import * as actionTypes from '../constant/event'
import moment from 'moment'
const initialState={
    
    list_event:null,
    detail_event:null,
    image_event:[],
    public_workshop:[],
    public_workshop_mobile:[]
}

export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.GET_EVENT_ALL:
            return{
                ...state,
                list_event:action.payload
            }   
        case actionTypes.GET_EVENT_DETAIL:
            return{
                ...state,
                detail_event:action.payload
            }  
        case actionTypes.GET_EVENT_IMAGE:
            return{
                ...state,
                image_event:action.payload
            } 
        case actionTypes.GET_PUBLIC_WORKSHOP:
            return{
                ...state,
                public_workshop:action.payload
            } 
        case actionTypes.GET_PUBLIC_WORKSHOP_MOBILE:
            return{
                ...state,
                public_workshop_mobile:action.payload
            } 
        default:
            return state;
    }
}