import * as actionTypes from '../constant/general'

export const setLoading =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING,
        payload
    })
}
export const setLoading2 =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING2,
        payload
    })
}
export const setTab =(tab_active,tab_back)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TAB,
        payload:{
            tab_active,tab_back
        }
    })
}

