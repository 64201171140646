import React,{useEffect,useState} from 'react'
import CloseAudit from '../../../assets/image/CloseAudit.svg'
import {useDispatch,useSelector} from 'react-redux'
import {setActiveQuestion,selectAnswer,postAudit} from '../../../redux/action/audit'
import Check from '../../../assets/image/Check_white.svg'
import {TextField,Button,Collapse,FormControlLabel,Select,MenuItem,InputLabel,RadioGroup ,Radio ,Checkbox, FormControl } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Modal from '../../../components/modal'
import {DragDropContext, Droppable,Draggable} from 'react-beautiful-dnd';
import ChevronDown from '../../../assets/image/chevron-down.svg'
import ChevronUp from '../../../assets/image/chevron-up.svg'
import InputMask from 'react-input-mask'

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#9b013c',
            contrastText: '#FFFFFF',

        }
    } 
})
export default function Question(props) {
    const audit=useSelector(state=>state.audit)

    const[answer,setAnswer]=useState(null)
    const [modal_exit,setModalExit]=useState(false)
    const [checked,setChecked]=useState(0)
    const [field,setField]=useState('')
    const [collapse,setCollapse]=useState({0:false})
    const [isOther,setIsOther]=useState({})

    const dispatch=useDispatch()
    const classes=useStyles()
    const {detail_survey,group,list_survey,active_survey,active_question,list_jawaban}=audit
    // console.log(`active_question`, active_question)
    useEffect(()=>{
        // console.log('detail_survey', detail_survey)
        // console.log('huh', list_jawaban[audit.active_question+1])
        // setAnswer(list_jawaban[audit.active_question].option)
        if(findActiveJawaban2()!==undefined){
            detail_survey[audit.active_question].active=true 

            setAnswer(findActiveJawaban2().option)
        }
        // console.log('answer', findActiveJawaban2())
     },[active_question])
    //  console.log('active_survey', active_survey)
     const clickNext=()=>{
        if(detail_survey.length-1!==audit.active_question){
            setAnswer(null)
            detail_survey[audit.active_question].active=false 
            detail_survey[audit.active_question+1].active=true 
            dispatch(setActiveQuestion(audit.active_question+1))
        }
    }
    // console.log('detail_survey[audit.active_question]', detail_survey)
    const clickBack=()=>{
        if(audit.active_question>0){
            detail_survey[audit.active_question-1].active=true 
            detail_survey[audit.active_question].active=false 
            dispatch(setActiveQuestion(audit.active_question-1))
        }
    }
    const findActiveSurvey=(id)=>{
        let survey=list_survey.filter((data)=>{
            return data.id===id
        })
        return survey[0]
    }
    const findActiveJawaban=(id)=>{
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id
        })
        // console.log('jawaban', jawaban)
        if(jawaban.length>0){
            return jawaban[0]
        }else{
            return {id:id,option:'last'}
        }
    }
    const findActiveJawaban2=()=>{
        let id_question=detail_survey[active_question]
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id_question.id
        })
        return jawaban[0]
    }
    const chooseAnswer=(id)=>{

        let a=findActiveJawaban2(id).option=id
        
        let new_jawaban=[
            ...list_jawaban,
            a
        ]
        setAnswer(id)
        // dispatch(selectAnswer(new_jawaban))
        // console.log('woeee', list_jawaban)

        
    }
    const renderIsActive=(data)=>{
        let a=findActiveJawaban(data.id)
        // console.log('a', data)
        if(data.active===true||a.option!==null){
            return true
        }else{
            return false
        }
        // if(a.option!=='last'){
        //     if(a.option!==null){
        //         return true
        //     }else{
        //         return false
        //     }
        // }else{
        //     if(data.active){
        //         return true
        //     }else{
        //         return false
        //     }
        // }
    }
    const toQuestion=(index)=>{
        props.tabToggle('question','list_question')
        detail_survey[audit.active_question].active=false 
        detail_survey[index].active=true
        dispatch(setActiveQuestion(index))
    }
    
    const renderExit=()=>{
        const close=()=>{
            window.location.assign('https://www.gmlperformance.com/organization-assessment')
            // props.tabToggle('audit','question')
            // props.setLang('en')
        }
        return(
            <div>
                <p>Your answers will be deleted if you leave the questionnaire.</p>
                <br/>
                <div style={{width:'100%',textAlign:'right'}}>
                    <MuiThemeProvider theme={themeButton}>
                    <Button onClick={close} style={{textTransform:'none',width:190,fontWeight:'bold'}} size="medium" color="secondary" variant="text">Yes, take me home</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={()=>setModalExit(false)} style={{textTransform:'none',width:100,fontWeight:'bold'}} size="medium" color="primary" variant="contained">No</Button>
                    </MuiThemeProvider>
                </div>
                
            </div>
        )
    }
    const handleDisable3=()=>{
        let {data_org}=list_jawaban[list_jawaban.length-1]
        var re = /\S+@\S+\.\S+/;
        let test=re.test(data_org[2].answerText)
        if(data_org[0].answerText!==''&&data_org[1].answerText!==''&&data_org[2].answerText!==''&&test&&data_org[1].answerText.length>=9){
            return false
        }else{
            return true
        }
    }
    const handleDisable2=()=>{
        let {data_org}=list_jawaban[list_jawaban.length-1]
        var re = /\S+@\S+\.\S+/;
        let status=[]
        data_org.map((d,i)=>{
            if(d.type==='textfield'||d.type==='phone'){
                status.push(d.answerText==='')
            }
            if(d.type==='email'){
                status.push(!re.test(d.answerText))
            }
            if(d.type==='single'||d.type==='dropdown'){
                status.push(d.ratingId===0)
            }
            if(d.type==='singleother'){
                if(isOther[i]){
                    status.push(d.ratingId===0||d.answerText==='')
                }else{
                    status.push(d.answerText==='')
                }
            }
        })
        let disable=status.includes(true)
        return disable
    }
    const postAudits=async ()=>{
        let new_jawab=[]
        list_jawaban.map((data,i)=>{
            if(list_jawaban.length-1!==i){
                new_jawab.push({id:0,surveyId:findActiveSurvey(active_survey).id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:data.option,answerText:''})

            }else{
                new_jawab.push(...data.data_org)
            }
        })
        let new_new_jawaban={
            surveyId:active_survey,
            uuid:props.uuid,
            responses:new_jawab
        }
        let res=await dispatch(postAudit(new_new_jawaban))
        if(res){
            props.tabToggle('result','question')

        }
        // console.log('new_jawab', new_jawab)
    }
    let org_index=list_jawaban.length-1

    const onChangeDataOrg=(index,value,type)=>{
        setField(value)
        if(type==='singleother'){

        }else if(type==='single'){
            list_jawaban[org_index].data_org[index].answerText=''
    
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
        }else if(type==='dropdown'){
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)

        }else{
            list_jawaban[org_index].data_org[index].answerText=value
        }
    }
    const onChangeOther=(index,value,type,name)=>{
        // console.log('value,type,name', value,type,name)
        setField(value)
        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther({...isOther,[index]:true})
                list_jawaban[org_index].data_org[index].answerText=''
    
                list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
            }else{
    
                setIsOther({...isOther,[index]:false})

                list_jawaban[org_index].data_org[index].answerText=value
                list_jawaban[org_index].data_org[index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            list_jawaban[org_index].data_org[index].answerText=value

        }
        
    }
    const onChangeCheckbox=(index,value)=>{
        list_jawaban[list_jawaban.length-1].data_org[index].ratingId=value

        setChecked(value)
    }
    const handleDisable=()=>{
        if(list_jawaban.length-1!==active_question){

            if(detail_survey[active_question].itemType==='ranking'){
                return false
            }else{
                if(answer!==null){
                    return false
                }else{
                    return true
                }
            }
            // if(answer!==null){
            //     return false
            // }else{
            //     return true
            // }
        }else{
            // console.log('aleeeeee',detail_survey[active_question].itemType)
            let filter=list_jawaban.filter((data)=>{
                return data.option===null
            })

            if(detail_survey[active_question].itemType==='ranking'){
                return false
            }else{
                if(filter.length>0){
                    return true
                }else{
                    return false
                }
            }
            
        }
        
    }
    const onDragEnd=async (result)=>{
        const {draggableId, source, destination, type} = result;
        
        if(!destination){
            return;
        }
        // if()
        let filter=list_jawaban.filter((data)=>{
            return data.slug===draggableId
        })
        list_jawaban.splice(source.index,1)
        // if()
        let check=list_jawaban.filter((data)=>{
            return data.slug===draggableId
        })
        if(check.length<1){
            list_jawaban.splice(destination.index,0,filter[0])
        }
        
    }
    const onBeforeCapture=(result)=>{
        // console.log('result before capture', result)
    }
    const collapseToggle=(i)=>{

        setCollapse({...collapse,[i]:!collapse[i]})
    }
    const renderOtherDisable=(answer,id)=>{
        // console.log('answer,id', answer,id)
        if(answer===id){
            return false
        }else{

            return true
        }
    }
    // console.log('list_jawaban', list_jawaban)
    return (
        <div className='question-wrapper-desktop'>
            <Modal width={500} title="Are you sure you want to leave the questionnaire?" without_x={true} isOpen={modal_exit} children={renderExit()} modalToggle={()=>setModalExit(!modal_exit)}/>
            <div className='div-flex'>
                <img style={{width:20,cursor:'pointer'}} src={CloseAudit} onClick={()=>setModalExit(true)}/>
                &nbsp;&nbsp;&nbsp;
                <div style={{width:'100%',textAlign:'left'}}>
                    <h3 style={{color:'white'}}>{findActiveSurvey(active_survey).title}</h3>
                </div>
            </div>
            <br/>
            <div className='question-desktop'>
                <div className='question-list'>
                    <p className='color-white'>{audit.active_language==='id'?detail_survey[active_question].title.id:detail_survey[active_question].title.en}</p>
                    <progress className='bar-desktop' max={detail_survey.length-1} value={active_question===0?detail_survey.length-1:active_question}></progress>
                    <br/><br/>
                    <div className='div-flex div-justify-between div-align-center'>
                        <p className='daftar-kuesioner-desktop'>{audit.active_language==='id'?'Daftar kuesioner':'Questionnaire List'}</p>
                        <div className='language-desktop'>
                            <p className={audit.active_language==='en'?'color-white':'color-black'} onClick={()=>props.setLang('en')}>EN</p>
                            &nbsp;&nbsp;
                            <div style={{width:1,height:18,backgroundColor:'grey'}}></div>
                            &nbsp;&nbsp;
                            <p className={audit.active_language==='id'?'color-white':'color-black'} onClick={()=>props.setLang('id')}>ID</p>

                        </div>
                    </div>
                    <br/>
                    <div className='scroll-question' style={{height:400,overflowY:"scroll",overflowX:'hidden'}}>
                        {/* <p className={detail_survey[0].visited===true?'color-white':'color-black'}>Introduction</p> */}
                        <p className={detail_survey[0].active?'color-white':'color-black'} style={{cursor:"pointer"}}>Introduction</p>
                        <ol className={'list-ol-desktop'} >
                            {detail_survey.map((data,i)=>{
                                if(data.items.length>0&&detail_survey.length-1!==i){
                                    return(
                                        <div key={i}>
                                            <li onClick={()=>toQuestion(i)} style={{cursor:'pointer'}}  className={renderIsActive(data)?'question-filled-desktop':'question-black'}>
                                                <div className='div-flex'>
                                                <p>{audit.active_language==='id'?`${data.items[0].text.id.substring(0,55)}...`:`${data.items[0].text.en.substring(0,55)}...`}</p>
                                                {findActiveJawaban(data.id).option!==null&&<img src={Check} style={{width:25}}/>}
                                                &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </li>
                                            <div style={{width:'100%',height:1,backgroundColor:'white'}}></div>
                                        </div>
                                    )  
                                }
                            })}
                        </ol>
                    </div>
                </div>
                <div className='question-content-desktop'>
                    <div className='question-white-section'>
                        <div style={{textAlign:'left',width:'100%'}}>
                                {audit.active_question===0&&<div style={{marginBottom:10}} className='text-center'><h1>{active_survey===4?`HR Business Partner Competency Assessment for ${group}`:findActiveSurvey(active_survey).title}</h1><br/></div>}
                                <br/>
                                <p dangerouslySetInnerHTML={{__html:audit.active_language==='id'?detail_survey[active_question].intro.id:detail_survey[active_question].intro.en}}></p>
                                {detail_survey.length-1===active_question?
                                <div style={{width:'100%'}}>
                                <br/>
                                {detail_survey[active_question].items.map((field,i)=>{
                                    if(field.itemType==='textfield'||field.itemType==='email'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                                <TextField
                                                    label={<>{audit.active_language==='id'?field.text.id:field.text.en}<span style={{color:'red'}}>*</span></>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    type='text'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                    value={list_jawaban[org_index].data_org[i].answerText}
                                                    onChange={(e)=>onChangeDataOrg(i,e.target.value,field.itemType)}
                                                />
                                            </div>
                                            
                                        )
                                    }else if(field.itemType==='phone'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                            <InputMask
                                                maskChar={null}
                                                mask="9999-9999-9999-9999"
                                                value={list_jawaban[org_index].data_org[i].answerText}
                                                onChange={(e)=>onChangeDataOrg(i,e.target.value,field.itemType)}
                                            >
                                            {() =>
                                                <TextField
                                                    label={<>{audit.active_language==='id'?field.text.id:field.text.en}<span style={{color:'red'}}>*</span></>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                />
                                            }
                                            </InputMask>
                                            </div>
                                        )
                                    }else if(field.itemType==='single'){
                                       return(
                                        <div key={i} >
                                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{audit.active_language==='id'?field.text.id:field.text.en} <span style={{color:'red'}}>*</span></p>
                                            <RadioGroup value={list_jawaban[org_index].data_org[i].ratingId} onChange={(e)=>onChangeDataOrg(i,e.target.value,field.itemType)}  style={{marginBottom:8}} aria-label="gender" name="gender1"  >
                                                <div className='lps-flex'>
                                                    {field.options.map((dat,i)=>(
                                                        <>
                                                        <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{audit.active_language==='id'?dat.text.id:dat.text.en}</p>} />
                                                        
                                                        </>
                                                    ))}
                                                
                                                </div>
                                            </RadioGroup>
                                        </div>
                                       )
                                    }else if(field.itemType==='singleother'){
                                        return(
                                            <div key={i}>
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{audit.active_language==='id'?field.text.id:field.text.en} <span style={{color:'red'}}>*</span></p>
                                                    <RadioGroup value={isOther[i]?list_jawaban[org_index].data_org[i].ratingId:parseInt(list_jawaban[org_index].data_org[i].answerText)}  aria-label="gender" name="gender1"  onChange={(e)=>onChangeOther(i,e.target.value,field.itemType,e.target.name)}>
                                                    {/* <div className='form-demografi ' > */}
                                                        <div className='lps-flex'>
                                                            {field.options.map((dat,i2)=>(
                                                                <div>
                                                                <FormControlLabel 
                                                                    key={i2}  
                                                                    className='radio-height'  
                                                                    value={dat.id} 
                                                                    name={dat.text.id} 
                                                                    control={<Radio color='primary' size='small'/>} 
                                                                    label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{audit.active_language==='id'?dat.text.id:dat.text.en}</p>} 
                                                                />
                                                                {dat.text.en==='Other'&&
                                                                    <div className='form-80'>
                                                                        <TextField
                                                                            color='primary'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            name='name'
                                                                            className={classes.textField}
                                                                            onChange={(e)=>onChangeOther(i,e.target.value,'otherValue','')}
                                                                            value={isOther[i]?list_jawaban[org_index].data_org[i].answerText:''}
                                                                            disabled={renderOtherDisable(list_jawaban[org_index].data_org[i].ratingId,dat.id)}
                            
                                                                        />
                                                                    </div>
                                                                }
                                                                </div>
                                                            ))}
                                                        {/* </div> */}
                                                        
                                                    </div>
                                                </RadioGroup>
                                                <br/>
                                            </div>
                                        )
                                    }else if(field.itemType==='dropdown'){
                                        return(
                                            <div className='tdra-field'>
                                                <FormControl  variant="outlined" size="small" className='add-proposal__field' >
                                                    <InputLabel  htmlFor="category">{audit.active_language==='id'?field.text.id:field.text.en} <span style={{color:'red'}}>*</span></InputLabel>
                                                    <Select className={classes.textField}   onChange={(e)=>onChangeDataOrg(i,e.target.value,field.itemType)} value={parseInt(list_jawaban[org_index].data_org[i].ratingId)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={120} style={{borderRadius:5}}>
                                                    {field.options.map((opt,i)=>(
                                                        <MenuItem key={i}  value={opt.id}>{audit.active_language==='id'?opt.text.id:opt.text.en}</MenuItem>

                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                })}
                                
                                
                                </div>
                                :
                                detail_survey[active_question].itemType==='ranking'?
                                <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
                                    <Droppable droppableId="droppable">
                                        {(provided)=>(
                                            <div
                                                style={{width:'100%'}}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {list_jawaban.map((data,i)=>{
                                                    if(data.itemType==='ranking'){
                                                        return(
                                                            <Draggable key={data.slug} draggableId={data.slug} index={i}>
                                                            {(provided)=>(
                                                                <div className='card-ranking'
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    >
                                                                    <div className='div-flex div-justify-between' style={{marginBottom:10}}>
                                                                        <p className='text-bold'>{audit.active_language==='id'?data.title.id:data.title.en}</p>
                                                                        <img src={collapse[i]?ChevronUp:ChevronDown} style={{width:20,cursor:'pointer'}} onClick={()=>collapseToggle(i)}/>
                                                                    </div>
                                                                    <Collapse in={collapse[i]}>
                                                                        {/* <div  dangerouslySetInnerHTML={{__html:audit.active_language==='id'?data.text.id:data.text.en}}></div> */}
                                                                        <p dangerouslySetInnerHTML={{__html:audit.active_language==='id'?data.text.id:data.text.en}}></p>
                                                                    </Collapse>
                                                                </div>
                                                            )}
                                                        
                                                        </Draggable>
                                                        )
                                                    }
                                                    
                                                    
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                
                                :detail_survey[active_question].items.map((data,i)=>(
                                    <div key={i}>
                                        <p>{audit.active_language==='id'?data.text.id:data.text.en}</p>
                                        <div className='question-option-desktop'>
                                            {data.options.map((option,i)=>(
                                                <div className={findActiveJawaban2().option===option.id||answer===option.id?'option-active':''} key={i} onClick={()=>chooseAnswer(option.id)}><p>{audit.active_language==='id'?option.text.id:option.text.en}</p></div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                                }
                                <br/>
                                {audit.active_question!==0&&detail_survey.length-1!==active_question?
                                    <div className='div-flex div-justify-between' style={{width:'100%'}}>
                                        <Button  onClick={clickBack} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Kembali':'Back'}</Button>
                                        <Button disabled={handleDisable()} onClick={clickNext} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Selanjutnya':'Next'}</Button>
                                    </div>
                                :
                                    <div style={{textAlign:'right',width:'100%'}}>
                                        {detail_survey.length-1===active_question?
                                        <Button disabled={active_survey===4?handleDisable3():handleDisable2()} onClick={postAudits} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Kalkulasi':'Calculation'}</Button>
                                        :
                                        <Button onClick={clickNext} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Mulai':'Start'}</Button>
                                        }
                                    </div>
                                }
                                
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
