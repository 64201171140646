import { combineReducers } from "redux";
import  event from './event'
import  general from './general'
import  career from './career'

import  audit from './audit'
import  home from './home'
import  insight from './insight'
export default combineReducers({
    event,
    general,
    audit,
    home,
    insight,
    career
})