import React,{useState,useEffect} from 'react'
import {Button,RadioGroup ,Collapse,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Modal from '../../../components/modal'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import {isMobile} from 'react-device-detect'
import {DragDropContext, Droppable,Draggable} from 'react-beautiful-dnd';
import ChevronDown from '../../../assets/image/chevron-down.svg'
import ChevronUp from '../../../assets/image/chevron-up.svg'



const Ranking=({total_question,next,back,list_jawaban,onDragEnd,onBeforeCapture,detail_survey,chooseAnswer,findActiveJawaban2,active_question,handleDisable,survey_length,onSubmit})=>{
    const [need_help,setNeedHelp]=useState(false)
    const [collapse,setCollapse]=useState({0:false})
    const collapseToggle=(i)=>{

        setCollapse({...collapse,[i]:!collapse[i]})
    }
    const child=()=>{
        return(
            <div className='help-mobile'>
                {detail_survey.pageNumber>=38?
                                <p>
                                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                                <br/><br/>
                                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
                                
                            :
                                <p >
                                    Pertanyaan pada survey ini terdiri dari 2 bagian.
                                    <br/><br/>
                                    Bagian pertama merupakan pernyataan-pernyataan dari survey Engagement yang menggambarkan persepsi Anda terhadap perusahaan. Bagian ini terdiri dari 33 pernyataan dengan 4 pilihan jawaban yaitu:<br/><br/>
                                    1 = Sangat tidak setuju<br/>
                                    2 = Tidak setuju<br/>
                                    3 = Setuju<br/>
                                    4 = Sangat setuju<br/><br/>
                                    Bagian kedua merupakan pernyataan dari survey Organization Digital Readiness yang menggambarkan seberapa siap perusahaan Anda untuk mendukung keberhasilan transformasi digital. Bagian ini terdiri dari … pertanyaan dengan … pilihan jawaban yaitu:
                                    <br/><br/>
                                    Silakan memilih salah satu jawaban yang paling sesuai menurut Anda.
                                </p>
                            }
            </div>
        )
    }
    const modToggle=()=>{
        setNeedHelp(!need_help)
    }
    return(
        <div className='welcome-wrapper'>
               {isMobile&&<Modal title="" isOpen={need_help} children={child()} modalToggle={modToggle}/>}
                <div className='welcome-text hide-mobile'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative',width:'100%'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div   style={{width:'100%',position:'relative'}}>
                            <div style={{padding:' 20px 20px 0px 20px'}}>
                            <div className='hide-mobile div-flex div-align-center div-justify-between'>
                                <p style={{color:'#252525',fontSize:18}}>Survey</p>
                                &nbsp;
                                <div style={{width:'70%',display:'flex',alignItems:'center'}}>
                                <progress className='bar-dtra' max={total_question+1} value={active_question+1-2}></progress>

                                </div>
                                &nbsp;
                                <p style={{color:'#808080',fontSize:12}}>{detail_survey.title.id}</p>
                                &nbsp;
                                <div className='help' onClick={()=>modToggle()}>?</div>&nbsp;

                            </div>
                            {need_help&&!isMobile&&<div className='help-backdrop' onClick={()=>setNeedHelp(false)}></div>}
                            {need_help&&!isMobile&&<div className='help-wrapper'>
                            {detail_survey.pageNumber>=38?
                                <p>
                                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                                <br/><br/>
                                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
                                
                            :
                                <p >
                                    Pertanyaan pada survey ini terdiri dari 2 bagian.
                                    <br/><br/>
                                    Bagian pertama merupakan pernyataan-pernyataan dari survey Engagement yang menggambarkan persepsi Anda terhadap perusahaan. Bagian ini terdiri dari 33 pernyataan dengan 4 pilihan jawaban yaitu:<br/><br/>
                                    1 = Sangat tidak setuju<br/>
                                    2 = Tidak setuju<br/>
                                    3 = Setuju<br/>
                                    4 = Sangat setuju<br/><br/>
                                    Bagian kedua merupakan pernyataan dari survey Organization Digital Readiness yang menggambarkan seberapa siap perusahaan Anda untuk mendukung keberhasilan transformasi digital. Bagian ini terdiri dari … pertanyaan dengan … pilihan jawaban yaitu:
                                    <br/><br/>
                                    Silakan memilih salah satu jawaban yang paling sesuai menurut Anda.
                                </p>
                            }
                            </div>}
                            <div className='show-mobile'>
                                <div className='div-flex div-justify-between'>
                                    <p>Data Demografi</p>
                                    <div className='div-flex div-align-center'>
                                        <p>{detail_survey.title.id}</p>
                                        &nbsp;&nbsp;
                                        <div className='help' onClick={()=>setNeedHelp(!need_help)}>?</div>
                                    </div>
                                </div>
                                <br/>
                                <progress className='bar-dtra' max={total_question+1} value={active_question+1-2}></progress>

                            </div>
                                
                            </div>
                            <br/>
                            <div>
                            <div className='ranking-fullheight'>
                                <p>{detail_survey.intro.id}</p>
                                <br/>
                                <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
                                    <Droppable droppableId="droppable">
                                        {(provided)=>(
                                            <div
                                                style={{width:'100%'}}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {list_jawaban.map((data,i)=>{
                                                    if(data.itemType==='ranking'){
                                                        return(
                                                            <Draggable key={data.slug} draggableId={data.slug} index={i}>
                                                            {(provided)=>(
                                                                <div className='card-ranking'
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    >
                                                                        <p dangerouslySetInnerHTML={{__html:data.text.id}}></p>
                                                                    {/* <div className='div-flex div-justify-between' style={{marginBottom:10}}>
                                                                        <p className='text-bold'>{data.title.id}</p>
                                                                        <img src={collapse[i]?ChevronUp:ChevronDown} style={{width:20,cursor:'pointer'}} onClick={()=>collapseToggle(i)}/>
                                                                    </div>
                                                                    <Collapse in={collapse[i]}>
                                                                        
                                                                        <p dangerouslySetInnerHTML={{__html:data.text.id}}></p>
                                                                    </Collapse> */}
                                                                </div>
                                                            )}
                                                        
                                                        </Draggable>
                                                        )
                                                    }
                                                    
                                                    
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <br/>
                                </div>
                                </div>
                            <div className='div-flex div-justify-between ' style={{padding:25}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                &nbsp;&nbsp;
                                {active_question+1-2===survey_length-2?
                                <Button disabled={handleDisable()} onClick={onSubmit} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Lihat hasil</Button>
                                :
                                <Button disabled={false} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                                }
                                {/* <Button disabled={handleDisable()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button> */}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default Ranking