import * as actionTypes from '../constant/career'
import moment from 'moment'
const initialState={
    
    list_career:null,
}

export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.GET_CAREER_ALL:
            return{
                ...state,
                list_career:action.payload
            }   
        default:
            return state;
    }
}