import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import CevronRight from "../../../assets/image/chevron-right-2.svg";
import CevronLeft from "../../../assets/image/chevron-left.svg";
import { URL_2, USER, PASS } from "../../../service/base_url";
import Axios from "axios";
import moment from "moment";
export default function List() {
  const [list, setlist] = useState([]);
  const [page, setpage] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState(0);
  const [loading, setloading] = useState(false);
  const [fromDate, setfromDate] = useState(0);
  const [toDate, settoDate] = useState(0);
  useEffect(() => {
    getList({
      fromDate: 0,
      toDate: 0,
    });
  }, [page, perpage]);
  const getList = (payload) => {
    setloading(true);
    Axios.get(
      URL_2 +
        `/survey/dx/${payload.fromDate}/${payload.toDate}/${page}/${perpage}`,
      {
        auth: {
          username: USER,
          password: PASS,
        },
      }
    )
      .then((res) => {
        setloading(false);
        setlist(res.data.items);
        settotal(res.data.info.total);
      })
      .catch(() => {
        setloading(false);
      });
  };
  const onSearch = () => {
    setpage(1);

    const payload = {
      fromDate: moment(fromDate).format("YYYYMMDD"),
      toDate: moment(toDate).format("YYYYMMDD"),
    };
    getList(payload);
  };
  return (
    <div
      style={{
        width: "50%",
        margin: "auto",
        marginTop: 100,
      }}
    >
      <h1 style={{ marginBottom: 10 }}>List Report</h1>
      <div style={{ marginBottom: 20, display: "flex", gap: 3 }}>
        <label for="birthday">From Date:</label>
        <input
          onChange={(e) => setfromDate(e.target.value)}
          type="date"
          id="birthday"
          name="birthday"
        />
        <label for="birthday">To Date:</label>
        <input
          onChange={(e) => settoDate(e.target.value)}
          type="date"
          id="birthday"
          name="birthday"
        />
        <button onClick={onSearch}>Search</button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Nama</TableCell>
              <TableCell align="left">Perusahaan</TableCell>
              <TableCell align="left">Tanggal</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                Loading...
              </TableCell>
            ) : (
              list.map((row, i) => (
                <TableRow
                  key={row.uuid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.company}</TableCell>
                  <TableCell align="left">
                    {moment(row.surveyDate).format("D MMM YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    <a
                      target="_blank"
                      href={`/digital-transformation-readiness-assessment/report/${row.uuid}`}
                    >
                      Link
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          className="card-pagination"
          type="reduced"
          page={page}
          pageLength={perpage}
          totalRecords={total}
          // totalRecords={event.drafted_pagination.total}
          onPageChange={({ page, pageLength }) => {
            setpage(page);
            setperpage(pageLength);
            // this.togglePagination(page,pageLength)
          }}
          prevPageRenderer={() => (
            <img src={CevronLeft} style={{ width: 10 }} />
          )}
          nextPageRenderer={() => <img src={CevronRight} />}
        />
      </TableContainer>
    </div>
  );
}
