import axios from "axios";
// import { get } from "lodash";
import { URL_2, USER,PASS } from './base_url'
// import Cookie from 'universal-cookie'
// import { setError ,modalToggle} from "redux/actions/general";
// const cookie=new Cookie()
let contentType = { "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }



export const apiCall=({method,url,data=''})=>async(dispatch)=>{
    // let head = !isEmpty(data.headers) ? defaultHeader({ Authorization: `Bearer ${data.headers}` }) : contentType
    // let login_url=url==='/auth/login'?{username:USER,password:PASS}:''
    try{
        const response=await axios({
            method:method,
            url:URL_2+url,
            data:data.data||"",
            auth:data.auth,
            headers:data.headers,
            // timeout:20000
        })
        return response
    }catch(error){
        return error
    }
}