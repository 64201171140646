import * as actionTypes from "../constant/home";
const initialState={
    
    banner:[],
    welcome:'',
    welcomeNote:''
   
}
export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.GET_BANNER:
            return{
                ...state, 
                banner:action.payload 
            }
        case actionTypes.GET_CAPTION:
            return{
                ...state, 
                welcome:action.payload.welcome ,
                welcomeNote:action.payload.welcomeNote
            }
       
        default:
            return state;
    }
}

