export const SET_ACTIVE_QUESTION='SET_ACTIVE_QUESTION'
export const SET_ACTIVE_SURVEY='SET_ACTIVE_SURVEY'
export const SET_ACTIVE_LANGUAGE='SET_ACTIVE_LANGUAGE'
export const GET_LIST_SURVEY='GET_LIST_SURVEY'
export const GET_DETAIL_SURVEY='GET_DETAIL_SURVEY'
export const GET_DETAIL_SURVEY2='GET_DETAIL_SURVEY2'
export const GET_DATA_REPORT='GET_DATA_REPORT'
export const SET_ANSWER='SET_ANSWER'
export const SET_MESSAGE='SET_MESSAGE'
export const SET_GROUP='SET_GROUP'
export const SET_JAWABAN='SET_JAWABAN'