import React,{useEffect,useState,useRef} from 'react'
import './style.css'
import Logo1 from '../../../assets/image/logo.png'
import Logo2 from '../../../assets/image/logo_report_2.png'
import Map from '../../../assets/image/map.svg'
import report_1 from '../../../assets/image/report/report_1.jpg'
import report_2 from '../../../assets/image/report/report_2.jpg'
import report_3 from '../../../assets/image/report/report_3.jpg'
import report_4 from '../../../assets/image/report/report_4.jpg'
import report_5 from '../../../assets/image/report/report_5.jpg'
import report_6 from '../../../assets/image/report/report_6.jpg'
import report_7 from '../../../assets/image/report/report_7.jpg'
import report_8 from '../../../assets/image/report/report_8.jpg'
import report_9 from '../../../assets/image/report/report_9.jpg'
import report_10 from '../../../assets/image/report/report_10.jpg'
import report_11 from '../../../assets/image/report/report_11.jpg'
import report_12 from '../../../assets/image/report/report_12.jpg'
import report_13 from '../../../assets/image/report/report_13.jpg'
import report_14 from '../../../assets/image/report/report_14.jpg'
import report_15 from '../../../assets/image/report/report_15.jpg'
import report_16 from '../../../assets/image/report/report_16.jpg'
import report_od from '../../../assets/image/report/report_od.png'

import Check from '../../../assets/image/Check.svg'
import Close from '../../../assets/image/Close_red.svg'
import Loader from '../../../components/Loading'

import ChevronGrey from '../../../assets/image/chevron_grey.svg'
import {getDtraResult,getListIndustry,getDtra} from '../../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import {Helmet} from "react-helmet";
import {Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {Chart} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Button,Checkbox ,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'

function Report(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let init=[false,false,false,false,false]
    const [loaders,setLoaded]=useState(init)
    const [timer,setTimer]=useState(0)
    const {report}=audit
    const canvas = useRef(null);
    const canvas2 = useRef(null);
    const canvas3 = useRef(null);
    const [chart1,setChart1]=useState([])
    const [chart2,setChart2]=useState({
        series: [{
            data: [0]
          },
          {
            data: [-3]
          }
          ],
          options: {
            tooltip:{
                enabled:false
            },
            legend:{
                show:false
              },
            chart: {
              type: 'bar',
              height: 740,
              stacked: true,
              toolbar: {
                show: false
              },
            },
            colors: [ '#FF4560','#26B600',],
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '100%',
            
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 1,
              colors: ["#fff"]
            },
            
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                    show: false
                }
            },  
            },
            yaxis: {
                
              min: -10,
              max: 10,
              
            },
            xaxis: {
              
              categories: [''],
              title: {
                text: `.`
              },
              
              labels: {
                formatter: function (val) {
                  if(val==6){
                    return 'Digital Stategy Differentiated'
                  }
                  if(val==-6){
                    return 'Brick and Mortar Strategy'
                  }
                }
              }
            },
          },
    })
    const [chart3,setChart3]=useState({
        series: [{
            data: [0]
          },
          {
            data: [-3]
          }
          ],
          options: {
              tooltip:{
                  enabled:false
              },
            legend:{
                show:false
              },
            chart: {
              type: 'bar',
              
              height: 740,
              toolbar: {
                show: false
              },
            },
            colors: [ '#FF4560','#26B600',],
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '100%',
                colors:{
                    
                }
              },
            },
            dataLabels: {
                enabled: true,
                formatter:(val)=>{
                    return val.toFixed(2)
                },
                offsetX: 70,
                style:{
                  colors: ['#333']
                }
            },
            stroke: {
              width: 1,
              colors: ["#fff"]
            },
            
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                    show: false
                }
            },  
            },
            yaxis: {
              min: -10,
              max: 10,
              
            },
            xaxis: {
              categories: [''],
              title: {
                text: `.`
              },
              
              labels: {
                formatter: function (val) {
                  if(val==6){
                    return 'High Organization Agility'
                  }
                  if(val==-6){
                    return 'High Organization Agility'
                  }
                }
              }
            },
          },
    })
    const [chart1Color,setChart1Color]=useState(['#ea5757','#5f8aeb','#26B600','#ebb262','#00ede4'])
    const [group_name,setGroup]=useState([])
    const [total,setTotal]=useState(0)
    const [industry_list,setIndustryList]=useState([])
    const color_list=[
        {
            color1:'rgb(220,20,60)',
            color2:'rgba(220,20,60,0.5)'
        },
        {
            color1:'rgb(205,92,92)',
            color2:'rgba(205,92,92,0.5)'
        },
        {
            color1:'rgb(250,128,114)',
            color2:'rgba(250,128,114,0.5)'
        },
        {
            color1:'rgb(255,215,0)',
            color2:'rgba(255,215,0,0.5)'
        },
        {
            color1:'rgb(255,140,0)',
            color2:'rgba(255,140,0,0.5)'
        },
        {
            color1:'rgb(255,250,205)',
            color2:'rgba(255,250,205,0.5)'
        },
        {
            color1:'rgb(255,218,185)',
            color2:'rgba(255,218,185,0.5)'
        },
        {
            color1:'rgb(240,230,140)',
            color2:'rgba(240,230,140,0.5)'
        },
        {
            color1:'rgb(189,183,107)',
            color2:'rgba(189,183,107,0.5)'
        },
        {
            color1:'rgb(50,205,50)',
            color2:'rgba(50,205,50,0.5)'
        },
        {
            color1:'rgb(124,252,0)',
            color2:'rgba(124,252,0,0.5)'
        },
        {
            color1:'rgb(173,255,47)',
            color2:'rgba(173,255,47,0.5)'
        },
        {
            color1:'rgb(0,250,154)',
            color2:'rgba(0,250,154,0.5)'
        },
        {
            color1:'rgb(143,188,143)',
            color2:'rgba(143,188,143,0.5)'
        },
        {
            color1:'rgb(128,128,0)',
            color2:'rgba(128,128,0,0.5)'
        },
        {
            color1:'rgb(0,255,255)',
            color2:'rgba(0,255,255,0.5)'
        },
        {
            color1:'rgb(0,139,139)',
            color2:'rgba(0,139,139,0.5)'
        },
        {
            color1:'rgb(176,196,222)',
            color2:'rgba(176,196,222,0.5)'
        },
        {
            color1:'rgb(255,0,255)',
            color2:'rgba(255,0,255,0.5)'
        },
    ]
    useEffect(() => {
        if(industry_list.length<1){
            getListInd()

        }
        if(industry_list.length>0){
            console.log('uiiie')
            getData()
        }
      },[industry_list]);
      const getListInd=async ()=>{
          let getId=await dispatch(getDtra())
          if(getId){
            let getindustry=await dispatch(getListIndustry(`/${getId.data.id}`))
            if(getindustry){
                let newind=[
                    {
                        id:0,
                        text:{
                            id:'Rata-rata',
                            en:'Rata-rata'
                        },
                        checked:true,
                        color1:'rgb(0,191,255)',
                        color2:'rgba(0,191,255,0.5)'
                    }
                ]
                
                getindustry.data.map((data,i)=>{
                    newind.push({...data,checked:false,color1:color_list[i].color1,color2:color_list[i].color2})
                })
                setIndustryList(newind)
            }
          }
      }
      const getData=async ()=>{
        //   setTimeout(async () => {
            let new_id=[]
            let ind_checked=industry_list.filter(dat=>{
                return dat.checked==true
            })
            ind_checked.map((dat)=>{
                new_id.push(dat.id)
            })
            let res=await dispatch(getDtraResult(`/${new_id.length>0?new_id.join(','):0}`))
            if(res){
                let {chart1}=res.data

                let new_datas=[
                    {x:-40,y:50,value:chart1.quadrant4},
                    {x:50,y:50,value:chart1.quadrant1},
                    {x:50,y:-50,value:chart1.quadrant2},
                    {x:-50,y:-50,value:chart1.quadrant3},
                ]
                let renderRadius=(value)=>{
                    if(value==0){
                        return 10
                    }
                    if(value==='NaN'){
                        return 0
                    }
                    if(value>70){
                        return value/2
                    }else{
                        return value
                    }
                    
                }
                const cfg = {
                        "responsive": false,
                        type: 'scatter',
                        data: {
                            borderColor:'red',
            
                            datasets: [{
                                label: 'Scatter Dataset',
                                showLine: false,
                                // pointBorderWidth:15,
                                pointRadius: [renderRadius(chart1.quadrant4),renderRadius(chart1.quadrant1),renderRadius(chart1.quadrant2),renderRadius(chart1.quadrant3)],
                                pointBorderColor:chart1Color,
                                pointBackgroundColor:chart1Color,
                                data: new_datas,
                            }]
                        },
                        options: {
                            
                            animation: {
                                duration: 0
                            },
                            
                            maintainAspectRatio: false,
                            scales: {
                                    x: {
                                            display: true,
                                            ticks: {
                                                beginAtZero: true,
                                                steps: 10,
                                                stepValue: 50,
                                                
                                                display:false,
                                            },
                                            max: 100,
                                                min:-100,
                                            grid: {
                                                drawBorder: false,
                                                zeroLineColor: '#00ede4',
                                                zeroLineWidth:2,
                                                drawBorder: false,
                                                lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0'
                                            }
                                        },
                                    y: {
                                            display: true,
                                            ticks: {
                                                beginAtZero: true,
                                                steps: 10,
                                                stepValue: 50,
                                                
                                                display:false,
            
                                            },
                                            max: 100,
                                                min:-100,
                                            grid: {
                                                drawBorder: false,
                                                zeroLineColor: '#00ede4',
                                                zeroLineWidth:2,
                                                lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0	'
                                            }
                                        }
                                },
                                plugins: {
                                    tooltips: {enabled: false},
                                    hover: {mode: null},
                                    legend: {
                                        display: false
                                    },
                                    datalabels: {
                                      anchor: function(context) {
                                        var value = context.dataset.data[context.dataIndex];
                                        return value.v < 50 ? 'end' : 'center';
                                      },
                                      align: function(context) {
                                        var value = context.dataset.data[context.dataIndex];
                                        return value.v < 50 ? 'end' : 'center';
                                      },
                                      color: function(context) {
                                        var value = context.dataset.data[context.dataIndex];
                                        return value.v < 50 ? context.dataset.backgroundColor : 'white';
                                      },
                                      font: {
                                        weight: 'bold'
                                      },
                                      formatter: function(value) {
                                        // console.log('value', value)
                                        return `${Math.round(value.value)}%`;
                                      },
                                      offset: 2,
                                      padding: 0
                                    }
                                  },
                              
                        }
                };
                let new_datas2=[]
                
                res.data.chart2.legends.map((dat,i)=>{
                    if(res.data.chart2.legends.length-1==i){
                        //for last
                        let new_dat=[]
                        res.data.chart2.items.map((item,i2)=>{
                            new_dat.push(item.values[i])
                        })
                        new_datas2.push({
                            label: dat,
                            data: new_dat,
                            fill:false,
                            type: 'line',
                            lineTension:0,
                            "borderColor":"#ed4900",
                            borderWidth:2,
                            pointBorderWidth:5,
                            pointBorderColor:'#ed4900',
                            pointBackgroundColor:'#ed4900',
                        })
                    }else if(res.data.chart2.legends.length-2==i){
                        //for before last
                        let new_dat=[]
                        res.data.chart2.items.map((item,i2)=>{
                            new_dat.push(item.values[i])
                        })
                        new_datas2.push({
                            label: dat,
                            data: new_dat,
                            fill:false,
                            type: 'line',
                            lineTension:0,
                            "borderColor":"#c919ed",
                            borderWidth:2,
                            pointBorderWidth:5,
                            pointBorderColor:'#c919ed',
                            pointBackgroundColor:'#c919ed',
                            // pointStyle: 'line'
                        })
                    }else{
                        let new_dat=[]
                        res.data.chart2.items.map((item,i2)=>{
                            new_dat.push(item.values[i])
                        })
                        let renderColor=()=>{
                            if(dat==='Nilai rata-rata'){
                                return '#0089ed'
                            }else{
                                let findColor=industry_list.filter((idn)=>{
                                    return idn.text.id===dat
                                })
                                return findColor[0].color1
                            }
                            
                        }
                        new_datas2.push({
                            label: dat,
                            data: new_dat,
                            backgroundColor:renderColor(),
                            pointStyle: 'rect'
                        })
                    }

                })
                console.log('new_datas2', new_datas2)
                setGroup(res.data.groupName)
                setTotal(res.data.total)
                let filter_line=new_datas2.filter((dat)=>{
                    return dat.type==='line'
                })
                let filter_without_line=new_datas2.filter((dat)=>{
                    return dat.type!=='line'
                })
                let reorderdata=[...filter_line,...filter_without_line]

            const cfg2 = {
                type: 'bar',
                data: {
                    datasets: reorderdata,
                    labels: [['Formulasi','Strategi'], ['Pemetaan',' Strategi','Level','Organisasi'], ['Penyelarasan ','Organisasi'], ['Eksekusi ','Operasional'],['Pemantauan dan','Penyelarasan','Kembali']]
                },
                "options":{
                    tooltips: {enabled: false},
                    hover: {mode: null},
                    
                    maintainAspectRatio: false,
                    animation: {
                        duration: 0
                    },
                    scales: {
                        x: { 
                            grid: {
                                display: false,
                            },
                            ticks: {
                                fontColor: "#464646",
                                fontFamily:'Lato', // this here,
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0
                            },
                        },
                        y: 
                        {
                            grid: {
                                color: '#464646'
                            },
                            ticks: {
                                beginAtZero: true,
                                fontColor: "#464646",
                                fontFamily:'Lato' // this here
                            },
                        },
                    
                    },
                    plugins: {
                        datalabels: {
                          formatter: function(value) {
                            return '';
                          },
                          offset: 2,
                          padding: 0
                        },
                        legend: {
                            display: true,
                            position:'bottom',
                            labels: {
                                usePointStyle: true
                            }
                        },
                      },
                }
            };
            let cfg3_dataset=[]
            let cfg3={
                // indexAxis: 'y',
                type: 'bar',
               data: {
                  labels: [""],
                  datasets: cfg3_dataset
               },
               plugins: [ChartDataLabels],
               options: {
                indexAxis: 'y',
                plugins: {
                    datalabels: {
                      formatter: function(value) {
                        if(value!==0){
                            return value.toFixed(2);

                          }else{
                              return ''
                          }
                      },
                      align:'end',
                      offset: 20,
                      padding: 0
                    },
                    tooltip: {enabled: false},
                    hover: {mode: null},
                    legend: {
                        display: false,
                    },
                  },
                responsive: true,
                    maintainAspectRatio: false,
                    legend: { display:false },
                  tooltips: {
                    enabled: false
                  },
                  responsive: true,
                  legend: {
                     display: false,
                    
                  },
                  scales: {
                     y: {
                       stacked: true,
                       barPercentage: 0.75,
                       grid: {
                         display: false,
                         drawTicks: false,
                         drawOnChartArea: false
                       },
                       ticks: {
                         fontColor: '#555759',
                         fontFamily: 'Lato',
                         fontSize: 11
                       }
                        
                     },
                     x: {
                        stacked: true,
                         grid: {
                           display: true,
                           drawTicks: false,
                           color: '#464646'
                         },
                         suggestedMin: -20,
                         suggestedMax: 20,
                         maxRotation: 0,
                       ticks: {
                         padding: 5,
                         beginAtZero: true,
                         fontColor: '#555759',
                         fontFamily: 'Lato',
                         fontSize: 11,
                        
                         callback: function(label, index, labels) {
                          
                          if(label==10){
                            return 'Digital Stategy Differentiated'
                          }else if(label==-10){
                            return 'Brick and Mortar Strategy'
                          }else if(label==0){
                            return 0
                          }else{
                            return null
                          }
            
                         }
                           
                       },
                       
                     }
                  }
               }
              }
            let new_series1=[]
            let new_series2=[]
            let new_series2_color=[]
            let new_series2_categories=[]
            res.data.chart3[0].values.map((data,i)=>{
                // if(data!==0){
                    cfg3_dataset.push({
                        data: [data],
                        backgroundColor: [data>0?'#26B600':'#FF4560' ], 
                    })
                // }
            })
            // console.log(`cfg3_dataset`, cfg3_dataset)
            res.data.chart4.map((data)=>{
                data.values.map(val=>{
                    if(val!==0){
                        
                        new_series2.push({
                            data:[val],
                            name:''
                        })
                        if(val>0){
                            new_series2_color.push('#26B600')
                        }else{
                            new_series2_color.push('#FF4560')
                        }
                    }
                })
                new_series2_categories.push(data.indicator)
            })
            console.log(`val`, new_series2)

            setChart2({...chart2,series:new_series1})
            setChart3({...chart3,series:new_series2,options:{...chart3.options,colors:new_series2_color,xaxis:{...chart3.options.xaxis,categories:new_series2_categories}}})
            if(window.chart){
                window.chart.destroy()
            }
            if(window.chart2){
                window.chart2.destroy()
            }
            if(window.chart3){
                window.chart3.destroy()
            }
            window.chart3 = new Chart(canvas3.current.getContext('2d'), cfg3);
            window.chart = new Chart(canvas.current.getContext('2d'), cfg);
            window.chart2 = new Chart(canvas2.current.getContext('2d'), cfg2);
            
            
            // setTimer(timer+1)

            
            }  
        //   }, 0);
      }
    //   console.log('chart2', chart3)
    let image1=[
        report_1,
        report_2,
        report_3,
        report_4,
    ]
    let image2=[
        report_5,
        report_6,
        report_7,
        report_8,
    ]
    let image3=[
        report_9,
        report_10,
        report_11,
        report_12,
    ]
    let image4=[
        report_1,
        report_13,
        report_14,
        report_15,
    ]
    let image5=[
        report_1,
        report_14,
        report_16,
        report_15,
    ]
    const renderImage=()=>{
        let {id,uuid}=props.match.params
        switch (parseInt(id)) {
            case 3:
                return image2
            case 2:
                return image3
            case 1:
                return image1
            case 4:
                return image4
            case 5:
                return image5
            case 6:
                return image5
            default:
                return image1
                break;
        }
    }
    let oo=[]
    const setonLoaded=(index)=>{
            
            if(oo.length<4){

                oo.push(index)
            }else{
                oo=[]
                setTimeout(()=>{
                    // window.print()
                },3000)
            }
    }
    const onChangeCheckbox=(index,id,text)=>{
        if(index==0){
            industry_list.map((dat,i)=>{
                if(i!==0){
                    dat.checked=false
                }
            })
            industry_list[0].checked=!industry_list[0].checked
        }else{
            let check_3=industry_list.filter(dat=>{
                return dat.checked==true
            })
            let check_isexist=industry_list.filter(dat=>{
                return dat.id==id
            })
            industry_list[0].checked=false
            if(check_3.length<3&&check_isexist[0].checked==false){
                industry_list[index].checked=true
            }else{
                industry_list[index].checked=false
            }

        }
        setIndustryList([...industry_list])
    }

    const renderCheck=()=>{
        
        let map=industry_list.map((data,i)=>{
            const checkBoxStyles = (color) => ({
                root: {
                  '&$checked': {
                    color: data.color1,
                  },
                },
              checked: {}
            })
            
            const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
            if(i!==0){
                return(
                    <FormControlLabel style={{display:'flex',alignItems:'flex-start'}} key={i} control={<CustomCheckbox name="checkedC" checked={industry_list[i].checked} onChange={()=>onChangeCheckbox(i,data.id,data.text.id)}  color='primary' style={{padding:'0px 5px 0px 10px'}}/>} label={data.text.id} />
                )
            }
        })
        return map
    }
    const checkBoxStyles2 = () => ({
        root: {
          '&$checked': {
            color: industry_list[0].color1,
          },
        },
      checked: {}
    })
    // console.log('industry_list', industry_list)
    const CustomCheckbox2 = withStyles(checkBoxStyles2)(Checkbox); 
    return (
        <>
        <Loader/>
        {industry_list.length>0&&
        <div className='report-wrapper' >
            <Helmet>
                <title>Report-DTRA</title>
                
            </Helmet>
            <div className='report-header'>
                <div className='report-shape'></div>
                <div className='div-margin'>
                    <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                </div>
            </div>
            <div className='report-footer'>
                <div className='div-margin'>
                    <p>Report date :  {moment().format('DD MMM YYYY')}</p>
                </div>
            </div>
            <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">
                        <div className='div-flex div-margin'>
                            {renderImage().map((data,index)=>(
                                <div style={{width:140,height:140}}>
                                <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>Digital Transformation Readiness Assessment</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        
                        <br/><br/>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        {/* page1 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Pemetaan Digital Transformation Readiness</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan keniscayaan terlepas bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industri. Asesmen yang telah Anda lakukan memberi gambaran tentang kesiapan organisasi Anda baik dari kesiapan agilitas strategi untuk melakukan diferensiasi memberi nilai kepada pelanggan <i>(differentiating digital capability)</i> maupun agilitas organisasi seperti sistem/proses, struktur, manusia dan budaya <i>(organization integrating capability)</i> untuk mendukung keberhasilan transformasi digital. 
                                <br/><br/>
                                Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Survei ini relevan bagi semua jenis organisasi. Partisipan yang mewakili organisasi pemerintahan atau nirlaba, diminta memikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda.
                                <br/><br/>
                                Hasil dari asesmen ini mengkategorikan organisasi Anda ke dalam salah satu dari empat kuadran yang ada: <i>Fully Ready, Not Ready, Weak Organization Agility</i>, atau <i>Poor Strategy Differentiation</i>. Ke empat kuadran ini dihasilkan dari pemetaan 2 dimensi: <i>differentiating digital capability</i> dan <i>organization integrating capability</i>. Dengan <i>differentiating digital capability</i>, organisasi melakukan pemantauan atas perubahan tren eksternal dan cepat memformulasi serta lincah mengeksekusi strategi digital yang tepat untuk memberi nilai tambah yang baru dan unik kepada pelanggan. Sedang organization integrating capability menjaga keselarasan dimensi organisasi tanwujud seperti sistem/proses, struktur, SDM dan budaya kerja telah dirancang untuk <i>agile</i> mendukung transformasi digital. </p>
                            <br/>
                            <div className='pemetaan' style={{width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                <p>DIFFERENTIATING DIGITAL CAPABILITY</p>
                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Definisi:</b> Kemampuan perusahaan untuk memberikan nilai tambah <i>(value proposition)</i> berbasis digital yang unik dalam akuisisi dan memenangkan loyalitas pelanggan</p>
                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc'}}></div>
                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Contoh:</b> Kemampuan inovasi, pelayanan, produksi dan distribusi, pengembangan ekosistem dengan menggunakan teknologi dan digitalisasi</p>

                            </div>
                            
                            <div className='pemetaan' style={{marginTop:5,width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                <p>ORGANIZATION INTEGRATING CAPABILITY</p>
                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Definisi:</b> Kemampuan tanwujud <i>(intangible)</i> perusahaan untuk mendorong terciptanya kemampuan yang <i>agile</i> dan selaras dengan <i>differentiating capability</i></p>
                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc',}}></div>
                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Contoh:</b> Sistem/Proses, struktur, sumber daya manusia, serta budaya kerja dan kepemimpinan.</p>

                            </div>

                        </div>
                        <div className='pagebreak'></div>

                        {/* page2 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Memahami Kuadran Kesiapan Transformasi Organisasi Anda</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p><b>Penempatan organisasi Anda dalam salah satu dari ke empat kuadran dapat dimaknai sebagai berikut:</b></p>
                            <div style={{width: '100%',textAlign: 'center',}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 1. Pemetaan Digital Transformation Readiness {group_name.join(' dan ')} </p>
                            <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {total}</p>

                            </div>
                            <br/><br/>
                        <div className="div-flex" style={{width: '100%'}}>
                            <div style={{width:500,height: 350,position:'relative'}}>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',top:0,left:20}}><b>WEAK<br/>ORGANIZATION AGILITY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',top:0,right:60}}><b>FULLY READY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',bottom:0,left:70}}><b>NOT-READY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',bottom:0,right:30}}><b>POOR STRATEGY<br/> DIFFERENTIATION</b></p>
                                </div>
                                <p style={{position:'absolute',top:-40,left:150}}>Digital Strategy Differentiated</p>
                                <p style={{position:'absolute',bottom:-40,left:100}}>Not Digital Strategy Differentiated</p>
                                {/* <p className='vertical-text' style={{position:'absolute',top:160,left:-70}}>Low Organization Agility</p>
                                <p className='vertical-text' style={{position:'absolute',top:160,right:-70}}>High Organization Agility</p> */}
                                <div style={{textAlign:'center'}}>
                                    <p  style={{position:'absolute',top:130,left:-20}}>Low<br/> Organization<br/> Agility</p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p  style={{position:'absolute',top:130,right:-20}}>High<br/> Organization<br/> Agility</p>
                                </div>
                                <p className='vertical-text' style={{position:'absolute',top:160,left:100}}>Differentiating Capability</p>
                                <p  style={{position:'absolute',top:160,left:200}}>Integrating Capability</p>
                                <canvas ref={canvas} className="chartjs"></canvas>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style={{width:'40%'}}>
                                <p style={{color:'#464646',fontSize:20,fontWeight:'bold'}}>Industri :</p>
                                {/* <br/> */}
                                <FormControlLabel style={{display:'flex',alignItems:'flex-start',marginBottom:10}} control={<CustomCheckbox2   checked={industry_list[0].checked} onChange={()=>onChangeCheckbox(0,industry_list[0].id,'Rata rata')} style={{padding:'0px 5px 0px 10px'}} name="checkedC"  />} label="Rata rata" />

                                <div style={{display:'grid',gridTemplateColumns: 'repeat( 2,1fr)',gridRowGap:10}}>
                                    {renderCheck()}
                                </div>
                            </div>
                        </div>
                        <br/>
                        

                        </div>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='div-margin'>
                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[1],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 1 FULLY READY :</b>  Organisasi dalam kuadran ini siap (atau telah) melakukan transformasi digital. Organisasi ini memiliki kemampuan melakukan diferensiasi strategi berbasis digital yang merubah pengalaman pelanggan secara unik. Dimensi <i>agile</i> organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi juga telah dibangun dan selaras dengan kebutuhan transformasi digital. </p>
                            <br/>
                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[2],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 2 Poor Strategy Differentiation :</b>  Organisasi dalam kuadran ini telah berinvestasi dalam mengembangkan <i>kapabilitas integrating</i> untuk membangun organisasi yang <i>agile</i>. Namun mereka belum menjalankan sepenuhnya strategi diferensiasi berbasis transformasi digital untuk merubah atau menambah nilai kepada pelanggan. Dengan adanya organisasi yang siap seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur & SDM yang <i>agile</i> , serta budaya yang menghargai inovasi, organisasi ini perlu segera memformulasi dan menjalankan strategi transformasi digital sehingga dapat berdampak kepada pelanggan. </p>
                            <br/>
                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[3],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 3 Not Ready :</b>  Organisasi dalam kuadran ini belum siap dan butuh waktu persiapan lebih matang untuk melakukan transformasi digital. Organisasi ini belum mampu melakukan diferensiasi strategi berbasis digital. Strategi yang diadopsi dan pengalaman pelanggan yang diciptakan masih belum berubah dari gaya lama. Di sisi lain, dimensi organisasi lainnya, seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi, belum dikembangkan sepenuhnya sehingga belum sesuai tuntutan transformasi digital. </p>
                            <br/>
                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[0],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 4 Weak Organization Agility :</b>  Organisasi ini telah mulai melakukan diferensiasi strategi berbasis digital untuk merubah atau menambah nilai kepada pelanggan. Namun dimensi organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi belum sepenuhnya dikembangkan sehingga belum sesuai tuntutan transformasi digital. Kurangnya penyelarasan antara kapabilitas diferensiasi dan integrasi ini dapat menghambat keberhasilan transformasi. Organisasi dalam kuadran ini perlu segera melakukan penyelarasan untuk mendukung keberhasilan transformasi digital yang sedang dilakukan. </p>
                        </div>
                        <br/>
                        <div style={{width: '100%',textAlign: 'center',}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 2. Digital Differentiating Capability</p>
                            <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {total}</p>

                        </div>
                        <br/>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <div style={{width:'50%',height:100}}>
                                <canvas ref={canvas3} height={200} className="chartjs" ></canvas>
                            </div>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Selain memliki Differentiating Digital Strategy yang jelas, organisasi yang siap bertransformasi membangun dan menyelaraskan kemampuan organisasi berbasis agilitas atau <i>Organization Integrating Capability</i>. Dimensi <i>agile</i> dalam organisasi terdiri sistem/proses kerja ala <i>rapid-decision making</i>, struktur organisasi yang flat dan fleksibel, pengelolaan talenta SDM yang <i>agile</i>, serta budaya yang menghargai inovasi dan mendorong pengambilan risiko (serta belajar dari kesalahan).   </p>
                        </div>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='div-margin' style={{width: '100%',textAlign: 'center'}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 3. Organization Integrating Capability</p>
                            <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {total}</p>
                            <div style={{width:'80%'}}>
                                <ReactApexChart options={chart3.options} series={chart3.series} type="bar" height={410} />
                            </div>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p><b>Execution-biased Systems:</b> Organisasi yang bertransformasi digital tidak lagi melihat teknologi hanya sebagai <i>enabler</i>, namun investasi dalam teknologi dan digitalisasi terbaru terus dilakukan sehingga teknologi menjadi teintegrasi dalam proses bisnis. Data dilihat sebagai aset strategis yang dapat diakses oleh semua bagian. Proses inovasi dan pengambilan keputusan dilakukan dengan cepat dan lincah berbasis iterasi berulang.</p>
                            <p><b>Empowered Structure:</b> Struktur organisasi yang <i>agile</i>, lintas fungsional dan independen, minim intervensi manajemen puncak diterapkan dalam berinovasi. Pemindahan talenta antar fungsi atau proyek dapat dilakukan dengan lincah tanpa birokrasi berbelit.</p>
                            <p><b>Entrepreneurial People:</b> Sumber daya manusia terus dikembangkan dengan budaya pembelajaran. Inovasi dengan akuntabilitas diterapkan dan diberikan penghargaan. Manajemen kinerja yang adaptif terhadap perubahan bisnis diimplementasikan.</p>
                            <p><b>Adhocracy Culture:</b> Budaya kolaboratif yang berfokus kepada penciptaan nilai baru dan kepuasan pelanggan merupakan roh perusahaan. Inovasi dan <i>risk-taking</i> disertai disiplin dalam melakukan reviu dan mendapatkan pembelajaran dari proses keberhasilan serta kegagalan merupakan bagian dari budaya kerja.</p>
                            <p><b>Ambidextrous Leadership:</b> Kepemimpinan yang menyeimbangkan antara eksploitasi dan eksplorasi, sehingga mampu menjaga fokus kinerja keseharian sambal sekaligus mendorong pengembangan inovasi untuk masa depan.</p>
                            <p>Lihat kembali nilai Anda dan identifikasi dimensi dalam <i>Organization Integrating Capability</i> yang memiliki nilai relatif rendah. Lakukan prioritas untuk melakukan penyelarasan organisasi agar transformasi digital Anda dapat lebih berhasil.</p>
                        </div>
                        {/* page3 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Pemetaan Kemampuan Agilitas Strategy-into-Performance Execution Excellence (SPEx2) vs {group_name.join(' vs ')} vs Benchmark</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Berbagai riset di Indonesia dan dunia menunjukkan bahwa strategi yang baik, termasuk transformasi digital, sering gagal 
                                diimplementasi. Kemampuan formulasi dan eksekusi strategi (SPEx2) merupakan kemampuan yang akan membedakan 
                                organisasi yang hanya “cerdas” dengan yang <i>agile</i> atau “lincah.” Organisasi yang <i>agile</i> adalah organisasi yang aktif memonitor 
                                perkembangan tren eksternal, cepat memformulasi strategi untuk mengantisipasi dan meresponi perubahan, dan selalu aktif 
                                melakukan reviu atas hasil implementasi strategi.</p>
                            <div style={{width: '100%',textAlign: 'center'}}>
                                <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 4. Pemetaan Kemampuan Agilitas Strategy-Into-Performance Execution Exellence (SPEx2) </p>
                                <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {total}</p>

                            </div>
                            <br/>
                                <div style={{width:700,margin: 'auto',height: 400}}>
                                    <canvas ref={canvas2} className="chartjs"></canvas>
                                </div>
                            <br/>
                            <p><b>Kemampuan SPEx2 yang agile memiliki 5 tahapan:</b></p>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Formulasi strategi:</b> Kemampuan organisasi memantau perkembangan luar, memformulasi respon strategi berbasis digital dan data baik untuk perbaikan operasi, mengembangkan bisnis model baru, atau memberikan nilai tambah yang merubah pengalaman pelanggan. Strategi mengedepankan keberanian untuk melakukan disrupsi bahkan mengkanibalisasi bisnis yang ada.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemetaan strategi:</b> Kemampuan menggambarkan strateginya secara visual dan menarik dengan penanggung
                                jawab yang jelas dalam organisasi disertai pengukuran keberhasilan yang menyeimbangkan indikator <i>leading </i>
                                 atau proses dan <i>lagging</i> atau hasil. Komunikasi strategi dilakukan dengan media berbasis digital untuk
                                menjangkau karyawan milenial.
                                </p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Penyelarasan organisasi:</b> Strategi organisasi diselaraskan dengan investasi dalam pengembangan teknologi
                                digitalisasi dan sumber daya manusia. Struktur organisasi yang ambidekster dibentuk untuk mendukung
                                agilitas.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Eksekusi operasional:</b> Program kerja strategis dieksekusi dengan <i>mindset</i> yang fokus kepada pelanggan atau
                                <i>stakeholder</i> utama. Implementasi yang cepat berbasis <i>rapid decision making</i>. Inovasi terus terjadi dalam proses
                                eksekusi. Pemindahan talenta dan pembagian pembelajaran/ide antar proyek atau fungsi mudah dilakukan
                                dengan cepat.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemantauan dan penyelarasan kembali :</b>  Pemantauan dan reviu kinerja dilakukan dengan disiplin dengan
                                menggunakan pengukuran yang selaras antar individu/unit kerja dengan organisasi serta maturitas bisnis. Ide ide baru terus muncul dalam pembelajaran. Penghargaan atas keberhasilan inovasi diberikan dan kegagalan juga
                                dihargai sebagai bagian dari proses menuju keberhasilan.</p>
                            </div>
                            <p><b>Lihat kembali nilai Anda dan identifikasi tahapan dalam SPEx2 yang memiliki nilai relatif rendah, baik dibanding
                            dengan kelima tahapan dalam organisasi Anda,maupun dibanding perusahaan pembanding. Lakukan prioritas
                            untuk melakukan perbaikan agar transformasi digital Anda dapat lebih berhasil.
                            </b></p>
                        </div>

                        {/* page4 */}
                        
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
        </div>
        } 
        </>
    )
}
export default withRouter(Report)