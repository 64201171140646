import React,{useEffect,useState} from 'react'
import CloseAudit from '../../../assets/image/CloseAudit.svg'
import Close from '../../../assets/image/Close_red.svg'
import {useDispatch,useSelector} from 'react-redux'
import {setActiveQuestion,postGroup,selectAnswer,postAudit} from '../../../redux/action/audit'
import Check from '../../../assets/image/Check_white.svg'
import {TextField,Button,Tooltip ,Collapse,FormControlLabel ,Checkbox, FormControl } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Modal from '../../../components/modal'
import {DragDropContext, Droppable,Draggable} from 'react-beautiful-dnd';
import ChevronDown from '../../../assets/image/chevron-down.svg'
import ChevronUp from '../../../assets/image/chevron-up.svg'
import Plus from '../../../assets/image/Plus.svg'
import MomentUtils from '@date-io/moment';
import {
    TimePicker,
    DatePicker ,
    MuiPickersUtilsProvider
  } from '@material-ui/pickers';
import moment from 'moment'
import {CopyToClipboard} from 'react-copy-to-clipboard';
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 8,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#9b013c',
            contrastText: '#FFFFFF',

        }
    } 
})
export default function Question(props) {
    const audit=useSelector(state=>state.audit)

    const[answer,setAnswer]=useState(null)
    const [modal_exit,setModalExit]=useState(false)
    const [checked,setChecked]=useState(0)
    const [field,setField]=useState('')
    const [collapse,setCollapse]=useState({0:false})
    const [active_options,setActiveOptions]=useState(0)
    const [result,setResult]=useState([])
    const [open,setOpen]=useState({0:false})
    const [state,setState]=useState({
        name:'',
        company:'',
        email:'',
        phone:'',
        department:'',
        position:'',
        groups:[
            {name:'Manager',expiredTime:moment()},
            {name:'Employee',expiredTime:moment()},
        ]
    })
    const dispatch=useDispatch()
    const classes=useStyles()
    
    const {detail_survey,list_survey,active_survey,active_question,list_jawaban}=audit

    useEffect(()=>{
        // console.log('huh', list_jawaban[audit.active_question+1])
        // setAnswer(list_jawaban[audit.active_question].option)
       
     },[active_question])

     const clickNext=()=>{
        if(detail_survey.length-1!==audit.active_question){
            setAnswer(null)
            detail_survey[audit.active_question].active=false 
            detail_survey[audit.active_question+1].active=true 
            dispatch(setActiveQuestion(audit.active_question+1))
        }
    }
    // console.log('detail_survey[audit.active_question]', detail_survey[audit.active_question])
    const clickBack=()=>{
        if(audit.active_question>0){
            detail_survey[audit.active_question-1].active=true 
            detail_survey[audit.active_question].active=false 
            dispatch(setActiveQuestion(audit.active_question-1))
        }
    }
    const findActiveSurvey=(id)=>{
        if(id===4){
            return {title:'HR Business Partner'}
        }else{
            let survey=list_survey.filter((data)=>{
                return data.id===id
            })
            return survey[0]
        }
    }
    const toQuestion=(index)=>{
        detail_survey[audit.active_question].active=false 
        detail_survey[index].active=true
        dispatch(setActiveQuestion(index))
    }
    
    const renderExit=()=>{
        const close=()=>{
            if(window.location.pathname==='/hrbp'){
                window.location.assign('https://www.gmlperformance.com/organization-assessment')
            }else{
                // props.tabToggle('audit','question')
                // props.setLang('en')
                window.location.assign('https://www.gmlperformance.com/organization-assessment')
            }
            
        }
        return(
            <div>
                <p>Your answers will be deleted if you leave the questionnaire.</p>
                <br/>
                <div style={{width:'100%',textAlign:'right'}}>
                    <MuiThemeProvider theme={themeButton}>
                    <Button onClick={close} style={{textTransform:'none',width:190,fontWeight:'bold'}} size="medium" color="secondary" variant="text">Yes, take me home</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={()=>setModalExit(false)} style={{textTransform:'none',width:100,fontWeight:'bold'}} size="medium" color="primary" variant="contained">No</Button>
                    </MuiThemeProvider>
                </div>
                
            </div>
        )
    }
    const addGroup=()=>{
        let dumm={name:'',expiredTime:moment()}
        let new_dum=[
            ...state.groups,
            dumm
        ]
        setState({...state,groups:new_dum})
    }
    const deleteGroup=(i)=>{
        state.groups.splice(i,1)
        setState({...state})
    }
    const onChange=(value,index)=>{
        state.groups[index].name=value
        setState({...state})

    }
    const onChangeDate=(date,index)=>{
        state.groups[index].expiredTime=date
        setState({...state})
    }
    const next=()=>{
        setActiveOptions(active_options+1)
    }
    const onChangeData=(e)=>{
        const {name,value}=e.target
        setState({
            ...state,
            [name]:value
        })
    }
    const generateLink=async ()=>{
        let newgroups=[]
        state.groups.map((data)=>{
            newgroups.push({text:data.name,time:moment(data.expiredTime).format("YYYY-MM-DD")})
        })
        let data={
            id:0,
            surveyId:active_survey,
            userId:35,
            name:state.name,
            email:state.email,
            company:state.company,
            phone:state.phone,
            department:state.department,
            position:state.position,
            groups:newgroups
        }
        let res=await dispatch(postGroup(data))
        if(res){
            setResult(res)
            setActiveOptions(2)
        }

    }
    const handleDisable=()=>{
        if(state.name!==''&&state.email!==''&&state.company!==''&&state.phone!==''&&state.department!==''&&state.position!==''){
            return false
        }else{
            return true
        }
    }
    const handleTooltipOpen =async (i) => {
        await setOpen({...open,[i]:true});
        setTimeout(()=>setOpen({...open,[i]:false}),3000)
    };
    const handleTooltipClose = (i) => {
        setOpen({...open,[i]:false});

    };
    const renderCopied=(i)=>{
        if(open[i]){
            return 'Copied'
        }else{
            return 'Copy'
        }
    }
    return (
        <div className='question-wrapper-desktop'>
            <Modal width={500} title="Are you sure you want to leave the questionnaire?" without_x={true} isOpen={modal_exit} children={renderExit()} modalToggle={()=>setModalExit(!modal_exit)}/>
            <div className='div-flex'>
                <img style={{width:20,cursor:'pointer'}} src={CloseAudit} onClick={()=>setModalExit(true)}/>
                &nbsp;&nbsp;&nbsp;
                <div style={{width:'100%',textAlign:'left'}}>
                    <h3 style={{color:'white'}}>{findActiveSurvey(active_survey).title}</h3>
                </div>
            </div>
            <br/>
            <div className='question-desktop'>
                <div className='question-list'>
                    <p className='color-white'>{audit.active_language==='id'?detail_survey[active_question].title.id:detail_survey[active_question].title.en}</p>
                    <progress className='bar-desktop' max={detail_survey.length} value={active_question+1}></progress>
                    <br/><br/>
                    <div className='div-flex div-justify-between div-align-center'>
                        <p className='daftar-kuesioner-desktop'>{audit.active_language==='id'?'Daftar kuesioner':'Questionnaire List'}</p>
                        <div className='language-desktop'>
                            <p className={audit.active_language==='en'?'color-white':'color-black'} onClick={()=>props.setLang('en')}>EN</p>
                            &nbsp;&nbsp;
                            <div style={{width:1,height:18,backgroundColor:'grey'}}></div>
                            &nbsp;&nbsp;
                            <p className={audit.active_language==='id'?'color-white':'color-black'} onClick={()=>props.setLang('id')}>ID</p>

                        </div>
                    </div>
                    <br/>
                    <div style={{height:400,overflowY:"auto",overflowX:'hidden'}}>
                        {/* <p className={detail_survey[0].visited===true?'color-white':'color-black'}>Introduction</p> */}
                        <p onClick={()=>toQuestion(0)} className={detail_survey[0].active?'color-white':'color-black'} style={{cursor:"pointer"}}>Introduction</p>
                        <ol className='list-ol-desktop2' >
                            {detail_survey.map((data,i)=>{
                                if(data.items.length>0&&i!==0){
                                    return(
                                        <div key={i}>
                                            <div onClick={()=>toQuestion(i)} style={{cursor:'pointer'}}  className={active_question!==0?'question-filled-desktop':'question-black'}>
                                                <div className='div-flex'>
                                                <p>{audit.active_language==='id'?`${data.items[0].text.id}`:`${data.items[0].text.en}`}</p>
                                                &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    )  
                                }
                            })}
                        </ol>
                    </div>
                </div>
                <div className='question-content-desktop'>
                    <div className='question-white-section2'>
                        <div style={{textAlign:'left',width:'100%'}}>
                            {audit.active_question===0&&<div style={{marginBottom:10}} className='text-center'><h1>{findActiveSurvey(active_survey).title}</h1><br/></div>}
                            <p>{audit.active_language==='id'?detail_survey[active_question].intro.id:detail_survey[active_question].intro.en}</p>
                            {active_question!==0&&<p style={{marginBottom:20,marginTop:20,fontWeight:"bold"}}>{audit.active_language==='id'?detail_survey[active_question].options[active_options].intro.id:detail_survey[active_question].options[active_options].intro.en}</p>}
                            <br/>
                            {active_question!==0&&active_options===0&&state.groups.map((data,i)=>{
                                    return(
                                        <div className='div-flex div-justify-between div-align-center'>
                                            <div className='div-flex div-justify-between' style={{width:'80%'}}>
                                            <div style={{width:'55%'}}>
                                            <TextField
                                                label={`Group ${i+1}`}
                                                color='primary'
                                                variant='outlined'
                                                size='small'
                                                name='name'
                                                type='text'
                                                className={classes.textField}
                                                onChange={(e)=>onChange(e.target.value,i)}
                                                value={data.name}
                                            />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div style={{width:'55%'}}>
                                             <MuiPickersUtilsProvider utils={MomentUtils}>
                                             <DatePicker
                                                className={classes.textField}
                                                format="DD MMM YYYY"
                                                autoOk
                                                disablePast
                                                placeholder="DD MMM YYYY"
                                                label="Expired time"
                                                value={state.groups[i].expiredTime}
                                                size='small' 
                                                inputVariant='outlined'
                                                onChange={(date)=>onChangeDate(date,i)}
                                            />
                                            </MuiPickersUtilsProvider>
                                            </div>
                                            </div>
                                            {i!==0&&
                                            <div>
                                                <img onClick={()=>deleteGroup(i)} src={Close} style={{width:25,cursor:'pointer'}}/>
                                            </div>
                                            }
                                        </div>
                                    )
                            })}
                            {active_question!==0&&active_options===0&&state.groups.length<3&&<div onClick={addGroup} className='add-participant'>
                                <p><span><img src={Plus} style={{width:15}}/></span>&nbsp;ADD MORE GROUP</p>
                            </div>}
                            {active_question!==0&&active_options===1&&<div style={{width:'70%'}}>
                                <TextField
                                    label={<>Name <span style={{color:'red'}}>*</span></>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='name'
                                    type='text'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                                <TextField
                                    label={<>Email <span style={{color:'red'}}>*</span></>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='email'
                                    type='email'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                                <TextField
                                    label={<>Handphone No. <span style={{color:'red'}}>*</span></>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='phone'
                                    type='number'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                                <TextField
                                    label={<>Job title </>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='position'
                                    type='text'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                                <TextField
                                    label={<>Department </>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='department'
                                    type='text'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                                <TextField
                                    label={<>Company name <span style={{color:'red'}}>*</span></>}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    name='company'
                                    type='text'
                                    className={classes.textField}
                                    onChange={(e)=>onChangeData(e)}
                                />
                            </div>}
                            {active_question!==0&&active_options===2&&result.map((data,i)=>(
                                <div key={i}>
                                    <p style={{fontSize:14,color:'#777777',fontWeight:"bold",margin:'20px 0px 20px 0px'}} >Link for {data.text} available until {moment(data.time).format("D MMMM YYYY")}</p>
                                    <div className='div-flex div-align-center'>
                                        <div style={{width:'70%'}}>
                                        <TextField
                                            label={<>Link for {data.text} </>}
                                            color='primary'
                                            variant='outlined'
                                            size='small'
                                            name='position'
                                            type='text'
                                            value={`${window.location.href}/${data.url}`}
                                            className={classes.textField}
                                            // onChange={(e)=>onChangeData(e)}
                                            multiline
                                        />
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <CopyToClipboard text={`${window.location.href}/${data.url}`}
                                        onCopy={() => handleTooltipOpen(i)}>
                                            {/* <Tooltip
                                                onOpen={()=>handleTooltipOpen(i)}
                                                onClose={()=>handleTooltipClose(i)}
                                                open={open[i]}
                                                disableFocusListener
                                                disableHoverListener
                                                title="Copied"
                                                arrow
                                            >
                                                <Button   size='large' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Copy</Button>
                                            </Tooltip> */}
                                            <Button   size='large' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>{renderCopied(i)}</Button>
                                            
                                        </CopyToClipboard>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button onClick={()=>window.open(`${window.location.href}/${data.url}`,'_blank')} size='large' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Go to link</Button>
                                    </div>
                                </div>
                            ))

                                
                            }
                            <br/>
                           
                            {active_options===0&&active_question!==0&&
                                <div className='div-flex div-justify-between' style={{width:'100%'}}>
                                <p style={{fontSize:14,color:'#777777',width:'70%'}}>{audit.active_language==='id'?'Setelah membuat kelompok, Anda akan mendapatkan link untuk setiap kelompok yang dapat Anda bagikan kepada responden untuk mengisi survei.':`After creating the groups, you will get a link for each group that you  can share with the respondents to fill in the survey.`}</p>
                                <Button  onClick={next} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Selanjutnya':'Next'}</Button>
                                </div>
                            }
                            {active_question===0&&
                             <div style={{textAlign:'right',width:'100%'}}>
                             <Button onClick={clickNext} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Mulai':'Start'}</Button>
                             
                             </div>
                            }
                            {active_options===1&&
                             <Button disabled={handleDisable()} onClick={generateLink} size="large" style={{borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Generate link':'Generate link'}</Button>
                             }
                             {active_options===2&&
                                <p style={{width:'70%',fontSize:14,color:'#777777'}}>{audit.active_language==='id'?'Hasil Penilaian Kompetensi HR Business Partner akan kami sampaikan oleh perwakilan kami setelah batas waktu pengisia':'The results of the HR Business Partner Competency Assessment will be delivered by our representatives after the deadline for filling out'}</p>
                             }
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
