import React from 'react'
import '../style.css'
import CloseAudit from '../../../assets/image/CloseAudit.svg'
import { Button } from '@material-ui/core'
import {useDispatch,useSelector} from 'react-redux'
import Check from '../../../assets/image/Check.svg'
import {setActiveQuestion} from '../../../redux/action/audit'
export default function Intro(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const {detail_survey,list_survey,active_survey,active_question,list_jawaban}=audit

    const startAudit=()=>{
        props.tabToggle('question','intro')
    }
    const toMenu=()=>{
        props.tabToggle('audit','intro')

    }
    const findActiveQuestion=(id)=>{
        let survey=list_survey.filter((data)=>{
            return data.id===id
        })
        return survey[0]
    }
    const findActiveJawaban=(id)=>{
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id
        })
        // console.log('jawaban', jawaban)
        if(jawaban.length>0){
            return jawaban[0]
        }else{
            return {id:id,option:'last'}
        }
    }
    const renderIsActive=(data)=>{
        let a=findActiveJawaban(data.id)
        // console.log('a', data)
        if(data.active===true||a.option!==null){
            return true
        }else{
            return false
        }
        // let a=findActiveJawaban(data.id)
        // console.log('a', a,data.visited)
        // if(a.option!=='last'){
        //     if(a.option!==null){
        //         return true
        //     }else{
        //         return false
        //     }
        // }else{
        //     if(data.visited){
        //         return true
        //     }else{
        //         return false
        //     }
        // }
    }
    const toQuestion=(index)=>{
        props.tabToggle('question','list_question')
        detail_survey[audit.active_question].active=false 
        detail_survey[index].active=true
        dispatch(setActiveQuestion(index))
    }
    
    return (
        <div>
            <div className='question-wrapper'>
                <div className='blue-section'>
                    <div style={{backgroundColor:'#00a1ed',zIndex:2}}>
                        <div className='blue-header'>
                            <Button onClick={()=>props.tabToggle('question','list_question')} size='medium' variant='text' style={{color:'white',fontWeight:'bold',margin:0}} className='remove-capital'>Back</Button>
                            {/* <img src={CloseAudit} onClick={toMenu}/> */}
                            <div style={{width:'100%',textAlign:'center'}}>
                                {/* <h3>HR Diagnostics</h3> */}
                            </div>
                        </div>
                        
                        {/* <div className='progress-bar'>
                            <div className='div-flex div-justify-between progress-title'>
                                <p>Intoduction</p>
                                <p>See all</p>
                            </div>
                            <progress className='bar' max="100" value="100"></progress>
                        </div> */}
                    </div>
                    
                    
                </div>
                <div className='white-wrapper'>
                    <div className='question'>
                        <div style={{padding:20}}>
                            <div className='div-flex div-justify-between div-align-center'>
                                <p className='daftar-kuesioner'>{audit.active_language==='id'?'Daftar kuesioner':'List quesioner'}</p>
                                <div className='language'>
                                    <p className={audit.active_language==='en'?'color-blue':'color-grey'} onClick={()=>props.setLang('en')}>EN</p>
                                    &nbsp;&nbsp;
                                    <div style={{width:1,height:18,backgroundColor:'grey'}}></div>
                                    &nbsp;&nbsp;
                                    <p className={audit.active_language==='id'?'color-blue':'color-grey'} onClick={()=>props.setLang('id')}>ID</p>

                                </div>
                            </div>
                            <h1>{findActiveQuestion(active_survey).title}</h1>
                            <br/>
                            <div className='question-content'>
                                <p className={detail_survey[0].visited?'color-blue':''}>Introduction</p>
                                <br/>
                                <ol className='list-ol' >
                                    {detail_survey.map((data,i)=>{
                                        if(data.items.length>0&&detail_survey.length-1!==i){
                                            return(
                                                <div key={i}>
                                                    <li onClick={()=>toQuestion(i)}  className={renderIsActive(data)?'question-filled':''}>
                                                        <div className='div-flex'>
                                                        <p>{audit.active_language==='id'?`${data.items[0].text.id.substring(0,35)}...`:`${data.items[0].text.en.substring(0,35)}...`}</p>
                                                        {findActiveJawaban(data.id).option!==null&&<img src={Check}/>}
                                                        </div>
                                                    </li>
                                                    <div style={{width:'100%',height:1,backgroundColor:'grey'}}></div>
                                                </div>
                                            )  
                                        }
                                    })}
                                </ol>
                                {/* <br/> */}
                                {/* <div style={{textAlign:'right'}}>
                                    <Button onClick={startAudit} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Mulai':'Start'}</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
