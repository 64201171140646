import React,{useState,useEffect} from 'react'
// import './style.css'
import {Button,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import {useDispatch,useSelector} from 'react-redux'
import {getNewDtra,getDetailSurvey3,getDetailSurvey2,setActiveQuestion,setJawaban,postAudit,getDetailSur, checkSurvey, getDetailSurvey, getDetailEdtra, } from '../../../redux/action/audit'
import * as actionTypes from '../../../redux/constant/audit'
import Loader from '../../../components/Loading'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Kurang from '../../../assets/image/Kurang.jpg'
import Cukup from '../../../assets/image/Cukup.jpg'
import Baik from '../../../assets/image/Baik.jpg'
import SangatBaik from '../../../assets/image/SangatBaik.jpg'
import Logo from '../../../assets/image/logo.png'
import {isMobile} from 'react-device-detect'
import Modal from '../../../components/modal'
import InputMask from 'react-input-mask'
import Welcome from './welcome'
import Pertanyaan from './Pertanyaan'
import Demografi from './Demografi'
import Result from './Result'
import CreateLink from './CreateLink'
import ListLink from './ListLink'
import {withRouter} from 'react-router-dom'
import Ranking from './Ranking'



export default function NewDtra(props) {
    const dispatch=useDispatch()
    const audit = useSelector(state => state.audit)
    const [field,setField]=useState('')
    const[answer,setAnswer]=useState(null)
    const[survey_id,setSurveyId]=useState(null)
    const [tab,setTab]=useState('assestment')
    const [result,setResult]=useState(null)
    const [isOther,setIsOther]=useState(false)
    const [isOther2,setIsOther2]=useState(false)
    let {uuid}=props.match.params
    const {detail_survey,active_question,total_question,list_jawaban}=audit
    let org_index=0
    useEffect(()=>{
        
        if(uuid){
            dtraGetUuid()
        }else{
            dtraGet()
        }
       
    },[])
    const dtraGetUuid=async ()=>{
        let check=await dispatch(checkSurvey(uuid))
        if(check){
            let getId=await dispatch(getNewDtra())
            if(getId){
                setSurveyId(getId.data.id)
                let data=[
                    {
                        itemType:'intro',
                       ...getId.data
        
                    },
                ]
                let getDetail=await dispatch(getDetailEdtra(getId.data.id))
                if(getDetail){
                    getDetail.map((detail,i)=>{
                        if(detail.itemType===''){
                            data.push(
                                {
                                    itemType:'intro',
                                    title:detail.intro.id,
                                    intro:detail.pageNumber===1?
                                        'Melalui survey ini, perusahaan ingin memahami dengan sebenar-benarnya mengenai persepsi Anda terhadap tempat kerja saat ini. Perusahaan ingin mengetahui seberapa puas dan happy, serta merasa engage Anda dengan pekerjaan dan tentunya dengan perusahaan Anda. Salah satu cara yang dapat dilakukan untuk memahami persepsi dari karyawan adalah dengan melakukan sebuah survey yang hasilnya nanti diharapkan dapat membantu perusahaan dalam melakukan improvement ke depan. <br/><br/>Isilah dengan jujur sesuai dengan pengalaman Anda selama 6 (enam) bulan terakhir.<br/><br/>Survey ini sifatnya anonim, namun dimohon kesediaannya untuk mengisi beberapa informasi jabatan untuk menjadi dasar analisis.<br/><br/>Untuk itu kami mohon kesediaan Anda untuk meluangkan waktu mengisi survey sekitar +/- 15 menit.'
                                        :'Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan keniscayaan terlepas bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industri. Asesmen singkat berikut memberi gambaran tentang kesiapan organisasi Anda melakukan transformasi digital, baik dari segi strategi maupun elemen organisasi pendukung. Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Jika Anda mewakili organisasi pemerintahan atau nirlaba, pikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda'
                                    
                                },
                                {
                                    itemType:'tutorial',
                                    title:detail.intro.id,
                                    tutorial:detail.pageNumber===1?'part-1':'part-2'
                                }
                            )
                        }else{
                            data.push(detail)
                        }
                    })
                    dispatch({
                        type:actionTypes.GET_DETAIL_SURVEY2,
                        payload:[...data]
                    })
                }
            }   
        }else{
            setTab('expired')
        }
    }
    const dtraGet=async ()=>{
        let res=await dispatch(getNewDtra())
        if(res){
            setSurveyId(res.data.id)
            let data=[
                {
                    itemType:'intro',
                   ...res.data
    
                },
                {
                    itemType:'create_link',
                   ...res.data
    
                },
                {
                    itemType:'list_link',
                   ...res.data
    
                },
                
            ]
                dispatch({
                    type:actionTypes.GET_DETAIL_SURVEY2,
                    payload:data
                })
            // let res2=await dispatch(getDetailSurvey3(res.data.id))
            // if(res2){
            //     dispatch({
            //         type:actionTypes.GET_DETAIL_SURVEY2,
            //         payload:[...data,...res2]
            //     })
            // }
        }
    }
    const findActiveJawaban2=()=>{
        let id_question=detail_survey[active_question]
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id_question.id
        })
        // console.log('jawaban,id_question', id_question,list_jawaban)
        return jawaban[0]
    }
    const onChangeDataOrg=(index,value)=>{
        setField(value)
        // console.log('list_jawaban[list_jawaban.length-1][index]', list_jawaban[list_jawaban.length-1].data_org[index])
        list_jawaban[list_jawaban.length-1].data_org[index].answerText=value
    }
    const chooseAnswer=(id)=>{
        // console.log('id', id)
        let a=findActiveJawaban2(id).option=id
        dispatch(setJawaban([...list_jawaban]))
        setAnswer(id)

    }
    const next=()=>{
        setAnswer(null)
        dispatch(setActiveQuestion(active_question+1))
    }
    // const nextForDemografi=()=>{
    //     dispatch(setActiveQuestion(active_question+1))

    // }
    const back=()=>{
        dispatch(setActiveQuestion(active_question===0?0:active_question-1))
    }
    const onSetActive = (index) => {
        // dispatch(setActiveQuestion(index + 2))
    }
    const handleDisable=()=>{
        if(org_index!==active_question){

            if(detail_survey[active_question].itemType==='ranking'){
                return false
            }else{
                let findQ=list_jawaban.filter((d)=>{
                    return d.id===detail_survey[active_question].id
                })[0]
                if(answer!==null){
                    return false
                }else{
                    if(findQ.option!==null){
                        return false
                    }else{
                        return true

                    }
                }
            }
            
        }else{
            let filter=list_jawaban.filter((data)=>{
                return data.option===null
            })

            if(detail_survey[active_question].itemType==='ranking'){
                return false
            }else{
                if(filter.length>0){
                    return true
                }else{
                    return false
                }
            }
            
        }
    }
    const handleDisable2=()=>{
        let {data_org}=list_jawaban[org_index]
        var re = /\S+@\S+\.\S+/;
        let status=[]
        data_org.map((d,i)=>{
            if(d.type==='textfield'){
                status.push(d.answerText==='')
            }
            if(d.type==='email'){
                status.push(!re.test(d.answerText))
            }
            if(d.type==='single'||d.type==='dropdown'){
                status.push(d.ratingId===0)
            }
            if(d.type==='singleother'){
                if(isOther[i]){
                    status.push(d.ratingId===0||d.answerText==='')
                }else{
                    status.push(d.answerText==='')
                }
            }
        })
        let disable=status.includes(true)
        return disable

        
    }
    const onSubmit=async ()=>{
        let new_jawab=[]
        // list_jawaban.map((data,i)=>{
        //     if(list_jawaban.length-1!==i){
        //         new_jawab.push({id:0,surveyId:survey_id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:data.option,answerText:''})

        //     }else{
        //         new_jawab.push(...data.data_org)
        //     }
        // })
        list_jawaban.map((data,i)=>{
            if(i!==0){
                if(data.itemId!==0){
                    new_jawab.push({id:0,surveyId:survey_id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:parseInt(data.option),answerText:''})

                }

            }else{
                let new_org=[]
                data.data_org.map((org)=>{
                    new_org.push({...org,ratingId:parseInt(org.ratingId)})
                })
                // console.log('data', data)
                new_jawab.push(...new_org)
            }
        })
        let new_new_jawaban={
            surveyId:survey_id,
            uuid:uuid,
            responses:new_jawab
        }
        let res=await dispatch(postAudit(new_new_jawaban))
        if(res){
            // props.tabToggle('result','question')
            setTab('result')
            setResult(res.data)
        }

    }
    const onChangeOther=(index,value,type,name)=>{
        // console.log('value,type,name', value,type,name)
        setField(value)
        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther({...isOther,[index]:true})
                list_jawaban[org_index].data_org[index].answerText=''
    
                list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
            }else{
    
                setIsOther({...isOther,[index]:false})

                list_jawaban[org_index].data_org[index].answerText=value
                list_jawaban[org_index].data_org[index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            list_jawaban[org_index].data_org[index].answerText=value

        }
        
    }
    const onChangeDemografi=(index,value,type,name)=>{
        setField(value)
        if(type==='singleother'){

        }else if(type==='single'){
            list_jawaban[org_index].data_org[index].answerText=''
    
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
        }else if(type==='dropdown'){
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)

        }else{
            list_jawaban[org_index].data_org[index].answerText=value
        }
    }
    const onDragEnd=async (result)=>{
        const {draggableId, source, destination, type} = result;
        
        if(!destination){
            return;
        }
        // if()
        let filter=list_jawaban.filter((data)=>{
            return data.slug===draggableId
        })
        list_jawaban.splice(source.index,1)
        // if()
        let check=list_jawaban.filter((data)=>{
            return data.slug===draggableId
        })
        if(check.length<1){
            list_jawaban.splice(destination.index,0,filter[0])
        }
        
    }
    const onBeforeCapture=(result)=>{
        // console.log('result before capture', result)
    }
    // console.log(`detail_survey`, list_jawaban)
    return (
        <div>
            <Loader/>
            {tab==='assestment'?
            <>
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='intro'&&
                <Welcome 
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='create_link'&&
                <CreateLink 
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='list_link'&&
                <ListLink 
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='tutorial'&&
                <Welcome
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='single'&&
                <Pertanyaan
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    survey_length={detail_survey.length}
                    onSubmit={onSubmit}
                    onDragEnd={onDragEnd}
                    onBeforeCapture={onBeforeCapture}
                    list_jawaban={list_jawaban}
                    total_question={total_question}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='ranking'&&
                <Ranking
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    survey_length={detail_survey.length}
                    onSubmit={onSubmit}
                    onDragEnd={onDragEnd}
                    onBeforeCapture={onBeforeCapture}
                    list_jawaban={list_jawaban}
                    total_question={total_question}

                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='varied'&&
                <Demografi
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    handleDisable2={handleDisable2}
                    onSetActive={onSetActive}
                    onChangeDemografi={onChangeDemografi}
                    list_jawaban={list_jawaban}
                    isOther={isOther}
                    isOther2={isOther2}
                    onChangeOther={onChangeOther}
                    org_index={org_index}
                />
            }
            
           
            </>
            :
                <Result result={result}/>
            }
        </div>
    )
}
