import React,{useState,useEffect} from 'react'
import './style.css'
import {Button,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import {useDispatch,useSelector} from 'react-redux'
import {getLps,getDetailSurvey2,setActiveQuestion,setJawaban,postAudit,getDetailSurvey3} from '../../../redux/action/audit'
import * as actionTypes from '../../../redux/constant/audit'
import Loader from '../../../components/Loading'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Kurang from '../../../assets/image/Kurang.jpg'
import Cukup from '../../../assets/image/Cukup.jpg'
import Baik from '../../../assets/image/Baik.jpg'
import SangatBaik from '../../../assets/image/SangatBaik.jpg'
import Logo from '../../../assets/image/logo.png'
import {isMobile} from 'react-device-detect'
import Modal from '../../../components/modal'
import InputMask from 'react-input-mask'

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 5,
      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const PetunjukPengisian=({detail_survey,next,back})=>{
    return(
        <div style={{width:'100%'}}>
            <div style={{padding:'20px 20px 0px 20px'}}>
                <h3>Petunjuk Pengisian Digital Transformation Readiness Assessment</h3>
                <br/>
                <p>Berikan nilai (dengan rentang dari 1 sampai 4) yang sesuai dengan karakteristik LPS saat ini dalam memenuhi perilaku antara pernyataan pertama atau pernyataan kedua. Bila Anda merasa LPS lebih cenderung ke perilaku pada pernyataan pertama, berikan nilai antara 1 atau 2. Namun bila Anda merasa bahwa LPS lebih cenderung ke perilaku pada pernyataan kedua, maka berikan nilai 3 atau 4. Bahkan jika LPS belum mencerminkan perilaku pada pernyataan pertama sama sekali, tetap berikan nilai 1.<br/><br/>Contohnya adalah sebagai berikut</p>
                <br/>
                
            </div>
            <div className='kolom-isi hide-mobile'>
                <div className='square-1'>
                    <p>Kami fokus dalam berinteraksi dengan stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini.</p>
                </div>
                <div className='square-middle '>
                <RadioGroup row aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                    </RadioGroup>
                </div>
                <div className='square-2'>
                    <p>Kami fokus pada perubahan perilaku digital stakeholder utama kami dan menelusuri bagaimana perjalanan stakeholder utama dalam berinteraksi dengan kami.</p>
                </div>
            </div>
            <div className='kolom-isi-mobile'>
                <div style={{width:'70%',display:'flex',justifyContent:'space-between',flexDirection:'column',height:'100%'}}>
                    <div className='square-1' style={{marginBottom:10}}>
                        <p>Kami fokus dalam berinteraksi dengan stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini.</p>
                    </div>
                    
                    <div className='square-2'>
                        <p>Kami fokus pada perubahan perilaku digital stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience dalam berinteraksi dengan kami.</p>
                    </div>
                </div>
                <div className='square-middle '>
                    <RadioGroup column aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                    </RadioGroup>
                </div>
            </div>
            <br/>
            <div className='div-flex div-justify-between' style={{padding:20}} >
                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                <Button onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Ikuti Survey</Button>
            </div>
        </div>
        
    )
}
const Introduction=({detail_survey,next})=>{
    return(
        <div style={{padding:20}}>
            <h3>{detail_survey.title} </h3>
            <br/>
            <p style={{lineHeight:2}}>Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan sebuah keniscayaan terlepas dari apapun bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industrinya. 
            Asesmen singkat berikut diadakan oleh <b>One GML</b> khusus untuk <b>LPS</b> yang bertujuan memberi gambaran tentang kesiapan LPS dalam melakukan transformasi digital, baik dari segi strategi maupun elemen organisasi pendukung. Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Jika Anda mewakili LPS, pikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda.</p>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={next} color='primary' variant='contained' size='large' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
            </div>
        </div>
    )
}
const Welcome=({detail_survey,next,back})=>{
    return(
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <h2>Selamat datang, </h2>
                        <p>Anda sedang menjalani proses <b>Digital Transformation Readiness Assessment </b></p>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                            {detail_survey.itemType==='intro'&&
                                <Introduction 
                                    detail_survey={detail_survey}
                                    next={next}
                                    back={back}
                                />
                            }
                            {detail_survey.itemType==='tutorial'&&
                                <PetunjukPengisian 
                                    detail_survey={detail_survey}
                                    next={next}
                                    back={back}
                                />
                            }
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
const Pertanyaan=({next,back,detail_survey,chooseAnswer,findActiveJawaban2,active_question,handleDisable,survey_length,onSubmit})=>{
    const [need_help,setNeedHelp]=useState(false)
    const child=()=>{
        return(
            <div className='help-mobile'>
                <p>
                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                <br/><br/>
                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
            </div>
        )
    }
    const modToggle=()=>{
        setNeedHelp(!need_help)
    }
    return(
        <div className='welcome-wrapper'>
               {isMobile&&<Modal title="" isOpen={need_help} children={child()} modalToggle={modToggle}/>}
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        {/* <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div> */}
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative',width:'100%'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%',height:'100%',position:'relative'}}>
                            <div style={{padding:'20px 20px 0px 20px'}}>
                            <div className='hide-mobile div-flex div-align-center div-justify-between'>
                                <p style={{color:'#252525',fontSize:18}}>Survey</p>
                                &nbsp;
                                <div style={{width:'70%',display:'flex',alignItems:'center'}}>
                                <progress className='bar-dtra' max={20} value={active_question+1-2}></progress>

                                </div>
                                &nbsp;
                                <p style={{color:'#808080',fontSize:12}}>{detail_survey.title.id}</p>
                                &nbsp;
                                <div className='help' onClick={()=>modToggle()}>?</div>&nbsp;

                            </div>
                            {need_help&&!isMobile&&<div className='help-backdrop' onClick={()=>setNeedHelp(false)}></div>}
                            {need_help&&!isMobile&&<div className='help-wrapper'>
                                <p>
                                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                                <br/><br/>
                                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
                            </div>}
                            <div className='show-mobile'>
                                <div className='div-flex div-justify-between'>
                                    <p>Data Demografi</p>
                                    <div className='div-flex div-align-center'>
                                        <p>{detail_survey.title.id}</p>
                                        &nbsp;&nbsp;
                                        <div className='help' onClick={()=>setNeedHelp(!need_help)}>?</div>
                                    </div>
                                </div>
                                <br/>
                                <progress className='bar-dtra' max={22} value={active_question+1-2}></progress>

                            </div>
                                
                            </div>
                            <br/>
                            <div className='kolom-isi hide-mobile'>
                                <div className='square-1'>
                                    <p>{detail_survey.items[0].text.id}</p>
                                </div>
                                <div className='square-middle '>
                                    <RadioGroup onChange={(e)=>chooseAnswer(e.target.value)} value={active_question>1?parseInt(findActiveJawaban2().option):0} row aria-label="position" name="position" defaultValue="1">
                                        {detail_survey.items[0].options.map((data,i)=>(
                                            <FormControlLabel
                                                value={data.id}
                                                control={<Radio color="primary" />}
                                                label={<p>{data.text.id}</p>}
                                                labelPlacement="top"
                                                style={{margin:3,height:60,fontWeight:'bold'}}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                                <div className='square-2'>
                                    <p>{detail_survey.items[0].text.en}</p>
                                </div>
                            </div>
                            <div className='kolom-isi-mobile'>
                                <div style={{width:'70%',display:'flex',justifyContent:'space-between',flexDirection:'column',height:'100%'}}>
                                    <div className='square-1' style={{marginBottom:20}}>
                                        <p>{detail_survey.items[0].text.id}</p>
                                    </div>
                                    
                                    <div className='square-2'>
                                        <p>{detail_survey.items[0].text.en}</p>
                                    </div>
                                </div>
                                <div className='square-middle '>
                                    <RadioGroup onChange={(e)=>chooseAnswer(e.target.value)} value={active_question>1?parseInt(findActiveJawaban2().option):0} column aria-label="position" name="position" defaultValue="1">
                                        {detail_survey.items[0].options.map((data,i)=>(
                                            <FormControlLabel
                                                value={data.id}
                                                control={<Radio color="primary" />}
                                                label={<p>{data.text.id}</p>}
                                                labelPlacement="top"
                                                style={{margin:3,height:60,fontWeight:'bold'}}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className='div-flex div-justify-between footer-form' style={{padding:25,bottom:0}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                &nbsp;&nbsp;
                                {active_question+1-2===survey_length-2?
                                <Button disabled={handleDisable()} onClick={onSubmit} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Lihat hasil</Button>
                                :
                                <Button disabled={handleDisable()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                                }
                                {/* <Button disabled={handleDisable()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button> */}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
const Demografi = ({onChangeDemografi, next, back, detail_survey, chooseAnswer, findActiveJawaban2, active_question, handleDisable,handleDisable2, onSetActive,list_jawaban,isOther,isOther2,onChangeOther1,onChangeOther2})=>{
    // console.log('findActiveJawaban2', findActiveJawaban2())
    // console.log(`detail_survey,list_jawaban`, detail_survey,list_jawaban)
    const classes=useStyles()
    const renderOtherDisable=(answer,id)=>{
        // console.log('answer,id', answer,id)
        if(answer===id.toString()){
            return false
        }else{

            return true
        }
    }
    return(
        <div className='welcome-wrapper'>
               
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        {/* <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div> */}
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative',width:'100%'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:350,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%'}}>
                            <div >
                                <div style={{padding:'20px 20px 0px 20px'}}>
                                <div className='hide-mobile div-flex div-align-center div-justify-between'>
                                    <p style={{color:'#252525',fontSize:18}}>Data Demografi</p>
                                    &nbsp;
                                    <div style={{width:'70%',display:'flex',alignItems:'center'}}>
                                    <progress className='bar-dtra' max={21} value={0}></progress>

                                    </div>
                                    &nbsp;
                                    <p style={{color:'#808080',fontSize:12}}>{detail_survey.title.id}</p>

                                </div>
                                <div className='show-mobile'>
                                    <div className='div-flex div-justify-between'>
                                        <p>Data Demografi</p>
                                        <p>{detail_survey.title.id}</p>
                                    </div>
                                    <br/>
                                    <progress className='bar-dtra' max={21} value={0}></progress>

                                </div>
                                </div>
                                <br/>
                                <div className='demografi-wrapper'>

                            {/* <div className=' hide-mobile'> */}
                                {detail_survey.items.map((data,i)=>{
                                    if(data.itemType==='text'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                                <TextField
                                                    label={data.text.id}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    type='text'
                                                    name={data.text.id}
                                                    className={classes.textField}
                                                    onChange={(e)=>onChangeDemografi(i,e.target.value,data.itemType)}
                                                    onFocus={(e) => onSetActive(i)}
                                                    value={list_jawaban[0].data_org[i].answerText}
                                                />
                                            </div>
                                        )
                                    }
                                    if(data.itemType==='phone'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                            <InputMask
                                                maskChar={null}
                                                mask="9999-9999-9999-9999"
                                                onFocus={(e)=>onSetActive(i)}
                                                onChange={(e)=>onChangeDemografi(i,e.target.value,data.itemType)}
                                                value={list_jawaban[0].data_org[i].answerText}
                                            >
                                            {() =>
                                                <TextField
                                                    label={data.text.id}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    name={data.text.id}
                                                    className={classes.textField}
                                                />
                                            }
                                            </InputMask>
                                            </div>
                                        )
                                    }
                                    if(data.itemType==='single'){
                                        return(
                                            <div key={i} >
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{data.text.id}</p>
                                                <RadioGroup onFocus={(e) => onSetActive(i)} style={{marginBottom:8}} aria-label="gender" name="gender1" value={parseInt(list_jawaban[0].data_org[i].ratingId)} onChange={(e)=>onChangeDemografi(i,e.target.value,data.itemType)}>
                                                    <div className='lps-flex'>
                                                        {data.options.map((dat,i)=>(
                                                            <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777'}}>{dat.text.id}</p>} />

                                                        ))}
                                                    
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        )
                                    }
                                    if(data.itemType==='singleother'){
                                        return(
                                            <div key={i}>
                                                <br/>
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{data.text.id}</p>
                                                    <RadioGroup onFocus={(e) => onSetActive(i)} aria-label="gender" name="gender1" value={parseInt(list_jawaban[0].data_org[i].answerText)} onChange={(e)=>onChangeOther1(i,e.target.value,data.itemType,e.target.name)}>
                                                    {/* <div className='form-demografi ' > */}
                                                        <div className='lps-flex'>
                                                            {data.options.map((dat,i)=>(
                                                                <FormControlLabel  className='radio-height'  value={dat.id} name={dat.text.id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{dat.text.id}</p>} />
                                                            ))}
                                                        {/* </div> */}
                                                        
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        )
                                    }
                                    if(data.itemType==='dropdown'){
                                        return(
                                            <div key={i}>
                                                <br/>
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{data.text.id}</p>
                                                    <RadioGroup onFocus={(e) => onSetActive(i)} aria-label="gender" name="gender1" value={parseInt(list_jawaban[0].data_org[i].ratingId)} onChange={(e)=>onChangeDemografi(i,e.target.value,data.itemType)}>
                                                    {/* <div className='form-demografi ' > */}
                                                        <div className='lps-flex'>
                                                            {data.options.map((dat,i)=>(
                                                                <FormControlLabel  className='radio-height'  value={dat.id} name={dat.text.id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{dat.text.id}</p>} />
                                                            ))}
                                                        {/* </div> */}
                                                        
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        )
                                    }
                                })}
                               
                                </div>
                            </div>
                            
                            <div className='div-flex div-justify-between' style={{padding:25}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                <Button disabled={handleDisable2()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}

const Form=({next,back,detail_survey,onChangeDataOrg,onSubmit,handleDisable2})=>{
    const classes=useStyles()
    
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%'}}>
                            <div style={{padding:'20px 20px 0px 20px'}}>
                                <p>{detail_survey.intro.id}</p>
                                <br/>
                                
                            </div>
                            <div className='kolom-isi2 '>
                                <div className='field-kolom'>
                                {detail_survey.items.map((data,i)=>{
                                    if(data.itemType==='textfield'||data.itemType==='email'){
                                        return(
                                            <TextField
                                                key={i}
                                                label={<>{data.text.id} <span style={{color:'red'}}>*</span></>}
                                                color='primary'
                                                variant='outlined'
                                                size='small'
                                                name='name'
                                                className={classes.textField}
                                                onChange={(e)=>onChangeDataOrg(i,e.target.value)}
                                            />
                                        )
                                    }
                                    if(data.itemType==='dropdown'){
                                        return(
                                            <FormControl key={i} variant="outlined" size="small" className='add-proposal__field' >
                                                <InputLabel  htmlFor="category">{data.text.id} <span style={{color:'red'}}>*</span></InputLabel>
                                                <Select  onChange={(e)=>onChangeDataOrg(i,e.target.value)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={60} className='field-radius'>
                                                    {data.options.map((option,i)=>(
                                                        <MenuItem key={i} value={option.id}>{option.text.id}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )
                                    }
                                })}
                                </div>
                            </div>
                            
                            <div className='div-flex div-justify-between' style={{padding:20}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                <Button disabled={handleDisable2()} onClick={onSubmit} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Lihat hasil</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}

const Result=({result})=>{
    const renderImg=(id)=>{
        switch (id) {
            case 1:
                return Kurang
            case 2:
                return Cukup
            case 3:
                return Baik
            case 4:
                return SangatBaik
            case 5:
                return SangatBaik
            default:
                break;
        }
    }
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div>
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div className='result-wrapp' style={{width:'100%',textAlign:'center'}}>
                            <div className='field-kolom'>
                            <br/>
                            <img src={renderImg(result!==null&&result.id)} style={{width:200}}/>
                            <br/>
                                <h2>{result!==null&&result.text.id}</h2>
                            <br/>
                            <p><b>Terimasih sudah berpartisipasi dalam survey Digital Transformation Readiness Assessment . Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.</b></p>
                            <br/>
                            <div className='div-flex div-align-center div-justify-center'>
                                <p><b>Provided by:</b></p>
                                &nbsp;&nbsp;
                                <img src={Logo} onClick={()=>window.location.assign('https://www.gmlperformance.com/')} style={{width:90,cursor:'pointer'}}/>
                                <br/>
                            </div>
                            <br/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default function Index() {
    const dispatch=useDispatch()
    const audit = useSelector(state => state.audit)
    const [field,setField]=useState('')
    const[answer,setAnswer]=useState(null)
    const[survey_id,setSurveyId]=useState(null)
    const [tab,setTab]=useState('assestment')
    const [result,setResult]=useState(null)
    const [isOther,setIsOther]=useState(false)
    const [isOther2,setIsOther2]=useState(false)

    const {detail_survey,active_question,list_jawaban}=audit
    useEffect(()=>{
        dtraGet()
    },[])

    const dtraGet=async ()=>{
        let res=await dispatch(getLps())
        if(res){
            setSurveyId(res.data.id)
            let data=[
                {
                    itemType:'intro',
                   ...res.data
    
                },
                {
                    itemType:'tutorial',
                   ...res.data
    
                },
                
            ]
            let res2=await dispatch(getDetailSurvey3(res.data.id))
            if(res2){
                dispatch({
                    type:actionTypes.GET_DETAIL_SURVEY2,
                    payload:[...data,...res2]
                })
            }
        }
    }
    const findActiveJawaban2=()=>{
        let id_question=detail_survey[active_question]
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id_question.id
        })
        // console.log('jawaban,id_question', id_question,list_jawaban)
        return jawaban[0]
    }
    const onChangeDataOrg=(index,value)=>{
        setField(value)
        // console.log('list_jawaban[list_jawaban.length-1][index]', list_jawaban[list_jawaban.length-1].data_org[index])
        list_jawaban[list_jawaban.length-1].data_org[index].answerText=value
    }
    const chooseAnswer=(id)=>{
        // console.log('id', id)
        let a=findActiveJawaban2(id).option=id
        dispatch(setJawaban([...list_jawaban]))
        setAnswer(id)

    }
    const next=()=>{
        setAnswer(null)
        dispatch(setActiveQuestion(active_question+1))
    }
    // const nextForDemografi=()=>{
    //     dispatch(setActiveQuestion(active_question+1))

    // }
    const back=()=>{
        dispatch(setActiveQuestion(active_question===0?0:active_question-1))
    }
    const onSetActive = (index) => {
        // dispatch(setActiveQuestion(index + 2))
    }
    const handleDisable=()=>{
        if(active_question>1){
            // console.log('active_question', active_question)
            if(list_jawaban[active_question-2].option!==null){
                return false
            }else{
                return true
            }
    }
    }
    const handleDisable2=()=>{
        let {data_org}=list_jawaban[0]
        var re = /\S+@\S+\.\S+/;
        let test=re.test(data_org[2].answerText)
        console.log(`data_org`, data_org)
        if(data_org[0].answerText!==''&&data_org[1].answerText!==''&&data_org[2].answerText!==''&&data_org[3].ratingId!==0&&data_org[4].ratingId!==0&&data_org[5].ratingId!==0&&data_org[6].answerText!==''){
            return false
        }else{
            return true
        }

        
    }
    const onSubmit=async ()=>{
        let new_jawab=[]
        // list_jawaban.map((data,i)=>{
        //     if(list_jawaban.length-1!==i){
        //         new_jawab.push({id:0,surveyId:survey_id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:data.option,answerText:''})

        //     }else{
        //         new_jawab.push(...data.data_org)
        //     }
        // })
        list_jawaban.map((data,i)=>{
            if(i!==0){
                new_jawab.push({id:0,surveyId:survey_id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:parseInt(data.option),answerText:''})

            }else{
                let new_org=[]
                data.data_org.map((org)=>{
                    new_org.push({...org,ratingId:parseInt(org.ratingId)})
                })
                // console.log('data', data)
                new_jawab.push(...new_org)
            }
        })
        let new_new_jawaban={
            surveyId:survey_id,
            uuid:'',
            responses:new_jawab
        }
        let res=await dispatch(postAudit(new_new_jawaban))
        if(res){
            // props.tabToggle('result','question')
            setTab('result')
            setResult(res.data)
        }

    }
    const onChangeOther1=(index,value,type,name)=>{
        // console.log('value,type,name', value,type,name)
        setField(value)

        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther(true)
                list_jawaban[0].data_org[index].answerText=''
    
                list_jawaban[0].data_org[index].ratingId=value
            }else{
    
                setIsOther(false)
                list_jawaban[0].data_org[index].answerText=value
                list_jawaban[0].data_org[index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            list_jawaban[0].data_org[index].answerText=value

        }
        
    }
    const onChangeOther2=(index,value,type,name)=>{
        setField(value)
        // console.log('value,type,name', value,type,name)

        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther2(true)
                list_jawaban[0].data_org[index].answerText=''
    
                list_jawaban[0].data_org[index].ratingId=value
            }else{
    
                setIsOther2(false)
                list_jawaban[0].data_org[index].answerText=value
                list_jawaban[0].data_org[index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            list_jawaban[0].data_org[index].answerText=value

        }
    }
    const onChangeDemografi=(index,value,type,name)=>{
        setField(value)

        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther(true)
                list_jawaban[0].data_org[index].answerText=''

                list_jawaban[0].data_org[index].ratingId=value
            }else{
                setIsOther(false)
                list_jawaban[0].data_org[index].answerText=value
                list_jawaban[0].data_org[index].ratingId=0

            }
            
        }else if(type==='otherValue'){
            list_jawaban[0].data_org[index].answerText=value

        }else if(type==='dropdown'){
            list_jawaban[0].data_org[index].ratingId=value

        }else if(type==='single'){
            list_jawaban[0].data_org[index].answerText=''
    
            list_jawaban[0].data_org[index].ratingId=value
        }else{

            list_jawaban[0].data_org[index].answerText=value

        }
    }
    // console.log('list_jawaban', list_jawaban)
    return (
        <div>
            <Loader/>
            {tab==='assestment'?
            <>
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='intro'&&
                <Welcome 
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='tutorial'&&
                <Welcome
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='single'&&
                <Pertanyaan
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    survey_length={detail_survey.length}
                    onSubmit={onSubmit}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='varied'&&
                <Demografi
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    handleDisable2={handleDisable2}
                    onSetActive={onSetActive}
                    onChangeDemografi={onChangeDemografi}
                    list_jawaban={list_jawaban}
                    isOther={isOther}
                    isOther2={isOther2}
                    onChangeOther1={onChangeOther1}
                    onChangeOther2={onChangeOther2}
                />
            }
            
            {/* { audit.detail_survey!==null&&detail_survey[active_question].itemType==='varied'&&
                <Form
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    onChangeDataOrg={onChangeDataOrg}
                    onSubmit={onSubmit}
                    handleDisable2={handleDisable2}
                />
            } */}
            </>
            :
                <Result result={result}/>
            }
        </div>
    )
}
