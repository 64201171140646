import React,{useState,useEffect} from 'react'
import {Button,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Modal from '../../../components/modal'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import {isMobile} from 'react-device-detect'
import InputMask from 'react-input-mask'

import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 5,
      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));



const Demografi = ({onChangeDemografi,onChangeOther,org_index, next, back, detail_survey, chooseAnswer, findActiveJawaban2, active_question, handleDisable,handleDisable2, onSetActive,list_jawaban,isOther,isOther2,onChangeOther1,onChangeOther2})=>{
    // console.log('findActiveJawaban2', findActiveJawaban2())
    const classes=useStyles()
    // console.log(`detail_survey`, detail_survey)
    const renderOtherDisable=(answer,id)=>{
        // console.log('answer,id', answer,id)
        if(answer===id){
            return false
        }else{

            return true
        }
    }
    return(
        <div className='welcome-wrapper'>
               
                <div className='welcome-text hide-mobile'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        {/* <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div> */}
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative',width:'100%'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:350,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%'}}>
                            <div >
                                <div className='demografi-wrapper-fullheight'>
                                {detail_survey.items.map((field,i)=>{
                                    if(field.itemType==='textfield'||field.itemType==='email'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                                <TextField
                                                    label={<>{field.text.id}<span style={{color:'red'}}>*</span></>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    type='text'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                    value={list_jawaban[org_index].data_org[i].answerText}
                                                    onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}
                                                />
                                            </div>
                                            
                                        )
                                    }else if(field.itemType==='phone'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                            <InputMask
                                                maskChar={null}
                                                mask="9999-9999-9999-9999"
                                                value={list_jawaban[org_index].data_org[i].answerText}
                                                onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}
                                            >
                                            {() =>
                                                <TextField
                                                    label={<>{field.text.id}</>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                />
                                            }
                                            </InputMask>
                                            </div>
                                        )
                                    }else if(field.itemType==='single'){
                                       return(
                                        <div key={i} >
                                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{field.text.id} <span style={{color:'red'}}>*</span></p>
                                            <RadioGroup value={list_jawaban[org_index].data_org[i].ratingId} onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}  style={{marginBottom:8}} aria-label="gender" name="gender1"  >
                                                <div className='lps-flex'>
                                                    {field.options.map((dat,i)=>(
                                                        <>
                                                        <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} />
                                                        
                                                        </>
                                                    ))}
                                                
                                                </div>
                                            </RadioGroup>
                                        </div>
                                       )
                                    }else if(field.itemType==='singleother'){
                                        return(
                                            <div key={i}>
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{field.text.id} <span style={{color:'red'}}>*</span></p>
                                                    <RadioGroup value={isOther[i]?list_jawaban[org_index].data_org[i].ratingId:parseInt(list_jawaban[org_index].data_org[i].answerText)}  aria-label="gender" name="gender1"  onChange={(e)=>onChangeOther(i,e.target.value,field.itemType,e.target.name)}>
                                                    {/* <div className='form-demografi ' > */}
                                                        <div className='lps-flex'>
                                                            {field.options.map((dat,i2)=>(
                                                                <div>
                                                                <FormControlLabel 
                                                                    key={i2}  
                                                                    className='radio-height'  
                                                                    value={dat.id} 
                                                                    name={dat.text.id} 
                                                                    control={<Radio color='primary' size='small'/>} 
                                                                    label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} 
                                                                />
                                                                {dat.text.en==='Other'&&
                                                                    <div className='form-80'>
                                                                        <TextField
                                                                            color='primary'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            name='name'
                                                                            className={classes.textField}
                                                                            onChange={(e)=>onChangeOther(i,e.target.value,'otherValue','')}
                                                                            value={isOther[i]?list_jawaban[org_index].data_org[i].answerText:''}
                                                                            disabled={renderOtherDisable(list_jawaban[org_index].data_org[i].ratingId,dat.id)}
                            
                                                                        />
                                                                    </div>
                                                                }
                                                                </div>
                                                            ))}
                                                        {/* </div> */}
                                                        
                                                    </div>
                                                </RadioGroup>
                                                <br/>
                                            </div>
                                        )
                                    }else if(field.itemType==='dropdown'){
                                        return(
                                            <div className='tdra-field'>
                                                <FormControl  variant="outlined" size="small" style={{marginTop:15}} className='add-proposal__field' >
                                                    <InputLabel  htmlFor="category">{field.text.id} <span style={{color:'red'}}>*</span></InputLabel>
                                                    <Select className={classes.textField}   onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)} value={parseInt(list_jawaban[org_index].data_org[i].ratingId)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={120} style={{borderRadius:5}}>
                                                    {field.options.map((opt,i)=>(
                                                        <MenuItem key={i}  value={opt.id}>{opt.text.id}</MenuItem>

                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                })}
                                </div>
                            </div>
                            
                            <div className='div-flex div-justify-between' style={{padding:25}} >
                                <div></div>
                                {/* <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button> */}
                                <Button disabled={handleDisable2()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default Demografi