import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AuditDesktop from "../views/audit/desktop";
import Report from "../views/audit/report";

import Tdra from "../views/audit/tdra";
import TdraReport from "../views/audit/tdra/report";
import TdraReportIndividual from "../views/audit/tdra/report_individual";

import Lps from "../views/audit/lps";
import LpsReport from "../views/audit/lps/report";
import LpsReportIndividual from "../views/audit/lps/report_individual";

import Akhlak from "../views/audit/akhlak";
import AkhlakReport from "../views/audit/akhlak/report";
import AkhlakReportAll from "../views/audit/akhlak/report_all";

import Kepemimpinan from "../views/audit/kepemimpinan";
import KepemimpinanReport from "../views/audit/kepemimpinan/report";
import KepemimpinanReport2 from "../views/audit/kepemimpinan/report2";

import NewDtra from "../views/audit/new-dtra";
import ReportEdtra from "../views/audit/new-dtra/report";
import ListLink from "../views/audit/new-dtra/ListLink";
import ListReport from "../views/audit/tdra/list";
export default function index() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={AuditDesktop} />
        <Route path="/id/:id" exact component={AuditDesktop} />

        <Route path="/organitationassessment" exact component={AuditDesktop} />
        <Route path="/hrbp" exact component={AuditDesktop} />
        <Route path="/hrbp/:uuid" exact component={AuditDesktop} />

        <Route
          path="/digital-transformation-readiness-assessment"
          exact
          component={Tdra}
        />
        <Route
          path="/digital-transformation-readiness-assessment/individual"
          exact
          component={ListReport}
        />

        <Route
          path="/digital-transformation-readiness-assessment/report"
          exact
          component={TdraReport}
        />
        <Route
          path="/digital-transformation-readiness-assessment/report/:uuid"
          exact
          component={TdraReportIndividual}
        />

        <Route
          path="/lps-digital-transformation-readiness-assessment"
          exact
          component={Lps}
        />
        <Route
          path="/lps-digital-transformation-readiness-assessment/report"
          exact
          component={LpsReport}
        />
        <Route
          path="/lps-digital-transformation-readiness-assessment/report/:uuid"
          exact
          component={LpsReportIndividual}
        />

        <Route path="/akhlak" exact component={Akhlak} />
        <Route path="/akhlak/report/:id/:uuid" exact component={AkhlakReport} />
        <Route path="/akhlak/reportall" exact component={AkhlakReportAll} />
        <Route
          path="/organitationassessment/:uuid"
          exact
          component={AuditDesktop}
        />
        <Route path="/survey/report/:id/:uuid" exact component={Report} />

        <Route path="/kepemimpinan" exact component={Kepemimpinan} />
        <Route
          path="/kepemimpinan/report/:id/:uuid"
          exact
          component={KepemimpinanReport}
        />
        <Route
          path="/kepemimpinan/report2/:id/:uuid"
          exact
          component={KepemimpinanReport2}
        />

        <Route path="/edtra" exact component={NewDtra} />
        <Route path="/edtra/link" exact component={ListLink} />
        <Route path="/edtra/link/:uuid" exact component={NewDtra} />
        <Route path="/edtra/report/:uuid" exact component={ReportEdtra} />
      </Switch>
    </Router>
  );
}
