import React,{useEffect,useState} from 'react'
import CloseAudit from '../../../assets/image/CloseAudit.svg'
import {useDispatch,useSelector} from 'react-redux'
import {setActiveQuestion,selectAnswer,postAudit} from '../../../redux/action/audit'
import Check from '../../../assets/image/Check_white.svg'
import {TextField,Button,FormControlLabel ,Checkbox, FormControl } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Modal from '../../../components/modal'
import Kurang from '../../../assets/image/Kurang.jpg'
import Cukup from '../../../assets/image/Cukup.jpg'
import Baik from '../../../assets/image/Baik.jpg'
import SangatBaik from '../../../assets/image/SangatBaik.jpg'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#9b013c',
            contrastText: '#FFFFFF',

        }
    } 
})
export default function Result(props) {
    const audit=useSelector(state=>state.audit)

    const[answer,setAnswer]=useState(null)
    const [modal_exit,setModalExit]=useState(false)
    const [checked,setChecked]=useState(0)
    const dispatch=useDispatch()
    const classes=useStyles()
    const {detail_survey,list_survey,active_survey,active_question,list_jawaban,message}=audit

    useEffect(()=>{
        detail_survey[audit.active_question].visited=true 
        // console.log('huh', detail_survey,active_question)
     },[active_question])

     const clickNext=()=>{
        if(detail_survey.length-1!==audit.active_question){
            setAnswer(null)
            detail_survey[audit.active_question].visited=true 
            dispatch(setActiveQuestion(audit.active_question+1))
        }
    }
    const clickBack=()=>{
        if(audit.active_question>0){
            dispatch(setActiveQuestion(audit.active_question-1))
        }
    }
    const findActiveSurvey=(id)=>{
        let survey=list_survey.filter((data)=>{
            return data.id===id
        })
        return survey[0]
    }
    const findActiveJawaban=(id)=>{
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id
        })
        // console.log('jawaban', jawaban)
        if(jawaban.length>0){
            return jawaban[0]
        }else{
            return {id:id,option:'last'}
        }
    }
    const findActiveJawaban2=()=>{
        let id_question=detail_survey[active_question]
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id_question.id
        })
        return jawaban[0]
    }
    const chooseAnswer=(id)=>{

        let a=findActiveJawaban2(id).option=id
        
        let new_jawaban=[
            ...list_jawaban,
            a
        ]
        setAnswer(id)
        // dispatch(selectAnswer(new_jawaban))
        // console.log('woeee', list_jawaban)

        
    }
    const renderIsActive=(data)=>{
        let a=findActiveJawaban(data.id)
        // console.log('a', data)
        if(data.active===true||a.option!==null){
            return true
        }else{
            return false
        }
    }
    const toQuestion=(index)=>{
        // window.location.assign('https://www.gmlperformance.com/organization-assessment')
        props.tabToggle('question','list_question')
        dispatch(setActiveQuestion(index))
    }
    
    const renderExit=()=>{
        return(
            <div>
                <p>Your answers will be deleted if you leave the questionnaire.</p>
                <br/>
                <div style={{width:'100%',textAlign:'right'}}>
                    <MuiThemeProvider theme={themeButton}>
                    <Button onClick={()=>window.location.assign('https://www.gmlperformance.com/organization-assessment')} style={{textTransform:'none',width:150,fontWeight:'bold'}} size="small" color="secondary" variant="text">Yes, take me home</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={()=>setModalExit(false)} style={{textTransform:'none',width:100,fontWeight:'bold'}} size="small" color="primary" variant="contained">No</Button>
                    </MuiThemeProvider>
                </div>
                
            </div>
        )
    }
    const postAudits=()=>{
        let new_jawab=[]
        list_jawaban.map((data,i)=>{
            if(list_jawaban.length-1!==i){
                new_jawab.push({id:0,surveyId:findActiveSurvey(active_survey).id,itemId:data.id,ratingId:data.option,answerText:''})

            }else{
                new_jawab.push(...data.data_org)
            }
        })
        let new_new_jawaban={
            surveyId:active_survey,
            responses:new_jawab
        }
        dispatch(postAudit(new_new_jawaban))
        // console.log('new_jawab', new_jawab)
    }
    // console.log('aihh', list_jawaban)
    const onChangeDataOrg=(index,value)=>{
        // console.log('list_jawaban[list_jawaban.length-1][index]', list_jawaban[list_jawaban.length-1].data_org[index])
        list_jawaban[list_jawaban.length-1].data_org[index].answerText=value
    }
    const onChangeCheckbox=(index,value)=>{
        list_jawaban[list_jawaban.length-1].data_org[index].ratingId=value

        setChecked(value)
    }
    const renderImg=(id)=>{
        if(id<=1){
            return Kurang
        }else if(id<=2){
            return Cukup
        }else if(id<=3){
            return Baik
        }else{
            return SangatBaik
        }
        // switch (id) {
        //     case 1:
        //         return Kurang
        //     case 2:
        //         return Cukup
        //     case 3:
        //         return Baik
        //     case 4:
        //         return SangatBaik
        //     case 5:
        //         return SangatBaik
        //     default:
        //         break;
        // }
    }
    // console.log('active_survey', active_survey)
    return (
        <div className='question-wrapper-desktop'>
            <Modal title="Are you sure you want to leave the questionnaire?" without_x={true} isOpen={modal_exit} children={renderExit()} modalToggle={()=>setModalExit(!modal_exit)}/>
            <div className='div-flex'>
                <img style={{width:20,cursor:'pointer'}} src={CloseAudit} onClick={()=>setModalExit(true)}/>
                {/* &nbsp;&nbsp;&nbsp;
                <div style={{width:'100%',textAlign:'left'}}>
                    <h3 style={{color:'white'}}>{findActiveSurvey(active_survey).title}</h3>
                </div> */}
            </div>
            <br/>
            <div className='question-desktop'>
                <div className='question-list'>
                    <p className='color-white'>{audit.active_language==='id'?detail_survey[active_question].title.id:detail_survey[active_question].title.en}</p>
                    <progress className='bar-desktop' max={detail_survey.length-1} value={active_question===0?detail_survey.length-1:active_question}></progress>
                    <br/><br/>
                    <div className='div-flex div-justify-between div-align-center'>
                        <p className='daftar-kuesioner-desktop'>Daftar kuesioner</p>
                        <div className='language-desktop'>
                            <p className={audit.active_language==='en'?'color-white':'color-black'} onClick={()=>props.setLang('en')}>EN</p>
                            &nbsp;&nbsp;
                            <div style={{width:1,height:18,backgroundColor:'grey'}}></div>
                            &nbsp;&nbsp;
                            <p className={audit.active_language==='id'?'color-white':'color-black'} onClick={()=>props.setLang('id')}>ID</p>

                        </div>
                    </div>
                    <br/>
                    <div style={{height:400,overflowY:"scroll",overflowX:'hidden'}}>
                        {/* <p className={detail_survey[0].visited===true?'color-white':'color-black'}>Introduction</p> */}
                        <p className={detail_survey[0].visited?'color-white':'color-black'}>Introduction</p>
                        <ol className='list-ol-desktop' >
                        {detail_survey.map((data,i)=>{
                                if(data.items.length>0&&detail_survey.length-1!==i){
                                    return(
                                        <div key={i}>
                                            <li onClick={()=>toQuestion(i)} style={{cursor:'pointer'}}  className={renderIsActive(data)?'question-filled-desktop':'question-black'}>
                                                <div className='div-flex'>
                                                <p>{audit.active_language==='id'?`${data.items[0].text.id.substring(0,55)}...`:`${data.items[0].text.en.substring(0,55)}...`}</p>
                                                {findActiveJawaban(data.id).option!==null&&<img src={Check} style={{width:25}}/>}
                                                &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </li>
                                            <div style={{width:'100%',height:1,backgroundColor:'white'}}></div>
                                        </div>
                                    )  
                                }
                            })}
                        </ol>
                    </div>
                </div>
                <div className='question-content-desktop'>
                    <div className='question-white-section'>
                        <div className='question-content-desktop'>
                            {message!==null&&
                            <div className='text-center'>
                                <img src={active_survey===4?Baik:renderImg(message.id)} style={{width:200}}/>
                                {findActiveSurvey(active_survey).title!=='Online BSC Quiz'&&<h3>{audit.active_language==='id'?message.text.id:message.text.en}</h3>}<br/>
                                <div style={{textAlign:'left'}}>
                                {/* <p>Terimasih sudah berpartisipasi dalam survey HR Diagnostics. Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.</p> */}
                                <p>{audit.active_language==='id'?`Terimasih sudah berpartisipasi dalam survey ${findActiveSurvey(active_survey).title}. Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.`:`Thank you for participating in the ${findActiveSurvey(active_survey).title} survey. We will contact you as soon as possible to explain more detailed information about your organization.`}</p>
                                </div>
                                <br/>
                                <div style={{textAlign:'right',width:'100%'}}>
                                        <Button onClick={()=>window.location.assign('https://api.whatsapp.com/send/?phone=+62 821-2325-3700&text=Halo%20Bapak%2FIbu%2C%20terima%20kasih%20sudah%20mengunjungi%20One%20GML.%20Ada%20yang%20dapat%20kami%20bantu%3F%0A%0AAgar%20dapat%20menjawab%20pertanyaan%20Anda%20secara%20komprehensif%2C%20mohon%20untuk%20menyertakan%20detail%20di%20bawah%20ini%3A%20%0ANama%20perusahaan%3A%20%0ANama%20Anda%3A%20%0AEmail%20bisnis%20Anda%3A%0AProduk%2Fsolusi%20One%20GML%20yang%20ingin%20di%20jelaskan%20lebih%20lanjut%3A%20%0A%28contoh%3A%20OneGML%2C%20Strategy%20Execution%2C%20atau%20yang%20lainnya%29%20%20%0A%0ATerima%20kasih.')} size="large" style={{width:150,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Hubungi kami':'Contact us'}</Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {findActiveSurvey(active_survey).title==='Online BSC Quiz'?<><Button onClick={()=>window.location.assign(`/survey/report/${active_survey}/${message.text.id}`)} size="large" style={{borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Download Hasil':'Download Result'}</Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;</>:
                                        <Button onClick={()=>window.location.assign('https://www.gmlperformance.com/')} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Home':'Home'}</Button>}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
