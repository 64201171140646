import React from 'react'
import '../style.css'
import CloseAudit from '../../../assets/image/CloseAudit.svg'
import { Button } from '@material-ui/core'
import {useDispatch,useSelector} from 'react-redux'


export default function Intro(props) {
    const audit=useSelector(state=>state.audit)
    const startAudit=()=>{
        props.tabToggle('question','intro')
    }
    const toMenu=()=>{
        props.tabToggle('audit','intro')

    }
    return (
        <div>
            <div className='question-wrapper'>
                <div className='blue-section'>
                    <div style={{backgroundColor:'#00a1ed',zIndex:2}}>
                        <div className='blue-header'>
                            <img src={CloseAudit} onClick={toMenu}/>
                            <div style={{width:'100%',textAlign:'center'}}>
                                <h3>HR Diagnostics</h3>
                            </div>
                        </div>
                        
                        <div className='progress-bar'>
                            <div className='div-flex div-justify-between progress-title'>
                                <p>Intoduction</p>
                                <p>See all</p>
                            </div>
                            <progress className='bar' max="100" value="100"></progress>
                        </div>
                    </div>
                    
                    
                </div>
                <div className='white-wrapper'>
                    <div className='title-question'>
                        <h4>HR Diagnostics</h4>
                    </div>
                    <br/>
                    <div className='question'>
                        <div style={{padding:20}}>
                            <div className='language'>
                                <p className={audit.active_language==='en'?'color-blue':'color-grey'} onClick={()=>props.setLang('en')}>EN</p>
                                &nbsp;&nbsp;
                                <div style={{width:1,height:18,backgroundColor:'grey'}}></div>
                                &nbsp;&nbsp;
                                <p className={audit.active_language==='id'?'color-blue':'color-grey'} onClick={()=>props.setLang('id')}>ID</p>

                            </div>
                            <br/>
                            <div className='question-content'>
                                <p>{audit.active_language==='id'?audit.assestment[audit.active_assestment].intro.id:audit.assestment[audit.active_assestment].intro.en}</p>
                                <br/>
                                <div style={{textAlign:'right'}}>
                                    <Button onClick={startAudit} size="large" style={{width:120,borderRadius:10,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital'>{audit.active_language==='id'?'Mulai':'Start'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
