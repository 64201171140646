import * as actionTypes from "../constant/audit";
const initialState={
    assestment:[
        {   intro:{
                id:'Survey ini mengukur Human Resources level untuk eksekusi strategi di organisasi Anda berbasis Strategy, Primary, dan Foundation. Untuk menyelesaikan survey ini, silakan berikan informasi yang sesuai dengan kondisi organisasi anda dengan memilih “Ya” atau “Tidak”.',
                en:'This survey measures the Human Resources level for strategy execution in your organization based on Strategy, Primary and Foundation. To complete this survey, please provide information that suits your organization’s conditions by selecting “Yes” or “No”.”.'
            },
            question:[
                {
                    subtitle:'Strategic',
                    id:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?',
                    en:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?'
                },
                {
                    subtitle:'Strategic',
                    id:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?',
                    en:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?'
                },
                {
                    subtitle:'Strategic',
                    id:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?',
                    en:'Apakah strategi HR anda sudah selaras dengan strategi yang diturunkan dari korporat?'
                }
            ]
        }
    ],
    list_survey:[],
    detail_survey:null,
    list_jawaban:null,
    total_question:0,
    active_question:0,
    active_survey:0,
    active_language:'en',
    message:null,
    report:null,
    group:''
    
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.SET_ACTIVE_QUESTION:
            return {
                ...state,
                active_question:action.payload
            }
        case actionTypes.SET_ACTIVE_LANGUAGE:
            return {
                ...state,
                active_language:action.payload
            }
        case actionTypes.SET_ACTIVE_SURVEY:
            return {
                ...state,
                active_survey:action.payload
            }
        case actionTypes.GET_LIST_SURVEY:
            return {
                ...state,
                list_survey:action.payload
            }
        case actionTypes.GET_DETAIL_SURVEY:
            return {
                ...state,
                detail_survey:action.payload.detail_survey,
                list_jawaban:action.payload.list_jawaban,
                total_question:action.payload.total_question
            }
        case actionTypes.GET_DETAIL_SURVEY2:
            return {
                ...state,
                detail_survey:action.payload,
            }
        
        case actionTypes.SET_ANSWER:
            return {
                ...state,
                list_jawaban:action.payload
            }
        case actionTypes.SET_MESSAGE:
            return {
                ...state,
                message:action.payload
            }
        case actionTypes.GET_DATA_REPORT:
            return {
                ...state,
                report:action.payload
            }
        case actionTypes.SET_GROUP:
            return {
                ...state,
                group:action.payload
            }
        
        default:
            return state;
    }
}

