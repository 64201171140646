import React,{useEffect,useState,useRef} from 'react'
import './style.css'
import Logo1 from '../../../assets/image/logo.png'
import Logo2 from '../../../assets/image/logo_report_2.png'
import Map from '../../../assets/image/map.svg'
import report_1 from '../../../assets/image/report/report_1.jpg'
import report_2 from '../../../assets/image/report/report_2.jpg'
import report_3 from '../../../assets/image/report/report_3.jpg'
import report_4 from '../../../assets/image/report/report_4.jpg'
import report_5 from '../../../assets/image/report/report_5.jpg'
import report_6 from '../../../assets/image/report/report_6.jpg'
import report_7 from '../../../assets/image/report/report_7.jpg'
import report_8 from '../../../assets/image/report/report_8.jpg'
import report_9 from '../../../assets/image/report/report_9.jpg'
import report_10 from '../../../assets/image/report/report_10.jpg'
import report_11 from '../../../assets/image/report/report_11.jpg'
import report_12 from '../../../assets/image/report/report_12.jpg'
import report_13 from '../../../assets/image/report/report_13.jpg'
import report_14 from '../../../assets/image/report/report_14.jpg'
import report_15 from '../../../assets/image/report/report_15.jpg'
import report_16 from '../../../assets/image/report/report_16.jpg'
import report_od from '../../../assets/image/report/report_od.png'

import Check from '../../../assets/image/Check.svg'
import Close from '../../../assets/image/Close_red.svg'
import Loader from '../../../components/Loading'

import ChevronGrey from '../../../assets/image/chevron_grey.svg'
import {getAkhlakResult} from '../../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import {Helmet} from "react-helmet";
import {Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {Chart} from 'chart.js'
import {isMobile} from 'react-device-detect'

function Report(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let init=[false,false,false,false,false]
    const [loaders,setLoaded]=useState(init)
    const {report}=audit
    const canvas = useRef(null);
    useEffect(() => {
        getReport()
      },[]);
    const getReport=async ()=>{
        let {id,uuid}=props.match.params
        let res=await  dispatch(getAkhlakResult(`/${id}/${uuid}`))
        if(res){
            let new_labels=[]
            let new_dataset=[]
            res.data.dimensions.map((data)=>{
                new_labels.push(data.title)
                new_dataset.push(data.indicators[0].value)
            })
            
            const cfg = {
                type: 'radar',
                data: {
                    labels: new_labels,
                    datasets: [
                        {
                            data: new_dataset,
                            borderColor:'#80b5fd',
                            backgroundColor:'rgba(188, 216, 255, 0.5)'
                        },
                    ],
                    
                },
        
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    
                    scale: {
                        beginAtZero: true,
                        max: 4,
                        min:1,
                        pointLabels :{
                            fontStyle: "bold",
                            fontSize:16
                        }
                    },
                      plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                          formatter: function(value) {
                            return '';
                          },
                          offset: 2,
                          padding: 0
                        }
                      },
                    elements: {
                        point:{
                            radius: 0
                        }
                    }
                }
            };
            const chart = new Chart(canvas.current.getContext('2d'), cfg);
            chart.update();
        }
        
    }
    let image1=[
        report_1,
        report_2,
        report_3,
        report_4,
    ]
    let image2=[
        report_5,
        report_6,
        report_7,
        report_8,
    ]
    let image3=[
        report_9,
        report_10,
        report_11,
        report_12,
    ]
    let image4=[
        report_1,
        report_13,
        report_14,
        report_15,
    ]
    let image5=[
        report_1,
        report_14,
        report_16,
        report_15,
    ]
    const renderImage=()=>{
        let {id,uuid}=props.match.params
        switch (parseInt(id)) {
            case 3:
                return image2
            case 2:
                return image3
            case 1:
                return image1
            case 4:
                return image4
            case 5:
                return image5
            case 6:
                return image5
            default:
                return image1
                break;
        }
    }
    let oo=[]
    const setonLoaded=(index)=>{
        
            let b=[false,true]
            
            
            let c=init.filter((data)=>{
                return data===false
            })
            console.log('index', index,oo.length)
            if(oo.length<4){

                oo.push(index)
            }else{
                oo=[]
                setTimeout(()=>{
                    if(!isMobile){
                        window.print()
                    }
                },3000)
            }
            

    }
    return (
        <>
        <Loader/>
        {report!==null&&
        <div className='report-wrapper' >
            <Helmet>
                <title>Report-Akhlak</title>
                
            </Helmet>
            <div className='report-header'>
                <div className='report-shape'></div>
                <div className='div-margin'>
                    <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                </div>
            </div>
            <div className='report-footer'>
                <div className='div-margin'>
                    <p>Report date :  {moment(report.surveyDate).format('DD MMM YYYY')}</p>
                </div>
            </div>
            <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">
                        <div className='div-flex div-margin'>
                            {renderImage().map((data,index)=>(
                                <div style={{width:140,height:140}}>
                                <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>{report.title}</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <br/>
                        <div className='report-information div-margin'>
                            <p className='text-bold'>Respondent Information</p>
                            {report.cover.map((data,i)=>(
                                <div className='div-flex'>
                                    <p style={{width:250}}>{data.indicator}</p>
                                    <p>:&nbsp;{data.text}</p>
                                </div>
                            ))} 
                        </div>
                        <br/><br/>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        {/* page1 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Hasil Penilaian Implementasi Budaya Akhlak</h1>
                        </div>
                        <br/>
                        <div className='div-margin '>
                            <p><b>{report.description}</b></p>
                            <br/>
                            <p><b>Diagram Hasil Penilaian Implementasi Budaya Akhlak:</b></p>
                            <br/>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div className="chartjs-wrapper" style={{width:'80%'}}><canvas ref={canvas} className="chartjs"></canvas></div>
                            </div>
                            <br/>
                            <table border="0" cellspacing="0" cellpadding="0">
                                <thead>
                                    <th className='akhlak-bold' style={{width:100}}>Kompetensi</th>
                                    <th className='akhlak-bold' style={{width:60}}>Nilai</th>
                                    <th className='akhlak-bold' style={{width:300}}>Interpretasi</th>
                                </thead>
                                <tbody>
                                    {report.dimensions.map((data,i)=>(
                                        <tr key={i}>
                                            <td className='akhlak-bold'>
                                                {data.title}
                                            </td>
                                            <td className='akhlak-bold'>
                                                {data.indicators[0].value.toFixed(2)}
                                            </td>
                                            <td className='akhlak-bold2'>
                                                {data.description}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='pagebreak'></div>

                        
                        
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
        </div>
         } 
        </>
    )
}
export default withRouter(Report)