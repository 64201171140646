import React,{Component} from 'react';
import logo from './logo.svg';
// import './App.css';
import Routes from './routes'
import MobileRoutes from './routes/mobileRoutes'
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles';
import {Provider} from 'react-redux'
import {store,persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react'
import {
  isMobile
} from "react-device-detect";
 class App extends Component {
  // state = {
  //   width: window.innerWidth,
  // };
  // componentWillMount() {
  //   window.addEventListener('resize', this.handleWindowSizeChange);
  // }
  
  // // make sure to remove the listener
  // // when the component is not mounted anymore
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }
  
  // handleWindowSizeChange = () => {
  //   this.setState({ width: window.innerWidth });
  // };
   render(){
   
    // const { width } = this.state;
    // const isMobile = width <= 768;
  const browserHistory = createBrowserHistory();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        {isMobile?<MobileRoutes/>:<Routes />}
      </Router>
   </ThemeProvider>
   </PersistGate>
   </Provider>
  );
}
}

export default App;
