import React,{useEffect,useState} from 'react'
import './style.css'

import {useDispatch,useSelector} from 'react-redux'
import {setTab} from '../../redux/action/general'
import {setActiveLang,setActiveQuestion,setActiveSurvey,getDetailSurvey,getListSurvey,checkSurvey} from '../../redux/action/audit'
import AuditDesktop from './desktop/index'
import IntroMobile from './mobile/intro'
import Question from './desktop/question'
import Result from './desktop/result'
import ListQuestion from './mobile/list_question'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import Loader from '../../components/Loading'
import CreateLink from './desktop/create_link'
import {withRouter} from 'react-router-dom'
import Expired from './expired'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            // main:'#FF7165',
            main:'#65b7ff',
            // contrastText: '#FFFFFF',
            contrastText: '#777777',
        }
    } 
})
function Index(props) {
    const general=useSelector(state=>state.general)
    const [uuid,setUuid]=useState('')
    const dispatch=useDispatch()
    const {tab_active}=general
    
    const tabToggle=(tab_active,tab_back)=>{
        dispatch(setTab(tab_active,tab_back))
    }
    useEffect( ()=>{
        getDetailLangsung()
    },[])
    let {id}=props.match.params
    // console.log('tab_active', general)
    const getDetailLangsung=async ()=>{
        let {uuid}=props.match.params

        if(uuid!==undefined){
            setUuid(uuid)
            let check=await dispatch(checkSurvey(uuid))
            await  dispatch(getListSurvey())

            let res=await dispatch(getDetailSurvey(4))

            if(check){
                if(res){
                    if(res.length>0){
                        await dispatch(setActiveSurvey(4))
                        dispatch(setActiveQuestion(0))
                        tabToggle('question','audit')
                    }
                }
                
               
    
            }else{
                tabToggle('expired','audit')
            }
        }
    }
    const setLang=(data)=>{
        dispatch(setActiveLang(data))
    }
    return (
        <div>
            <Loader/>
            <MuiThemeProvider theme={themeButton}>
                {tab_active==='audit'&&<AuditDesktop id={parseInt(id)} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='question'&&<Question uuid={uuid} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='result'&&<Result setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='create_link'&&<CreateLink setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='expired'&&<Expired setLang={setLang} tabToggle={tabToggle}/>}
                {/* {tab_active==='list_question'&&<ListQuestion setLang={setLang} tabToggle={tabToggle}/>} */}
            </MuiThemeProvider>
        </div>
    )
}
export default withRouter(Index)