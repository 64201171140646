import React,{useEffect} from 'react'
// import Navbar from '../../../components/navbar'
// import Footer from '../../../components/footer'
// import BannerWithoutBtn from '../../../components/tribe/banner_withoutbtn'
import BannerImg from '../../../assets/image/banner-hompage1.jpg'
import '../style.css'
import {getListSurvey,setDetailSurvey,getDetailSurvey,setActiveSurvey,setActiveQuestion} from '../../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
export default function Mobile(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let {list_survey,detail_survey}=audit
    const getById=async ()=>{
        let res=await dispatch(getListSurvey())
            if(res){
                detailSurvey(props.id)

            }
    }
    useEffect(()=>{
        let path=window.location.pathname
        if(props.id){
            getById()
        }
        if(path==='/hrbp'){
            let detail=[]
            let jawaban=[]
            detail.push({
                active:true,
                id:1,
                intro:{en:'The following are some of the behaviors related to the superior competence of a human resource (HR) practitioner. In preparation for the self-development of HRBP training, filling out this short survey aims to identify areas of competence that will be the focus of training so that your attendance at the training will be more effective.',id:'Berikut adalah beberapa perilaku yang terkait dengan kompetensi atasan seorang praktisi sumber daya manusia (SDM). Dalam persiapan pengembangan diri diklat HRBP, pengisian survey singkat ini bertujuan untuk mengidentifikasi bidang-bidang kompetensi yang akan menjadi fokus pelatihan agar absensi anda pada pelatihan lebih efektif.'},
                itemType:'',
                items:[],
                pageNumber:1,
                options:[],
                title:{en:'Introduction',id:'Pengantar'}
            })
            detail.push({
                active:false,
                id:2,
                intro:{en:'',id:''},
                itemType:'generate_link',
                items:[
                    {
                        id:2,
                        options:[],
                        text:{en:"Creation of participant",id:'Pembuatan partisipasi'}
                    }
                ],
                options:[
                    {
                        id:11,
                        intro:{en:'You can create one or more participation groups according to the needs of your organization',id:'Anda dapat membuat satu atau lebih kelompok partisipasipan sesuai dengan kebutuhan organisasi Anda'},
                        itemType:'generate_link',
                        items:[
                            {
                                id:111,
                                field:[{text:{en:'Group 1',id:'Group 1'},type:'textfield'},{text:{en:'Expired date',id:'Tanggal expired'},type:'date'}]
                            }
                        ]
                    },
                    {
                        id:12,
                        intro:{en:'We need your contact information, so we can contact you about the survey results . Please fill out the form below for generate link.',id:'Kami membutuhkan kontak Anda, sehingga kami dapat menghubungi Anda tentang hasil survei. Silakan isi formulir di bawah ini untuk membuat tautan.'},
                        itemType:'varied',
                        items:[
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Name',id:'Nama'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Email',id:'Email'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Handphone No.',id:'No Hp.'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Job title',id:'Pekerjaan'},
                                required:false
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Department',id:'Department'},
                                required:false
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Company name',id:'Nama perusahaan'},
                                required:true
                            },
                        ]
                    },
                    {
                        id:13,
                        intro:{en:'Share the link below according to the group of participants',id:'Bagikan tautan di bawah ini sesuai dengan kelompok peserta'},
                        itemType:'generate_link',
                        items:[]
                    },
                ],
                pageNumber:2,
                title:{en:'Question 2 of 2',id:'Pertanyaan 2 dari 2'}
            })
            let data={
                detail_survey:detail,list_jawaban:jawaban
            }
            dispatch(setDetailSurvey(data))
            dispatch(setActiveSurvey(4))
            dispatch(setActiveQuestion(0))
            props.tabToggle('create_link','audit')
        }
        
    },[])
    const image=[
        {image:BannerImg},
    ]
    const detailSurvey=async (id)=>{
        if(id===4){
            let detail=[]
            let jawaban=[]
            detail.push({
                active:true,
                id:1,
                intro:{en:'The following are some of the behaviors related to the superior competence of a human resource (HR) practitioner. In preparation for the self-development of HRBP training, filling out this short survey aims to identify areas of competence that will be the focus of training so that your attendance at the training will be more effective.',id:'Berikut adalah beberapa perilaku yang terkait dengan kompetensi atasan seorang praktisi sumber daya manusia (SDM). Dalam persiapan pengembangan diri diklat HRBP, pengisian survey singkat ini bertujuan untuk mengidentifikasi bidang-bidang kompetensi yang akan menjadi fokus pelatihan agar absensi anda pada pelatihan lebih efektif.'},
                itemType:'',
                items:[],
                pageNumber:1,
                options:[],
                title:{en:'Introduction',id:'Pengantar'}
            })
            detail.push({
                active:false,
                id:2,
                intro:{en:'',id:''},
                itemType:'generate_link',
                items:[
                    {
                        id:2,
                        options:[],
                        text:{en:"Creation of participant",id:'Pembuatan partisipasi'}
                    }
                ],
                options:[
                    {
                        id:11,
                        intro:{en:'You can create one or more participation groups according to the needs of your organization',id:'You can create one or more participation groups according to the needs of your organization'},
                        itemType:'generate_link',
                        items:[
                            {
                                id:111,
                                field:[{text:{en:'Group 1',id:'Group 1'},type:'textfield'},{text:{en:'Expired date',id:'Tanggal expired'},type:'date'}]
                            }
                        ]
                    },
                    {
                        id:12,
                        intro:{en:'We need your contact information, so we can contact you about the survey results . Please fill out the form below for generate link.',id:'We need your contact information, so we can contact you about the survey results . Please fill out the form below for generate link.'},
                        itemType:'varied',
                        items:[
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Name',id:'Nama'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Email',id:'Email'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Handphone No.',id:'No Hp.'},
                                required:true
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Job title',id:'Pekerjaan'},
                                required:false
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Department',id:'Department'},
                                required:false
                            },
                            {
                                id:121,
                                itemType:'textfield',
                                title:{en:'',id:''},
                                text:{en:'Company name',id:'Nama perusahaan'},
                                required:true
                            },
                        ]
                    },
                    {
                        id:13,
                        intro:{en:'Share the link below according to the group of participants',id:'Bagikan tautan di bawah ini sesuai dengan kelompok peserta'},
                        itemType:'generate_link',
                        items:[]
                    },
                ],
                pageNumber:2,
                title:{en:'Question 2 of 2',id:'Pertanyaan 2 dari 2'}
            })
            let data={
                detail_survey:detail,list_jawaban:jawaban
            }
            dispatch(setDetailSurvey(data))
            dispatch(setActiveSurvey(id))
            dispatch(setActiveQuestion(0))
            props.tabToggle('create_link','audit')

        }else{
            let res=await dispatch(getDetailSurvey(id))
            if(res){
                if(res.length>0){
                    dispatch(setActiveSurvey(id))
                    dispatch(setActiveQuestion(0))
                    props.tabToggle('question','audit')
                }
               
    
            }
        }
        // let res=await dispatch(getDetailSurvey(id))
        // if(res){
        //     if(res.data.length>0){
        //         dispatch(setActiveSurvey(id))
        //         dispatch(setActiveQuestion(0))
        //         props.tabToggle('question','audit')
        //     }
           

        // }
    }
    const renderLoading=()=>{
        return(
            <div className='audit-wrapper-mobile'>
                <Skeleton height={150}/>
                <br/><br/>
                <Skeleton height={150}/>
                <br/><br/>
                <Skeleton height={150}/>
                <br/>
            </div>
        )
    }
    let color_card=['audit-color-card1','audit-color-card2','audit-color-card3','audit-color-card1','audit-color-card2','audit-color-card3']

    return (
        <div>
            {/* <Navbar/>
            <br/><br/><br/><br/><br/>
            <BannerWithoutBtn image={image} title="We enhance your Execution Capability" subtitle="We help you transform you organization’s execution capability through Strategy, Process, Structure, People, and Culture according to your company’s needs"/>
            {general.isLoading?renderLoading()
            :
            <div className='audit-wrapper-mobile'>
                {list_survey.map((data,i)=>(
                    <div key={i}>
                        <div className={`audit-card-mobile ${color_card[i]} flex-column`} onClick={()=>detailSurvey(data.id)}>
                            <h3>{data.title}</h3>
                            <p>{data.intro}</p>
                        </div>
                        <br/>
                    </div>
                ))}
            </div>
            }
            <br/><br/>
            <Footer/> */}
        </div>
    )
}
