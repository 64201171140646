import React,{useState,useEffect} from 'react'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import Kurang from '../../../assets/image/Kurang.jpg'
import Cukup from '../../../assets/image/Cukup.jpg'
import Baik from '../../../assets/image/Baik.jpg'
import SangatBaik from '../../../assets/image/SangatBaik.jpg'
import Logo from '../../../assets/image/logo.png'
const Result=({result})=>{
    const renderImg=(id)=>{
        switch (id) {
            case 1:
                return Kurang
            case 2:
                return Cukup
            case 3:
                return Baik
            case 4:
                return SangatBaik
            case 5:
                return SangatBaik
            default:
                break;
        }
    }
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div>
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div className='result-wrapp' style={{width:'100%',textAlign:'center'}}>
                            <div className='field-kolom'>
                            <br/>
                            <img src={renderImg(result!==null&&result.id)} style={{width:200}}/>
                            <br/>
                                <h2>{result!==null&&result.text.id}</h2>
                            <br/>
                            <p><b>Terimasih sudah berpartisipasi dalam survey Digital Transformation Readiness Assessment . Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.</b></p>
                            <br/>
                            <div className='div-flex div-align-center div-justify-center'>
                                <p><b>Provided by:</b></p>
                                &nbsp;&nbsp;
                                <img src={Logo} onClick={()=>window.location.assign('https://www.gmlperformance.com/')} style={{width:90,cursor:'pointer'}}/>
                                <br/>
                            </div>
                            <br/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default Result