import React,{useState,useEffect} from 'react'
import Modal from '../../components/modal'
export default function Report_mobile() {
    const [modal,setModal]=useState(false)
    useEffect(()=>{
        setModal(true)
    },[])
    const renderModal=()=>{
        return(
            <div>
                <p>Report only available on desktop device (Laptop or PC)</p>
                
            </div>
        )
    }
    return (
        <div>
            <Modal without_x={true} title="Report" isOpen={modal} children={renderModal()} modalToggle={()=>null}/>
        </div>
    )
}
