import React,{useEffect,useState} from 'react'
import './style.css'
import Logo1 from '../../assets/image/logo_report_1.png'
import Logo2 from '../../assets/image/logo_report_2.png'
import Map from '../../assets/image/map.svg'
import report_1 from '../../assets/image/report/report_1.jpg'
import report_2 from '../../assets/image/report/report_2.jpg'
import report_3 from '../../assets/image/report/report_3.jpg'
import report_4 from '../../assets/image/report/report_4.jpg'
import report_5 from '../../assets/image/report/report_5.jpg'
import report_6 from '../../assets/image/report/report_6.jpg'
import report_7 from '../../assets/image/report/report_7.jpg'
import report_8 from '../../assets/image/report/report_8.jpg'
import report_9 from '../../assets/image/report/report_9.jpg'
import report_10 from '../../assets/image/report/report_10.jpg'
import report_11 from '../../assets/image/report/report_11.jpg'
import report_12 from '../../assets/image/report/report_12.jpg'
import report_13 from '../../assets/image/report/report_13.jpg'
import report_14 from '../../assets/image/report/report_14.jpg'
import report_15 from '../../assets/image/report/report_15.jpg'
import report_16 from '../../assets/image/report/report_16.jpg'
import report_od from '../../assets/image/report/report_od.png'
import Kurang from '../../assets/image/Kurang.jpg'
import Cukup from '../../assets/image/Cukup.jpg'
import Baik from '../../assets/image/Baik.jpg'
import SangatBaik from '../../assets/image/SangatBaik.jpg'
import Check from '../../assets/image/Check.svg'
import Close from '../../assets/image/Close_red.svg'
import Loader from '../../components/Loading'

import ChevronGrey from '../../assets/image/chevron_grey.svg'
import {getDataReport} from '../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import {Helmet} from "react-helmet";
import {Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import more from 'highcharts/highcharts-more';
more(Highcharts);

function Report(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let init=[false,false,false,false,false]
    const [loaders,setLoaded]=useState(init)
    const {report}=audit

    useEffect(()=>{
        let {id,uuid}=props.match.params
        // console.log('id',id,uuid)
        if(report===null){
            dispatch(getDataReport(`/${id}/${uuid}`))
        }
        

        if(report!==null){
           
            setTimeout(()=>{
                let findload=loaders.filter((data)=>{
                    return data===false
                })
                // console.log('findload', findload,loaders)
                if(findload.length<1){
                    
                    window.print()

                }
    
            },3000)
        }
    },[report])
    // let findload=loader.includes(false)

    // console.log('loader', loaders)
    let image1=[
        report_1,
        report_2,
        report_3,
        report_4,
    ]
    let image2=[
        report_5,
        report_6,
        report_7,
        report_8,
    ]
    let image3=[
        report_9,
        report_10,
        report_11,
        report_12,
    ]
    let image4=[
        report_1,
        report_13,
        report_14,
        report_15,
    ]
    let image5=[
        report_1,
        report_14,
        report_16,
        report_15,
    ]
    const renderImage=()=>{
        let {id,uuid}=props.match.params
        switch (parseInt(id)) {
            case 3:
                return image2
            case 2:
                return image3
            case 1:
                return image1
            case 4:
                return image4
            case 5:
                return image5
            case 6:
                return image5
            // case 10:
            //     return image4
            default:
                return image1
                break;
        }
    }
    let oo=[]
    const setonLoaded=(index)=>{
        // let findload=loader.filter((data)=>{
        //     return data===false
        // })
        // let a=loaders.includes(false)
        // if(a){
            // let a=loaders.indexOf(index)
            let b=[false,true]
            
            
            let c=init.filter((data)=>{
                return data===false
            })
            console.log('index', index,oo.length)
            if(oo.length<4){

                oo.push(index)
            }else{
                oo=[]
                setTimeout(()=>{
                    window.print()
                },3000)
            }
            // console.log('a', init.includes(false))

            // setLoaded({...loaders,[index]:true})

        // }else{
        //     window.print()
        // }
        // let a=[false,false,false]
        // console.log('findload',typeof(loaders),typeof(a) )

    }
    const renderImageBsc=(score)=>{
        if(score>66){
            return Baik
        }else if(score>45){
            return Cukup
        }else{
            return Kurang
        }
    }
    let {id,uuid}=props.match.params
    let hrbp_list=[{text:'Priority for Development',color:'#ed4900'},{text:'Maintain & Strengthen',color:'#fcbc44'},{text:'Maintain at Little Cost',color:'#00ed64'},{text:'Least Priority',color:'#00a1ed'}]
    // console.log('report', report)

    return (
        <>
        <Loader/>
        {report!==null&&
        <div className='report-wrapper' >
            <Helmet>
                <title>{report.title}</title>
                
            </Helmet>
            <div className='report-header'>
                <div className='report-shape'></div>
                <div className='div-margin'>
                    <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:160}}/>
                </div>
            </div>
            <div className='report-footer'>
                <div className='div-margin'>
                    <p>Report date :  {moment(report.surveyDate).format('DD MMM YYYY')}</p>
                </div>
            </div>
            <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div class="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div class="content">
                        <div className='div-flex div-margin'>
                            {renderImage().map((data,index)=>(
                                <div style={{width:140,height:140}}>
                                <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>{parseInt(id)===4?"HR Business Partner Competency Assessment":report.title}</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <br/>
                        <div className='report-information div-margin'>
                            <p className='text-bold'>Respondent Information</p>
                            {report.cover.map((data,i)=>(
                                <div className='div-flex' key={i}>
                                    <p style={{width:250}}>{data.indicator}</p>
                                    <p>:&nbsp;{data.text}</p>
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>

                        <div className='pagebreak'></div>

                        <br/><br/>
                        {parseInt(id)===1&&
                        <>
                            <div className='report-shape3'>
                                <h1>Result of {report.title}</h1>
                            </div>
                            <br/>
                            <div className='div-margin strategic-text'>
                                <p>{report.description}</p>
                            </div>
                            <br/>
                            <div style={{width:'70%',marginLeft:50}}>
                            <ReactApexChart options={report.chart?.options} series={report.chart?.series} type="bar" height={380} />
                            </div>
                        </>
                        }
                        {parseInt(id)===2&&
                            <>
                            {report.dimensions.map((data,i)=>{
                            if(data.title!=="Primary"){
                                return(
                                    <div key={i}>
                                        <div className='report-shape3'>
                                        <h1>{data.title}</h1>
                                        </div>
                                        <br/>
                                        <div className='div-margin strategic-text'>
                                            <p>{data.description}</p>
                                        </div>
                                        <br/>
                                        <div className='div-table' style={{marginLeft:130,width:400}}>
                                            <div className='div-flex border-bottom-table' style={{padding:10}}>
                                                <p className='text-bold ' style={{width:220}}>Indicators</p>
                                                <p className='text-bold '>Glance Result</p>
                                            </div>
                                            {data.indicators.map((indicator,i)=>(
                                                <div className='div-flex border-bottom-table' style={{padding:10}} key={i}>
                                                    <p className=' ' style={{width:220}}>{indicator.indicator}</p>
                                                    <p className={`text-bold ${indicator.value<1?'color-red':'color-blue'}`} style={{width:120}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{indicator.value<1?'Poor':'Good'}</p>
                                                    <p><span><img src={indicator.value<1?Close:Check} style={{width:20}}/></span></p>
                                                </div>
                                            ))}
                                        </div>
                                        <br/>
                                    </div>
                                )
                            }else{
                                return(
                                    <div key={i}>
                                        <div className='report-shape3'>
                                            <h1>{data.title}</h1>
                                        </div>
                                        <br/>
                                        <div className='div-margin strategic-text'>
                                            <p>{data.description}</p>
                                        </div>
                                        <br/>
                                        {data.dimensions.map((dimension,i)=>(
                                            <div key={i}>
                                                <div className='div-margin div-table'>
                                                    <p style={{fontSize:18}} className='text-bold'>{dimension.title}</p>
                                                    <div className='div-flex'>
                                                        <div style={{width:80,textAlign:'center',paddingTop:10}}>
                                                            <p><span><img src={ChevronGrey}/></span></p>
                                                        </div>
                                                        <div className='border-bottom-table' style={{width:210,padding:10}}>
                                                            <p className='text-bold ' >Indicators</p>
                                                        </div>
                                                        <div className='border-bottom-table' style={{padding:10}}>
                                                            <p className='text-bold '>Glance Result</p>

                                                        </div>
                                                    </div>
                                                    {dimension.indicators.map((indicator,i)=>(
                                                        <div className='div-flex' key={i}>
                                                            <div style={{width:80,textAlign:'center',paddingTop:10}}>
                                                                <p><span><img src={ChevronGrey}/></span></p>
                                                            </div>
                                                            <div className='border-bottom-table' style={{width:210,padding:10}}>
                                                                <p className='' >{indicator.indicator}</p>
                                                            </div>
                                                            <div className='border-bottom-table' style={{padding:10,width:110}}>

                                                                <p className={`text-bold ${indicator.value<1?'color-red':'color-blue'}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{indicator.value<1?'Poor':'Good'}</p>

                                                            </div>
                                                            <div className='border-bottom-table' style={{padding:10,}}>
                                                                <p><span><img src={indicator.value<1?Close:Check} style={{width:20}}/></span></p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                                <br/>
                                            </div>
                                        ))}
                                        
                                    </div>
                                )
                            }
                            })}
                            <div className='pagebreak'></div>
                            <div className='report-shape3'>
                                <h1>Your Mini HR Maturity Result :</h1>
                            </div>
                            <br/>
                            <div className='div-flex '>
                                <div className='div-flex div-justify-center' style={{width:310,marginTop:90}}>
                                    
                                <ReactApexChart options={report.chart?.options} series={report.chart?.series} type="bar" width={300} />
                                </div>
                                <div className='mini-hr-desc'>
                                <div className='mini-hr-result div-flex div-justify-center div-align-center'>
                                    <p>{report.summary.title}</p>
                                </div>
                                <br/>

                                    <p>{report.summary.description}</p>
                                </div>
                            </div>
                            </>
                        }
                        {parseInt(id)===3&&
                            <>
                                <div className='report-shape3'>
                                    <h1>Result of {report.title}</h1>
                                </div>
                                <br/>
                                <div className='div-margin strategic-text'>
                                    <p>{report.description}</p>
                                </div>
                                <br/>
                                <div style={{width:'70%',marginLeft:50}}>
                                <ReactApexChart options={report.chart?.options} series={report.chart?.series} type="bar" height={380} />
                                </div>
                            </>
                        }
                        {parseInt(id)===4&&
                         <>
                            <div className='report-shape3'>
                                <h1>Result of {report.title}</h1>
                            </div>
                            <br/>
                            <div className='div-margin strategic-text'>
                                <p>{report.description}</p>
                            </div>
                            <br/>
                            {report.data.map((data,i)=>(
                                <div key={i}>
                                <div className='report-shape3'>
                                        <h1>{data.table.title}</h1>
                                    </div>
                                    <br/>
                                    <div className='div-margin'>
                                    <Table  size="small" aria-label="a dense table" style={{color:'#777777'}}>
                                        <TableHead>
                                        <TableRow >
                                            <TableCell  className='card-content-item-jurnal-text-without-border'></TableCell>
                                            {data.table.header.map((head,i)=>(
                                                <TableCell key={i} align="right" className='card-content-item-jurnal-text-without-border' style={{color:head==='Mid-Point'?'#f28416':'#777777'}}>{data.table.title==="Value Data"?head==="Mid-Point"?head+" Value":"Value "+head:head==='Mid-Point'?head+" Ranking":"Ranking "+head}</TableCell>
                                                
                                            ))}
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.table.rows.map((row,i)=>(
                                                <TableRow key={i}>
                                                    <TableCell className='card-content-item-jurnal-text' style={{color:'#777777'}}>{row.title}</TableCell>
                                                    {row.data.map((data_row,i)=>(
                                                        <TableCell key={i} align="right" className='card-content-item-jurnal-text' style={{color:row.data.length-1===i?'#f28416':'#777777'}}>{data_row.toFixed(2)}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                            
                                            
                                            
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <div style={{width:'80%'}}>
                                    <ReactApexChart className='chart-report' options={data.chart?.options} series={data.chart?.series} type="bar" height={350} />

                                    </div>
                                    </div>
                                    <br/>
                                </div>
                            ))}
                            <div className='pagebreak'></div>
                            <div className='report-shape3'>
                                <h1>Value VS Ranking</h1>
                            </div>
                            <br/>
                            <div className='div-margin strategic-text'>
                                <p>Hasil analisis berikut merupakan hasil penilaian yang telah Anda dan/atau rekan Anda lakukan. Riset menunjukkan ada 9 kompetensi praktisi pengelola sumber daya manusia (SDM) yang perlu dimiliki untuk menjadi HR Business Partner bagi manajemen lini. Penempatan ke-sembilan kompetensi ini ke dalam 4 kuadran mencerminkan prioritas atau fokus perbaikan yang bisa Anda lakukan segera untuk menjadi praktisi SDM yang berkontribusi bagi pengembangan perusahaan.</p>
                            </div>
                            <br/>
                            <div className='div-margin'>

                                {report&&<div style={{width:700,zIndex:2000,textAlign:'center'}}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={report.quadrant.options}
                                        style={{zIndex:2000}}
                                    />
                                </div>}
                            </div>
                            <br/>
                            <div className='div-margin hrbp-grid'>
                                <div>
                                    
                                    {report.quadrant.quadrant.map((quadrant,i)=>(
                                        <div key={i}>
                                        {/* {i===report.quadrant.quadrant.length-1&&<><br/><br/><br/></>}   */}

                                        {/* {i===report.quadrant.quadrant.length-1&&<div className='pagebreak'></div>}   */}
                                            
                                        <div className='hrbp-header div-flex' style={{marginTop:15}}>
                                            <div style={{width:16,height:16,backgroundColor:hrbp_list[i].color,borderRadius:'100%'}}></div>
                                            &nbsp;&nbsp;&nbsp;
                                            <p className='bold' style={{fontWeight:'bold'}}>{quadrant.name}</p>
                                            &nbsp;&nbsp;&nbsp;
                                            <div style={{width:2,height:20, backgroundColor:'grey'}}></div>
                                            &nbsp;&nbsp;&nbsp;
                                            <p className='bold' style={{fontWeight:'bold'}}>{hrbp_list[i].text}</p>
                                        </div>
                                        <div style={{paddingLeft:25}}>
                                            {quadrant.children.map((children,i)=>(
                                            <p style={{fontWeight:600,fontSize:14,margin:'5px 0px 5px 0px'}} key={i}>{children}</p>
                                            ))}
                                            
                                        </div>
                                        {i===1&&<div className='pagebreak'></div>}
                                        {i===1&&<br/>}

                                        </div>
                                    ))}
                                    
                                </div>
                                <div>
                                    <p><b style={{fontSize:16}}>Quadrant 1</b> ini berisi kompetensi yang dinilai rendah padahal memiliki dampak besar terhadap keberhasilan organisasi. Selayaknya Anda segera mengidentifikasi peluang dan tindaklanjut untuk melakukan perbaikan.</p><br/>
                                    <p><b style={{fontSize:16}}>Quadrant 2</b> ini berisi kompetensi yang dinilai dilakukan dengan baik dan memiliki dampak besar terhadap keberhasilan organisasi. Penting untuk mempertahankan dan terus menguatkan kompetensi dalam kuadran ini.</p><br/>
                                    <div className='pagebreak'></div>
                                    <br/>
                                    <p><b style={{fontSize:16}}>Quadrant 3</b> berisi kompetensi yang dinilai tinggi meskipun memiliki dampak yang relatif kecil terhadap keberhasilan organisasi. Kompetensi ini sebaiknya dipertahankan, namun tidak menjadi fokus investasi waktu dan biaya untuk pengembangan.</p><br/>
                                    <p><b style={{fontSize:16}}>Quadrant 4</b> berisi kompetensi yang dinilai rendah namun juga memiliki dampak kecil terhadap keberhasilan organisasi. Kompetensi dalam kuadran ini bukan merupakan prioritas untuk pengembangan.</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </>
                        }
                        
                        {parseInt(id)===5&&
                        <>
                            <div className='div-margin' style={{textAlign:'center'}}>
                                <img src={report_od} style={{width:'60%'}}/>
                                <h1 style={{fontFamily:'Lato, sans-serif',color:'#464646'}}> Organization Diagnostic Framework</h1>
                            </div>
                            <br/>
                            <div className='div-margin'>
                                <p>{report.description}</p>
                            </div>
                            <br/>
                            {report.dimensions.map((data,i)=>(
                                <>
                                <div className='report-shape3'>
                                    <h1>{data.title}</h1>
                                </div>
                                <br/>
                                <div className='div-margin strategic-text'>
                                    <p>{data.description}</p>
                                </div>
                                <br/>
                                <div style={{width:'80%'}}>
                                <ReactApexChart options={data.chart?.options} series={data.chart?.series} type="bar" height={380} />
                                </div>
                                <div className='pagebreak'></div>
                            </>
                            ))}
                            <br/>
                        </>
                        }
                        {parseInt(id)===6&&
                        <>
                            <div className='div-margin' style={{textAlign:'center'}}>
                                <img src={report_od} style={{width:'60%'}}/>
                                <h1 style={{fontFamily:'Lato, sans-serif',color:'#464646'}}> Organization Diagnostic Framework</h1>
                            </div>
                            <br/>
                            <div className='div-margin'>
                                <p>{report.description}</p>
                            </div>
                            <br/>
                            {report.dimensions.map((data,i)=>(
                                <>
                                <div className='report-shape3'>
                                    <h1>{data.title}</h1>
                                </div>
                                <br/>
                                <div className='div-margin strategic-text'>
                                    <p>{data.description}</p>
                                </div>
                                <br/>
                                <div style={{width:'80%'}}>
                                <ReactApexChart options={data.chart?.options} series={data.chart?.series} type="bar" height={380} />
                                </div>
                                <div className='pagebreak'></div>
                            </>
                            ))}
                            <br/>
                        </>
                        }
                        {parseInt(id)===10&&
                        <>
                            <div className='report-shape3'>
                                <h1>Result of {report.title}</h1>
                            </div>
                            <br/>
                            <div style={{textAlign:'center'}}>
                                <img style={{width:'30%'}} src={renderImageBsc(report.summary.score)}/>
                                <br/><br/><br/>
                                <h1 style={{fontSize:50,fontFamily:'Lato'}}>Nilai Anda : {report.summary.score}</h1>
                            </div>
                            <br/><br/><br/>
                            <div className='div-margin '>
                                <p  style={{color:'#3aaf06'}}>{report.summary.description}</p>
                            </div>
                            <br/><br/><br/>
                            <div className='div-margin '>
                                <p>Untuk informasi lebih lanjut silakan hubungi kami di <span style={{color:'#00a1ed'}}>gml@gmlperformance.co.id</span> atau lewat pesan singkat Whatsapp di nomor <span style={{color:'#00a1ed'}}>0821-2325-3700</span></p>
                            </div>
                        </>}
                        {parseInt(id)===11&&
                        <>
                            <div className='report-shape3'>
                                <h1>Result of {report.title}</h1>
                            </div>
                            <br/>
                            <div style={{textAlign:'center'}}>
                                <img style={{width:'30%'}} src={renderImageBsc(report.summary.score)}/>
                                <br/><br/><br/>
                                <h1 style={{fontSize:50,fontFamily:'Lato'}}>Nilai Anda : {report.summary.score}</h1>
                            </div>
                            <br/><br/><br/>
                            <div className='div-margin '>
                                <p  style={{color:'#3aaf06'}}>{report.summary.description}</p>
                            </div>
                            <br/><br/><br/>
                            <div className='div-margin '>
                                <p>Untuk informasi lebih lanjut silakan hubungi kami di <span style={{color:'#00a1ed'}}>gml@gmlperformance.co.id</span> atau lewat pesan singkat Whatsapp di nomor <span style={{color:'#00a1ed'}}>0821-2325-3700</span></p>
                            </div>
                        </>}
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div class="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
        </div>
        }
        </>
    )
}
export default withRouter(Report)