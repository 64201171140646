// import Navbar from '../../components/navbar'
// import Footer from '../../components/footer'
import React from 'react'
import Koala from '../../assets/image/Koala.png'
export default function Expired() {
    return (
        <div>
            {/* <Navbar/> */}
                <br/><br/><br/><br/><br/>
                <div className='expired-wrapper'>
                    <img src={Koala}/>
                    <h1 style={{color:'#252525'}}>The questionnaire you are referring to
                    has exceeded the specified time limit</h1>
                    <h1 onClick={()=>window.location.assign('/organitationassessment')} style={{color:'#00a1ed',cursor:'pointer'}}>Go to Organitational Assessment</h1>
                </div>
                <br/><br/><br/>
            {/* <Footer/> */}
        </div>
    )
}
