import * as actionTypes from "../constant/general";
const initialState={
    
    isLoading:false,
    isLoading2:false,
    tab_active:'audit',
    tab_back:'audit'
   
}
export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.SET_LOADING:
            return{
                ...state, 
                isLoading:action.payload 
            }
        case actionTypes.SET_LOADING2:
            return{
                ...state, 
                isLoading2:action.payload 
            }
        case actionTypes.SET_TAB:
            return{
                ...state, 
                tab_active:action.payload.tab_active,
                tab_back:action.payload.tab_back
            }
        
        default:
            return state;
    }
}

