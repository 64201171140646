import * as actionTypes from '../constant/insight'
import moment from 'moment'
const initialState={
    
    list_insight:[],
    topic:[],
}

export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.GET_INSIGHT:
            return{
                ...state,
                list_insight:action.payload
            }  
        case actionTypes.GET_TOPIC:
            return{
                ...state,
                topic:action.payload
            }  
        default:
            return state;
    }
}