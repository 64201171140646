import React,{useState} from 'react'
import {Button,RadioGroup,Checkbox  ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
const PetunjukPengisian=({detail_survey,next,back})=>{
    const [checked, setchecked] = useState(false)
    //test
    return(
        <div style={{width:'100%'}}>
            {detail_survey.tutorial==='part-1'?
                <div style={{padding:20}}>
                    <h3>{detail_survey.title}</h3>
                    <br/>
                    <p >
                        Pertanyaan pada survey ini terdiri dari 2 bagian.
                        <br/><br/>
                        Bagian pertama merupakan pernyataan-pernyataan dari survey Engagement yang menggambarkan persepsi Anda terhadap perusahaan. Bagian ini terdiri dari 33 pernyataan dengan 4 pilihan jawaban yaitu:<br/><br/>
                        1 = Sangat tidak setuju<br/>
                        2 = Tidak setuju<br/>
                        3 = Setuju<br/>
                        4 = Sangat setuju<br/><br/>
                        Bagian kedua merupakan pernyataan dari survey Organization Digital Readiness yang menggambarkan seberapa siap perusahaan Anda untuk mendukung keberhasilan transformasi digital. 
                        <br/><br/>
                        Silakan memilih salah satu jawaban yang paling sesuai menurut Anda.
                    </p>
                </div>
            :
            //test
            
            <div>
            <div style={{padding:'20px 20px 0px 20px'}}>
                <h3>{detail_survey.title}</h3>
                <br/>
                <p>Berikan nilai 1 sampai 4 sesuai karakteristik organisasi Anda memenuhi perilaku di bagian kiri (1) atau kanan (4). Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
                <br/>
                
            </div>
            <div className='kolom-isi hide-mobile'>
                <div className='square-1'>
                    <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                </div>
                <div className='square-middle '>
                <RadioGroup row aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                    </RadioGroup>
                </div>
                <div className='square-2'>
                    <p>Kami fokus pada perubahan perilaku digital pelanggan atau stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience membeli atau menggunakan solusi/produk kami</p>
                </div>
            </div>
            <div className='kolom-isi-mobile'>
                <div style={{width:'70%',display:'flex',justifyContent:'space-between',flexDirection:'column',height:'100%'}}>
                    <div className='square-1' style={{marginBottom:10}}>
                        <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                    </div>
                    
                    <div className='square-2'>
                        <p>Kami fokus pada perubahan perilaku digital pelanggan atau stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience membeli atau menggunakan solusi/produk kami</p>
                    </div>
                </div>
                <div className='square-middle '>
                    <RadioGroup column aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                    </RadioGroup>
                </div>
            </div>
            </div>}
            <br/>
            
            <div className='instruksi-edtra' style={{padding:20}} >
                <FormControlLabel
                    className="agreement"
                    control={<Checkbox color='primary' checked={checked} onChange={()=>setchecked(!checked)} name="checkedA" />}
                    label={<p style={{margin:0,fontWeight:'bold'}}>Saya telah membaca petunjuk dan saya mengerti</p>}
                />
                <Button disabled={!checked} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Ikuti Survey</Button>
            </div>
        </div>
        
    )
}
const Introduction=({detail_survey,next})=>{
    return(
        <div style={{padding:20}}>
            <h3>{detail_survey.title} </h3>
            <br/>
            <p style={{lineHeight:2}} dangerouslySetInnerHTML={{__html:detail_survey.intro}}></p>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={next} color='primary' variant='contained' size='large' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
            </div>
        </div>
    )
}
const Welcome=({detail_survey,next,back})=>{
    return(
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <h2>Selamat datang, </h2>
                        <p>Anda sedang menjalani proses <b>{detail_survey.title}</b></p>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                            {detail_survey.itemType==='intro'&&
                                <Introduction 
                                    detail_survey={detail_survey}
                                    next={next}
                                    back={back}
                                />
                            }
                            {detail_survey.itemType==='tutorial'&&
                                <PetunjukPengisian 
                                    detail_survey={detail_survey}
                                    next={next}
                                    back={back}
                                />
                            }
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default Welcome