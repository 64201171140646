import React,{useState,useEffect} from 'react'
import './style.css'

import {useDispatch,useSelector} from 'react-redux'
import {setTab} from '../../redux/action/general'
import {setActiveLang,getDetailSurvey,setActiveSurvey,setActiveQuestion,getListSurvey,checkSurvey} from '../../redux/action/audit'
import AuditMobile from './mobile/index'
import IntroMobile from './mobile/intro'
import Question from './mobile/question'
import ListQuestion from './mobile/list_question'
import ListQuestion2 from './mobile/list_question2'
import Result from './mobile/result'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import Loader from '../../components/Loading'
import CreateLink from './mobile/create_link'
import Expired from './expired'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            // main:'#FF7165',
            main:'#65b7ff',
            // contrastText: '#FFFFFF',
            contrastText: '#777777',
        }
    } 
})
export default function Index(props) {
    const [active_options,setActiveOptions]=useState(0)
    const [uuid,setUuid]=useState('')

    const general=useSelector(state=>state.general)
    const dispatch=useDispatch()
    const {tab_active}=general
    // console.log('tab_active', general)
    const tabToggle=(tab_active,tab_back)=>{
        dispatch(setTab(tab_active,tab_back))
    }
    useEffect(async ()=>{
        let {uuid}=props.match.params
        getDetailLangsung()
    },[])
    let {id}=props.match.params

    const getDetailLangsung=async ()=>{
        let {uuid}=props.match.params
        if(uuid!==undefined){
            setUuid(uuid)
            let check=await dispatch(checkSurvey(uuid))
            await  dispatch(getListSurvey())

            let res=await dispatch(getDetailSurvey(4))

            if(check){
                if(res){
                    if(res.length>0){
                        await dispatch(setActiveSurvey(4))
                        dispatch(setActiveQuestion(0))
                        tabToggle('question','audit')
                    }
                }
                
               
    
            }else{
                tabToggle('expired','audit')
            }
        }
    }

    const setLang=(data)=>{
        dispatch(setActiveLang(data))
    }
    return (
        <div>
            <Loader/>
            <MuiThemeProvider theme={themeButton}>
                {tab_active==='audit'&&<AuditMobile id={parseInt(id)} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='intro'&&<IntroMobile setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='question'&&<Question uuid={uuid} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='list_question'&&<ListQuestion setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='list_question2'&&<ListQuestion2 active_options={active_options} setActiveOptions={setActiveOptions} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='result'&&<Result setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='create_link'&&<CreateLink active_options={active_options} setActiveOptions={setActiveOptions} setLang={setLang} tabToggle={tabToggle}/>}
                {tab_active==='expired'&&<Expired active_options={active_options} setActiveOptions={setActiveOptions} setLang={setLang} tabToggle={tabToggle}/>}

            </MuiThemeProvider>
        </div>
    )
}
