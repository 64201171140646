import React,{useState,useEffect} from 'react'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import moment from 'moment'
import Close from '../../../assets/image/Close_red.svg'

import {TextField,Button,Tooltip ,Collapse,FormControlLabel ,Checkbox, FormControl } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Plus from '../../../assets/image/Plus.svg'
import MomentUtils from '@date-io/moment';
import {
    TimePicker,
    DatePicker ,
    MuiPickersUtilsProvider
  } from '@material-ui/pickers';
import { isMobile } from 'react-device-detect'
import { postLinkEdtra } from '../../../redux/action/audit'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 8,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const CreateLink=({detail_survey,next})=>{
    const classes=useStyles()
    const dispatch = useDispatch()
    const [state,setState]=useState({
        
        groups:[
            {name:'',expiredTime:moment()},
        ]
    })
    const addGroup=()=>{
        let dumm={name:'',expiredTime:moment()}
        let new_dum=[
            ...state.groups,
            dumm
        ]
        setState({...state,groups:new_dum})
    }
    const deleteGroup=(i)=>{
        state.groups.splice(i,1)
        setState({...state})
    }
    const onChange=(value,index)=>{
        state.groups[index].name=value
        setState({...state})

    }
    const onChangeDate=(date,index)=>{
        state.groups[index].expiredTime=date
        setState({...state})
    }
    const onClick=async ()=>{
        let new_groups=[]
        state.groups.map((d)=>{
            new_groups.push({text:d.name,time:moment(d.expiredTime).format('YYYY-MM-DD')})
        })
        let data={
            surveyId:detail_survey.id,
            groups:new_groups
        }
        let res= await dispatch(postLinkEdtra(data))
        if(res){
            window.location.assign('/edtra/link')
        }
    }
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <h2>Selamat datang, </h2>
                        <p>Anda sedang menjalani proses <b>{detail_survey.title}</b></p>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                            <div className='create-link-wrapper' style={{padding:20}} >
                            <h2 >Buat kelompok survey sesuai dengan kebutuhan organisasi anda.</h2>
                            {!isMobile&&state.groups.map((data,i)=>{
                                    return(
                                        <div className='div-flex div-justify-between div-align-center'>
                                            <div className='div-flex div-justify-between' style={{width:'90%'}}>
                                            <div style={{width:'55%'}}>
                                            <TextField
                                                label={`Kelompok ${i+1}`}
                                                autoFocus

                                                placeholder="Nama Kelompok"
                                                color='primary'
                                                variant='outlined'
                                                size='small'
                                                name='name'
                                                type='text'
                                                className={classes.textField}
                                                onChange={(e)=>onChange(e.target.value,i)}
                                                value={data.name}
                                            />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div style={{width:'55%'}}>
                                             <MuiPickersUtilsProvider utils={MomentUtils}>
                                             <DatePicker
                                                className={classes.textField}
                                                format="DD MMM YYYY"
                                                autoOk
                                                disablePast
                                                placeholder="DD MMM YYYY"
                                                label="Expired Date"
                                                value={state.groups[i].expiredTime}
                                                size='small' 
                                                inputVariant='outlined'
                                                onChange={(date)=>onChangeDate(date,i)}
                                            />
                                            </MuiPickersUtilsProvider>
                                            </div>
                                            </div>
                                            {i!==0&&
                                            <div>
                                                <img onClick={()=>deleteGroup(i)} src={Close} style={{width:25,cursor:'pointer'}}/>
                                            </div>
                                            }
                                        </div>
                                    )
                            })}
                            {isMobile&&state.groups.map((data,i)=>(
                                <div className='' key={i}>
                                    <div className='' style={{width:'100%'}}>
                                    <div className='div-flex div-justify-between'>
                                        <p style={{fontSize:14,color:'#777777',fontWeight:"bold",margin:'20px 0px 20px 0px'}} >Kelompok {i+1}</p>
                                        {i!==0&&<img onClick={()=>deleteGroup(i)} src={Close} style={{width:20,cursor:'pointer'}}/>}
                                    </div>
                                    <div style={{width:'100%'}}>
                                    <TextField
                                        label={`Kelompok ${i+1}`}
                                        color='primary'
                                        placeholder="Nama Kelompok"
                                        autoFocus
                                        variant='outlined'
                                        size='small'
                                        name='name'
                                        type='text'
                                        value={data.name}
                                        className={classes.textField}
                                        onChange={(e)=>onChange(e.target.value,i)}
                                    />
                                    </div>
                                    {/* &nbsp;&nbsp;&nbsp; */}
                                    <div style={{width:'100%'}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        className={classes.textField}
                                        format="DD MMM YYYY"
                                        autoOk
                                        disablePast
                                        label="Expired time"
                                        value={state.groups[i].expiredTime}
                                        size='small' 
                                        inputVariant='outlined'
                                        onChange={(date)=>onChangeDate(date,i)}
                                    />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                    </div>
                                </div>
                            ))}
                            <div onClick={addGroup} className='add-participant-dtra'>
                                <img src={Plus} style={{width:15}}/>
                                &nbsp;&nbsp;
                                <p>Tambah Kelompok Survey</p>
                            </div>
                            <br/>
                            <div className='create-link-footer' >
                                <p >Setelah membuat kelompok survey, Sistem akan generate link dari masing masing kelompok untuk anda bagikan sesuai dengan kelompok survey.</p>
                                <Button  onClick={onClick}  style={{width:120,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital btn-rounded'>Selanjutnya</Button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default CreateLink