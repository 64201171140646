import React,{useEffect,useState,useRef} from 'react'
import Logo1 from '../../../assets/image/logo.png'
import Map from '../../../assets/image/map.svg'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import report_1 from '../../../assets/image/report/report_1.jpg'
import report_13 from '../../../assets/image/report/report_13.jpg'
import report_17 from '../../../assets/image/report/report_17.jpg'
import report_18 from '../../../assets/image/report/report_18.jpg'
import './style.css'
import { getNewDtra,getReportEdtra } from '../../../redux/action/audit'
import { withRouter } from 'react-router'
import Loader from '../../../components/Loading'
import {Chart,Bar,Pie,Scatter} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
function ReportEdtra(props) {
     const dispatch = useDispatch()
    let {uuid}=props.match.params
    const [report, setreport] = useState(null)
    const [chart1Color,setChart1Color]=useState(['#ea5757','#5f8aeb','#26B600','#ebb262','#00ede4'])
    let renderRadius=(value)=>{
        if(value==0){
            return 10
        }
        if(value==='NaN'){
            return 0
        }
        if(value>70){
            return value/2
        }else{
            return value
        }
        
    }
    const chart1 = {
        labels: ['Kepuasan','Komitmen'],
        datasets: [
          {
            label: '',
            backgroundColor: ['#eb8585','#ebc085'],
            borderWidth: 0,
            data: [],
            barPercentage: 0.4,
          }
        ]
      }
    const chart2 = {
        labels: [['Kepuasan','terhadap Imbalan','dan Fasilitas'],['Kepuasan',' Terhadap Keamanan','dan Kenyamanan'],['Kepuasan terhadap','Kebersamaan'],['Kepuasan terhadap','Pengembangan Diri'],['Kepuasan terehadap','Aktualisasi'],['Kepuasan di','Masa Pandemi']],
        datasets: [
          {
            label: '',
            backgroundColor: '#eb8585',
            borderWidth: 0,
            data: [],
            barPercentage: 0.7,
          }
        ]
      }
    const chart3 = {
        labels: [['Commitment to','Organization'],['Commitment to','Task'],['Commitment to','Work Unit']],
        datasets: [
          {
            label: '',
            backgroundColor: '#ebc085',
            borderWidth: 0,
            data: [],
            barPercentage: 0.4,
          }
        ]
      }
    const chart4 = {
        labels: [['Kejelasan dan kesempatan','memperoleh pengembangan','diri dan pengembangan karir'],['Pemberian penghargaan','dan kompensasi yang','sesuai dengan posisi dan kinerja.'],['Kepercayaan dan kepedulian atasan serta','rekan kerja dalam menjalankan pekerjaan'],['Kesempatan ikut berkontribusi','(ide dan saran) baik dalam','bekerja maupun dalam','pengembangan perusahaan'],['Sosialisasi yang jelas','dan transparan terkait','transformasi dan perubahan','di perusahaan']],
        datasets: [
          {
            label: '',
            backgroundColor: '#85a4eb',
            borderWidth: 0,
            data: [],
            barPercentage: 0.6,
          }
        ]
      }
    const chart5 = {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#85a4eb',
            borderWidth: 0,
            data: [],
            barPercentage: 0.8,
          }
        ]
      }
    const chart6 = {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor:['#85a4eb','#a8eb85','#eb8585','#ebc085'],
            borderWidth: 0,
            data: [],
            // barPercentage: 0.8,
          }
        ]
      }
    const chart7 = {
        responsive:false,
        borderColor:'red',
        datasets: [{
            label: 'Scatter Dataset',
            showLine: false,
            // pointBorderWidth:15,
            pointRadius: [],
            pointBorderColor:chart1Color,
            pointBackgroundColor:chart1Color,
            data: [],
        }]
      }
      const chart8 = {
        labels: [['Digital Strategy','Differentiated']],
        datasets: [
            {
                label: '',
                backgroundColor: [],
                borderWidth: 0,
                data: [],
                barPercentage: 0.8,
              }
        ]
      }
      const chart9 = {
        labels: [['Execution-biased','Systems'], ['Empowered','Structure'],['Entrepreneurial','People'],['Adhocracy','Culture'],['Ambidextrous','Leadership']],
        datasets: [
          {
            label: '',
            backgroundColor: [],
            borderWidth: 0,
            data: [],
            barPercentage: 1.1,
          }
        ]
      }
      let new_datas2=[]
      const chart10= {
        labels: [['Formulasi','Strategi'], ['Pemetaan',' Strategi','Level','Organisasi'], ['Penyelarasan ','Organisasi'], ['Eksekusi ','Operasional'],['Pemantauan dan','Penyelarasan','Kembali']],
        datasets: []
      }
    useEffect(() => {
        if(report!==null){
            chart1.datasets[0].data.push(report.charts[0].items[0].values[0].toFixed(2))
            chart1.datasets[0].data.push(report.charts[2].items[0].values[0].toFixed(2))
            
            report.charts[1].items.map((data)=>{
                if(data.values.length>0&&data.values[0]!=='NaN'){
                    chart2.datasets[0].data.push(data.values[0].toFixed(2))

                }
            })

            report.charts[3].items.map((data)=>{
                if(data.values.length>0&&data.values[0]!=='NaN'){
                chart3.datasets[0].data.push(data.values[0].toFixed(2))
                    
                }
            })
            report.charts[4].items.map((data)=>{
                if(data.values.length>0&&data.values[0]!=='NaN'){
                chart4.datasets[0].data.push(data.values[0].toFixed(2))
                    
                }
            })

            report.charts[5].legends.map((data)=>{
                chart5.labels.push(data)
            })
            report.charts[5].items.map((data)=>{
                if(data.values.length>0&&data.values[0]!=='NaN'){
                chart5.datasets[0].data.push(data.values[0].toFixed(2))
                    
                }
            })
            report.charts[6].legends.map((data)=>{
                chart6.labels.push(data)
            })
            report.charts[6].items.map((data)=>{
                if(data.values.length>0&&data.values[0]!=='NaN'){
                chart6.datasets[0].data.push(data.values[0].toFixed(2))
                    
                }
            })

            chart7.datasets[0].pointRadius=[renderRadius(report.digital.chart1.quadrant4),renderRadius(report.digital.chart1.quadrant1),renderRadius(report.digital.chart1.quadrant2),renderRadius(report.digital.chart1.quadrant3)]
            chart7.datasets[0].data= [
                {x:-50,y:50,value:report.digital.chart1.quadrant4},
                {x:50,y:50,value:report.digital.chart1.quadrant1},
                {x:50,y:-50,value:report.digital.chart1.quadrant2},
                {x:-50,y:-50,value:report.digital.chart1.quadrant3},
            ] 

            report.digital.chart3[0].values.map((data)=>{
                if(data!==0){
                    chart8.datasets[0].data.push(data)
                    chart8.datasets[0].backgroundColor.push(data>=2.5?'#26B600':'#FF4560')
                }
                // chart8.datasets.push({
                //     data: [data],
                //     backgroundColor: [data>0?'#26B600':'#FF4560' ], 
                // })
            })
            report.digital.chart4.map((data)=>{
                data.values.map((d)=>{
                    if(d!==0){
                        chart9.datasets[0].data.push(d)
                        chart9.datasets[0].backgroundColor.push(d>=2.5?'#26B600':'#FF4560')

                    }
                })
            })
            report.digital.chart2.legends.map((dat,i)=>{
                if(report.digital.chart2.legends.length-1==i){
                    //for last
                    let new_dat=[]
                    report.digital.chart2.items.map((item,i2)=>{
                        new_dat.push(item.values[i])
                    })
                    new_datas2.push({
                        label: dat,
                        data: new_dat,
                        fill:false,
                        type: 'line',
                        lineTension:0,
                        "borderColor":"#ed4900",
                        borderWidth:2,
                        pointBorderWidth:5,
                        pointBorderColor:'#ed4900',
                        pointBackgroundColor:'#ed4900',
                    })
                }else if(report.digital.chart2.legends.length-2==i){
                    //for before last
                    let new_dat=[]
                    report.digital.chart2.items.map((item,i2)=>{
                        new_dat.push(item.values[i])
                    })
                    new_datas2.push({
                        label: dat,
                        data: new_dat,
                        fill:false,
                        type: 'line',
                        lineTension:0,
                        "borderColor":"#c919ed",
                        borderWidth:2,
                        pointBorderWidth:5,
                        pointBorderColor:'#c919ed',
                        pointBackgroundColor:'#c919ed',
                        // pointStyle: 'line'
                    })
                }else{
                    let new_dat=[]
                    report.digital.chart2.items.map((item,i2)=>{
                        new_dat.push(item.values[i])
                    })
                    let renderColor=()=>{
                        if(dat==='Nilai rata-rata'){
                            return '#0089ed'
                        }else{
                            // let findColor=industry_list.filter((idn)=>{
                            //     return idn.text.id===dat
                            // })
                            return 'rgb(0, 137, 237)'
                        }
                        
                    }
                    new_datas2.push({
                        label: dat,
                        data: new_dat,
                        backgroundColor:renderColor(),
                        pointStyle: 'rect'
                    })
                }

            })
           
            let filter_line=new_datas2.filter((dat)=>{
                return dat.type==='line'
            })
            let filter_without_line=new_datas2.filter((dat)=>{
                return dat.type!=='line'
            })
            // reorderdata=[]
            // console.log(`filter_line,filter_without_line`, filter_line,filter_without_line)
            // chart10.datasets.push([...filter_line,...filter_without_line])
            filter_line.map((d)=>{
                chart10.datasets.push(d)
            })
            filter_without_line.map(d=>{
                chart10.datasets.push(d)
            })
        }else{
            getReport()
        }
    }, [report])
    // console.log(`chart8`, chart8)
    const getReport=async ()=>{
        let getId=await dispatch(getNewDtra())
        if(getId){
            let report=await dispatch(getReportEdtra(`/rev/${getId.data.id}/${uuid}`))
            setreport(report)
        }
    }
    const renderImage=()=>{
        return [report_1,report_13,report_17,report_18]
    }
    let oo=[]
    const setonLoaded=(index)=>{
        if(oo.length<4){
            oo.push(index)
        }else{
            oo=[]
            Chart.instances[0].resize(350, 350);
            // Chart.instances[1].resize(340, 340);
            // Chart.instances[2].resize(340, 340);
            // Chart.instances[3].resize(500, 500);
            // Chart.instances[4].resize(500, 500);
            // Chart.instances[5].resize(300, 300);
            // Chart.instances[8].resize(350, 350);

            // Chart.instances[6].resize(500, 500);

            // for (var id in Chart.instances) {
            //     console.log(`id`, id)
            // }
            setTimeout(()=>{
                
                window.print()
                
            },3000)
        }
    }
    const renderSymbol=(number)=>{
        if(number>=3){
            return 'di atas'
        }else{
            return 'di bawah'
        }
    }
    const renderExpresion=(number)=>{
        if(number>=3){
            return 'puas'
        }else{
            return 'kurang puas'
        }
    }
    const renderYakin=(number)=>{
        if(number>=3){
            return 'yakin'
        }else{
            return 'belum yakin'
        }
    }
    const renderIsTelah=(number)=>{
        if(number>=3){
            return 'telah'
        }else{
            return 'belum'
        }
    }
    const renderDiberikan=(number)=>{
        if(number>=3){
            return 'diberikan'
        }else{
            return 'belum diberikan'
        }
    }
    const renderBersedia=(number)=>{
        if(number>=3){
            return 'bersedia'
        }else{
            return 'tidak bersedia'
        }
    }
    const renderMenganggap=(number)=>{
        if(number>=3){
            return 'menganggap'
        }else{
            return 'tidak menganggap'
        }
    }
    const renderTercipta=(number)=>{
        if(number>=3){
            return 'tercipta'
        }else{
            return 'kurang tercipta'
        }
    }

    return (
        <div>
            <Loader/>
            {report!==null&&
            <div className='report-wrapper' >
                <div className='report-header'>
                    <div className='report-shape'></div>
                    <div className='div-margin'>
                        <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                    </div>
                </div>
                <div className='report-footer'>
                    <div className='div-margin'>
                        <p>Report date :  {moment(report.reportDate).format('DD MMM YYYY')}</p>
                    </div>
                </div>
                <table style={{width:'100%'}}>
                    <thead><tr><td>
                        <div className="header-space">&nbsp;</div>
                    </td></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className='content'>
                                    <div className='div-flex div-margin'>
                                        {renderImage().map((data,index)=>(
                                            <div style={{width:140,height:140}}>
                                            <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                            &nbsp;&nbsp;
                                            </div>
                                        ))}
                                    </div>
                                    <br/><br/>
                                    <div className='report-title div-flex div-align-center div-margin'>
                                        <h1 className='report-h1-title'>{report.surveyName}</h1>
                                        <h1 className='vertical-title'>Confidential</h1>
                                    </div>
                                    <br/>
                                    <div className='report-information div-margin'>
                                        <p className='text-bold'>Respondent Information</p>
                                            <div className='div-flex'>
                                                <p style={{width:250}}>Nama Perusahaan</p>
                                                <p>:&nbsp;&nbsp;{report.groupName}</p>
                                            </div>
                                            
                                    </div>
                                    <br/><br/>
                                    <div className='report-map'>
                                        <img src={Map}/>
                                    </div>
                                    
                                    <div className='pagebreak'></div>

                                    <br/><br/>
                                    <div className='report-shape3'>
                                        <h1>Pendahuluan</h1>
                                    </div>
                                    <br/>
                                    <div className='pemetaan div-margin stategic-text'>
                                        <p>
                                            <i>Employee Engagement</i> merupakan salah satu faktor yang sangat mempengaruhi keberlangsungan suatu perusahaan, terutama dari sisi produktivitas kinerja dan keberlangsungan perusahaan, mengingat sumber daya manusia adalah <i>intangible asset</i> yang sangat penting. Beberapa penelitian mengindikasikan hubungan yang positif antara <i>engagement</i> karyawan dan produktivitas perusahaan, semakin perusahaan dapat memberikan kepuasan terhadap karyawannya, maka karyawan akan cenderung semakin berkomitmen tinggi terhadap perusahaan dan pekerjaannya, sehingga kinerja perusahaan akan semakin baik lagi.
                                            <br/><br/>
                                            Hasil dari asesmen ini memberikan rangkuman tingkat <i>engagement</i> karyawan {report.groupName}. Hasil engagement akan dikategorikan dalam <i>engagement matrix</i> yang terbagi 4: <i>Actively Engaged, Potentially Engaged, Passively Engaged, dan Disengaged.</i> Hasil <i>engagement</i> juga tergambar dari tingkat Kepuasan dan Komitmen dari karyawan {report.groupName}.
                                            
                                        </p>
                                    </div>
                                    <br/>
                                    <div className='report-shape3'>
                                        <h1>Partisipan</h1>
                                    </div>
                                    <br/>
                                    <div className='div-margin'>
                                        <div style={{textAlign:'center'}}>
                                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 1. Profil Partisipan</p>
                                       
                                        </div>
                                        <p style={{fontSize: 14,fontWeight: 'bold'}}>N = {report.digital.total}</p>


                                        <br/>
                                        <div className='div-flex ' >
                                        <div style={{width:'50%'}}>
                                        <Bar
                                                height={230}
                                                data={chart5}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    maintainAspectRatio: true,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            },
                                                            formatter:(value)=>{
                                                                return `${value}%`
                                                            },
                                                            ticks:{
                                                                callback: function(value, index, values) {
                                                                    return value+'%';
                                                                }
                                                            }
                                                        },
                                                        y: {
                                                            grid: {
                                                                display:false
                                                            } , 
                                                            ticks:{
                                                                color:'#464646',
                                                                font:{
                                                                    // weight:'bold',
                                                                    size:10
                                                                },
                                                                align:'left'
                                                            }
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'right',
                                                            offset:6,
                                                            font:{
                                                                size:10
                                                            },
                                                        formatter: function(value) {
                                                            return `${value}%`
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />
                                        </div>
                                        <br/><br/>
                                        <div style={{width:'35%',margin:'auto',justifySelf:'center'}}>
                                            <Pie
                                                data={chart6}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    animation: {
                                                        duration: 0
                                                    },
                                                }}
                                            />
                                        </div>
                                         
                                        </div>
                                        <p style={{fontSize:10,fontWeight:'bold'}}>*N : merupakan jumlah responden yang mengikuti survey</p>

                                    </div>
                                    <br/><br/>
                                    <div className='pagebreak'></div>
                                    
                                    <div className='report-shape3'>
                                        <h1>Engagement Index</h1>
                                    </div>
                                    <br/>
                                    <div className='pemetaan div-margin strategic-text'>
                                        <p><i>Actively Engaged:</i> karyawan memiliki komitmen dan kepuasan yang tinggi
                                            <br/>
                                            <i>Potentially Engaged:</i> karyawan memiliki komitmen tinggi namun memiliki tingkat kepuasan yang rendah
                                            <br/>
                                            <i>Passively Engaged:</i> karyawan memiliki komitmen yang rendah namun memiliki tingkat kepuasan yang tinggi
                                            <br/>
                                            <i>Disengaged:</i> karyawan memiliki komitmen dan kepuasan yang rendah. 
                                        </p>
                                    </div>
                                    <br/>
                                    <div style={{textAlign:'center'}}>
                                        <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 2. Engagement Index</p>
                                        </div>
                                        <br/>
                                    <div className='div-margin'>
                                        <div className='div-flex div-justify-between div-align-center'>
                                            <div style={{textAlign:'center'}}>
                                                {/* <p style={{textAlign:'left',fontWeight:'bold',marginBottom:10}}>N = {report.total}</p> */}
                                                <div className='engagement-box'>
                                                    <h1>{report.engagementIndex1.toFixed(2)}</h1>
                                                    <h4>{report.engagementIndex2.toFixed(2)}</h4>
                                                </div>
                                                <h3 style={{fontFamily:'Lato',margin:'10px 0px',color:'#464646'}}>Engagement Index</h3>
                                            </div>
                                            <div >
                                                
                                                <div className='engagement-quadrant' style={{position:'relative'}}>
                                                <p style={{position:'absolute',fontWeight:'bold',top:'45%',left:'-26%'}}>Komitmen</p>
                                                <p style={{position:'absolute',fontWeight:'bold',left:'40%',bottom:'-15%'}}>Kepuasan</p>
                                                    <div className='quadrant-box'>
                                                        <div className='quadrant-item item-1'>
                                                            <p>{report.quadrants[3].indicator}<br/> {report.quadrants[3].value.toFixed(2)}%</p>
                                                        </div>
                                                        <div className='quadrant-item item-2'>
                                                            <p>{report.quadrants[0].indicator}<br/> {report.quadrants[0].value.toFixed(2)}%</p>
                                                        </div>
                                                        <div className='quadrant-item item-3'>
                                                            <p>{report.quadrants[2].indicator}<br/> {report.quadrants[2].value.toFixed(2)}%</p>
                                                        </div>
                                                        <div className='quadrant-item item-4'>
                                                            <p>{report.quadrants[1].indicator}<br/> {report.quadrants[1].value.toFixed(2)}%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/><br/><br/>
                                    </div>
                                    <br/><br/>
                                    {/* <div className='pagebreak'></div> */}
                                    <div className='report-shape3'>
                                        <h1>Kepuasan dan Komitmen</h1>
                                    </div>
                                    <br/>
                                    <div className='pemetaan div-margin strategic-text'>
                                        <p>Kepuasan dalam <i>engagement</i> menunjukkan tingkat kepuasan karyawan {report.groupName} dalam enam aspek utama, yaitu Kepuasan terhadap Imbalan dan Fasilitas, Kepuasan terhadap Keamanan dan Kenyamanan, Kepuasan terhadap Kebersamaan, Kepuasan terhadap Pengembangan Diri, Kepuasan terhadap Aktualisasi, dan Kepuasan di Masa Pandemi.</p>
                                        <br/>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>1.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[0].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap imbalan dan fasilitas menunjukkan angka {renderSymbol(report.charts[1].items[0].values[0])} nilai 3 yaitu di angka {report.charts[1].items[0].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[0].values[0])} terhadap imbalan dan fasilitas yang diberikan oleh perusahaan.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>2.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[1].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap keamanan dan kenyamanan tempat kerja menunjukkan angka  {renderSymbol(report.charts[1].items[1].values[0])} nilai 3 yaitu di angka {report.charts[1].items[1].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[1].values[0])} terhadap kondisi lingkungan tempat mereka bekerja.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>3.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[2].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap kebersamaan menunjukkan angka {renderSymbol(report.charts[1].items[2].values[0])} nilai 3 yaitu di angka {report.charts[1].items[2].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[2].values[0])} terhadap koordinasi internal dan antar departemen dan merasa atasan {renderIsTelah(report.charts[1].items[2].values[0])} memberikan kepedulian dan kepercayaan yang baik kepada karyawan secara pribadi.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>4.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[3].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap pengembangan diri menunjukkan angka {renderSymbol(report.charts[1].items[3].values[0])} nilai 3 yaitu di angka {report.charts[1].items[3].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[3].values[0])} terhadap pelatihan dan pengembangan yang diberikan perusahaan, {renderIsTelah(report.charts[1].items[3].values[0])} memperoleh kesempatan berbagi pengetahuan dan peningkatan karir yang jelas.
                                                
                                            </p>
                                        </div>
                                        <br/><br/><br/><br/><br/><br/>

                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>5.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[4].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap aktualisasi menunjukkan angka {renderSymbol(report.charts[1].items[4].values[0])} nilai 3 yaitu di angka {report.charts[1].items[4].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[4].values[0])} terhadap kesempatan yang diberikan untuk dapat menampilkan kinerja yang terbaik, {renderDiberikan(report.charts[1].items[4].values[0])} kesempatan memberikan ide dan melakukan perbaikan berkesinambungan.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>6.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[1].items[5].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat kepuasan terhadap masa pandemi menunjukkan angka {renderSymbol(report.charts[1].items[5].values[0])} nilai 3 yaitu di angka {report.charts[1].items[5].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderExpresion(report.charts[1].items[5].values[0])} terhadap inisiatif atau program yang diberikan oleh perusahaan selama masa pandemi.
                                                
                                            </p>
                                        </div>
                                        <br/>
                                        <p>Komitmen dalam <i>engagement</i> menunjukkan tingkat komitmen karyawan {report.groupName} dalam tiga aspek utama, yaitu Komitmen terhadap Organisasi, Komitmen terhadap Tugas, dan Komitmen terhadap Unit Kerja</p>
                                        <br/>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>1.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[3].items[0].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat komitmen terhadap organisasi menunjukkan angka {renderSymbol(report.charts[1].items[0].values[0])} nilai 3 yaitu di angka {report.charts[1].items[0].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} merasa {renderYakin(report.charts[1].items[0].values[0])} terhadap masa depannya diperusahaan serta {renderBersedia(report.charts[1].items[0].values[0])} secara suka rela untuk memberikan kontribusi positif terhadap visi/misi strategi perusahaan.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>2.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[3].items[1].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat komitmen terhadap tugas menunjukkan angka {renderSymbol(report.charts[1].items[1].values[0])} nilai 3 yaitu di angka {report.charts[1].items[1].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} dinilai {renderIsTelah(report.charts[1].items[1].values[0])} memahami proses bisnis, tujuan pekerjaan, kebutuhan pelanggan dan {renderBersedia(report.charts[1].items[1].values[0])} menggunakan pengetahuan/keterampilan untuk menyelesaikan pekerjaan.
                                                
                                            </p>
                                        </div>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 10}}>
                                            <p style={{margin:0}}>3.</p>
                                            &nbsp;
                                            <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}>
                                                <b>{report.charts[3].items[2].indicator}</b>
                                                <br/>
                                                Berdasarkan hasil survey, tingkat komitmen terhadap unit kerja menunjukkan angka {renderSymbol(report.charts[1].items[2].values[0])} nilai 3 yaitu di angka {report.charts[1].items[2].values[0].toFixed(2)}. Hal ini menunjukkan bahwa rata-rata karyawan {report.groupName} dinilai {renderIsTelah(report.charts[1].items[2].values[0])} bekerjasama dengan baik dalam menjalankan rencana kerja unit dan {renderMenganggap(report.charts[1].items[2].values[0])} target unit sebagai target kerja individu sehingga dapat {renderTercipta(report.charts[1].items[2].values[0])}.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pagebreak'></div>
                                    <br/><br/>
                                    <div style={{textAlign:'center'}}>
                                        <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 3. Tingkat Kepuasan dan Komitmen</p>
                                    </div>
                                    <br/>
                                    <div className='div-margin'>
                                        <div className='div-flex div-justify-center'>
                                            <div style={{width:'80%',height:200}}>
                                            <Bar
                                                // height={200}
                                                data={chart1}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            display:true,
                                                            grid: {
                                                                display:true
                                                            },
                                                            ticks:{
                                                                color:'#464646',
                                                                font:{
                                                                    weight:'bold',
                                                                    size:20,
                                                                }
                                                            }
                                                        },
                                                        y: {
                                                            grid: {
                                                                display:false
                                                            }  
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'top',
                                                        formatter: function(value) {
                                                            return value
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />
                                            </div>
                                        </div> 
                                    </div>
                                    <br/><br/><br/><br/><br/><br/><br/><br/>
                                    <div className='div-margin'>
                                        <div className='div-flex' style={{gap:10}}>
                                            <div style={{width:'50%',height:400,padding:30,boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor:'white',}}>
                                            <Bar
                                                height={330}
                                                data={chart2}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    maintainAspectRatio: true,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            },
                                                            
                                                        },
                                                        y: {
                                                            grid: {
                                                                display:false
                                                            } , 
                                                            ticks:{
                                                                color:'#464646',
                                                                font:{
                                                                    // weight:'bold',
                                                                    size:10
                                                                },
                                                                align:'left'
                                                            }
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'right',
                                                            offset:6,
                                                            font:{
                                                                size:10
                                                            },
                                                        formatter: function(value) {
                                                            return value
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />

                                            </div>
                                            <div style={{width:'50%',height:400,padding:30,boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor:'white',}}>
                                            <Bar
                                                height={330}
                                                data={chart3}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    maintainAspectRatio: true,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            },
                                                            
                                                        },
                                                        y: {
                                                            grid: {
                                                                display:false
                                                            } , 
                                                            ticks:{
                                                                color:'#464646',
                                                                font:{
                                                                    // weight:'bold',
                                                                    size:10
                                                                },
                                                                align:'left'
                                                            }
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'right',
                                                            offset:6,
                                                            font:{
                                                                size:10
                                                            },
                                                        formatter: function(value) {
                                                            return value
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <br/><br/>
                                    <div className='pagebreak'></div>
                                    
                                    <div className='report-shape3'>
                                        <h1>Motivasi Pegawai</h1>
                                    </div>
                                    <br/>
                                    <div className='div-margin'>
                                        <p>Berikut ini adalah gambaran prioritas pegawai terhadap aspek-aspek dalam organisasi yang paling memotivasi pegawai dalam bekerja:</p>
                                        <div style={{textAlign:'center'}}>
                                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 4. Aspek Motivasi Pegawai</p>
                                        </div>
                                        <div style={{width:'80%'}}>
                                        <Bar
                                                height={200}
                                                data={chart4}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    maintainAspectRatio: true,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            },
                                                            formatter:(value)=>{
                                                                return `${value}%`
                                                            },
                                                            ticks:{
                                                                callback: function(value, index, values) {
                                                                    return value+'%';
                                                                }
                                                            }
                                                        },
                                                        y: {
                                                            grid: {
                                                                display:false
                                                            } , 
                                                            ticks:{
                                                                color:'#464646',
                                                                font:{
                                                                    // weight:'bold',
                                                                    size:10
                                                                },
                                                                align:'left'
                                                            }
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'right',
                                                            offset:6,
                                                            font:{
                                                                size:10
                                                            },
                                                        formatter: function(value) {
                                                            return `${value}%`
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <div className='pagebreak'></div>
                                    <br/><br/>
                                    
                                        <div className='report-shape3'>
                                            <h1>Pemetaan Digital Transformation Readiness</h1>
                                        </div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                        <p>Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan keniscayaan terlepas bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industri. Asesmen yang telah Anda lakukan memberi gambaran tentang kesiapan organisasi Anda baik dari kesiapan agilitas strategi untuk melakukan diferensiasi memberi nilai kepada pelanggan <i>(differentiating digital capability)</i> maupun agilitas organisasi seperti sistem/proses, struktur, manusia dan budaya <i>(organization integrating capability)</i> untuk mendukung keberhasilan transformasi digital. 
                                            <br/><br/>
                                            Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Survei ini relevan bagi semua jenis organisasi. Partisipan yang mewakili organisasi pemerintahan atau nirlaba, diminta memikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda.
                                            <br/><br/>
                                            Hasil dari asesmen ini mengkategorikan organisasi Anda ke dalam salah satu dari empat kuadran yang ada: <i>Fully Ready, Not Ready, Weak Organization Agility</i>, atau <i>Poor Strategy Differentiation</i>. Ke empat kuadran ini dihasilkan dari pemetaan 2 dimensi: <i>differentiating digital capability</i> dan <i>organization integrating capability</i>. Dengan <i>differentiating digital capability</i>, organisasi melakukan pemantauan atas perubahan tren eksternal dan cepat memformulasi serta lincah mengeksekusi strategi digital yang tepat untuk memberi nilai tambah yang baru dan unik kepada pelanggan. Sedang organization integrating capability menjaga keselarasan dimensi organisasi tanwujud seperti sistem/proses, struktur, SDM dan budaya kerja telah dirancang untuk <i>agile</i> mendukung transformasi digital. </p>
                                            <br/>
                                            <div className='pemetaan' style={{width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                                <p>DIFFERENTIATING DIGITAL CAPABILITY</p>
                                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Definisi:</b> Kemampuan perusahaan untuk memberikan nilai tambah <i>(value proposition)</i> berbasis digital yang unik dalam akuisisi dan memenangkan loyalitas pelanggan</p>
                                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc'}}></div>
                                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Contoh:</b> Kemampuan inovasi, pelayanan, produksi dan distribusi, pengembangan ekosistem dengan menggunakan teknologi dan digitalisasi</p>

                                            </div>
                                            
                                            <div className='pemetaan' style={{marginTop:5,width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                                <p>ORGANIZATION INTEGRATING CAPABILITY</p>
                                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Definisi:</b> Kemampuan tanwujud <i>(intangible)</i> perusahaan untuk mendorong terciptanya kemampuan yang <i>agile</i> dan selaras dengan <i>differentiating capability</i></p>
                                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc',}}></div>
                                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Contoh:</b> Sistem/Proses, struktur, sumber daya manusia, serta budaya kerja dan kepemimpinan.</p>

                                            </div>

                                        </div>
                                        <br/><br/>
                                        <div className='pagebreak'></div>
                                        <div className='report-shape3'>
                                            <h1>Memahami Kuadran Kesiapan Transformasi Organisasi Anda</h1>
                                        </div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                            <p><b>Penempatan organisasi Anda dalam salah satu dari ke empat kuadran dapat dimaknai sebagai berikut:</b></p>
                                            <div style={{width: '100%',textAlign: 'center',}}>
                                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 5. Pemetaan Digital Transformation Readiness {report.digital.groupName.join(' dan ')} </p>
                                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}

                                            </div>
                                            <br/><br/>
                                        <div className="div-flex div-justify-center" style={{width: '100%'}}>
                                            <div style={{width:500,height: 350,position:'relative'}}>
                                                <div style={{textAlign:'center'}}>
                                                    <p style={{position:'absolute',top:0,left:20}}><b>WEAK<br/>ORGANIZATION AGILITY</b></p>
                                                </div>
                                                <div style={{textAlign:'center'}}>
                                                    <p style={{position:'absolute',top:0,right:60}}><b>FULLY READY</b></p>
                                                </div>
                                                <div style={{textAlign:'center'}}>
                                                    <p style={{position:'absolute',bottom:0,left:70}}><b>NOT-READY</b></p>
                                                </div>
                                                <div style={{textAlign:'center'}}>
                                                    <p style={{position:'absolute',bottom:0,right:30}}><b>POOR STRATEGY<br/> DIFFERENTIATION</b></p>
                                                </div>
                                                <p style={{position:'absolute',top:-40,left:150}}>Digital Strategy Differentiated</p>
                                                <p style={{position:'absolute',bottom:-40,left:100}}>Not Digital Strategy Differentiated</p>
                                                {/* <p className='vertical-text' style={{position:'absolute',top:160,left:-70}}>Low Organization Agility</p>
                                                <p className='vertical-text' style={{position:'absolute',top:160,right:-70}}>High Organization Agility</p> */}
                                                <div style={{textAlign:'center'}}>
                                                    <p  style={{position:'absolute',top:130,left:-20}}>Low<br/> Organization<br/> Agility</p>
                                                </div>
                                                <div style={{textAlign:'center'}}>
                                                    <p  style={{position:'absolute',top:130,right:-20}}>High<br/> Organization<br/> Agility</p>
                                                </div>
                                                <p className='vertical-text' style={{position:'absolute',top:160,left:100}}>Differentiating Capability</p>
                                                <p  style={{position:'absolute',top:160,left:200}}>Integrating Capability</p>
                                                <Scatter
                                                // height={230}
                                                data={chart7}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                     
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    
                                                    maintainAspectRatio: false,
                                                    scales: {
                                                            x: {
                                                                    display: true,
                                                                    ticks: {
                                                                        beginAtZero: true,
                                                                        steps: 10,
                                                                        stepValue: 50,
                                                                        
                                                                        display:false,
                                                                    },
                                                                    max: 100,
                                                                        min:-100,
                                                                    grid: {
                                                                        drawBorder: false,
                                                                        zeroLineColor: '#00ede4',
                                                                        zeroLineWidth:2,
                                                                        drawBorder: false,
                                                                        lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                                        color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0'
                                                                    }
                                                                },
                                                            y: {
                                                                    display: true,
                                                                    ticks: {
                                                                        beginAtZero: true,
                                                                        steps: 10,
                                                                        stepValue: 50,
                                                                        
                                                                        display:false,
                                    
                                                                    },
                                                                    max: 100,
                                                                        min:-100,
                                                                    grid: {
                                                                        drawBorder: false,
                                                                        zeroLineColor: '#00ede4',
                                                                        zeroLineWidth:2,
                                                                        lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                                        color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0	'
                                                                    }
                                                                }
                                                        },
                                                        plugins: {
                                                            tooltips: {enabled: false},
                                                            hover: {mode: null},
                                                            legend: {
                                                                display: false
                                                            },
                                                            datalabels: {
                                                            anchor: function(context) {
                                                                var value = context.dataset.data[context.dataIndex];
                                                                return value.v < 50 ? 'end' : 'center';
                                                            },
                                                            align: function(context) {
                                                                var value = context.dataset.data[context.dataIndex];
                                                                return value.v < 50 ? 'end' : 'center';
                                                            },
                                                            // color: function(context) {
                                                            //     var value = context.dataset.data[context.dataIndex];
                                                            //     return value.v < 50 ? context.dataset.backgroundColor : 'white';
                                                            // },
                                                            color:'black',

                                                            font: {
                                                                weight: 'bold',
                                                            },
                                                            formatter: function(value) {
                                                                // console.log('value', value)
                                                                return `${Math.round(value.value)}%`;
                                                            },
                                                            offset: 2,
                                                            padding: 0
                                                            }
                                                        },
                                                }}
                                                />
                                            </div>
                                           
                                        </div>
                                        </div>
                                        <br/><br/>
                                        <div className='pagebreak'></div>
                                        <br/>
                                        <div className='div-margin'>
                                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[1],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 1 FULLY READY :</b>  Organisasi dalam kuadran ini siap (atau telah) melakukan transformasi digital. Organisasi ini memiliki kemampuan melakukan diferensiasi strategi berbasis digital yang merubah pengalaman pelanggan secara unik. Dimensi <i>agile</i> organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi juga telah dibangun dan selaras dengan kebutuhan transformasi digital. </p>
                                            <br/>
                                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[2],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 2 Poor Strategy Differentiation :</b>  Organisasi dalam kuadran ini telah berinvestasi dalam mengembangkan <i>kapabilitas integrating</i> untuk membangun organisasi yang <i>agile</i>. Namun mereka belum menjalankan sepenuhnya strategi diferensiasi berbasis transformasi digital untuk merubah atau menambah nilai kepada pelanggan. Dengan adanya organisasi yang siap seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur & SDM yang <i>agile</i> , serta budaya yang menghargai inovasi, organisasi ini perlu segera memformulasi dan menjalankan strategi transformasi digital sehingga dapat berdampak kepada pelanggan. </p>
                                            <br/>
                                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[3],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 3 Not Ready :</b>  Organisasi dalam kuadran ini belum siap dan butuh waktu persiapan lebih matang untuk melakukan transformasi digital. Organisasi ini belum mampu melakukan diferensiasi strategi berbasis digital. Strategi yang diadopsi dan pengalaman pelanggan yang diciptakan masih belum berubah dari gaya lama. Di sisi lain, dimensi organisasi lainnya, seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi, belum dikembangkan sepenuhnya sehingga belum sesuai tuntutan transformasi digital. </p>
                                            <br/>
                                            <p style={{fontSize: 16,fontWeight: 'normal'}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[0],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 4 Weak Organization Agility :</b>  Organisasi ini telah mulai melakukan diferensiasi strategi berbasis digital untuk merubah atau menambah nilai kepada pelanggan. Namun dimensi organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi belum sepenuhnya dikembangkan sehingga belum sesuai tuntutan transformasi digital. Kurangnya penyelarasan antara kapabilitas diferensiasi dan integrasi ini dapat menghambat keberhasilan transformasi. Organisasi dalam kuadran ini perlu segera melakukan penyelarasan untuk mendukung keberhasilan transformasi digital yang sedang dilakukan. </p>
                                        </div>
                                        <br/>
                                        <div style={{width: '100%',textAlign: 'center',}}>
                                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 6. Digital Differentiating Capability</p>
                                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}

                                        </div>
                                        <br/>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <div style={{width:'70%',height:100}}>
                                            <Bar
                                                // height={00}
                                                data={chart8}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    plugins: {
                                                        datalabels: {
                                                        formatter: function(value) {
                                                            if(value!==0){
                                                                return value.toFixed(2);

                                                            }else{
                                                                return ''
                                                            }
                                                        },
                                                        align:'end',
                                                        offset: 20,
                                                        padding: 0
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    responsive: true,
                                                        maintainAspectRatio: false,
                                                        legend: { display:false },
                                                    tooltips: {
                                                        enabled: false
                                                    },
                                                    responsive: true,
                                                    legend: {
                                                        display: false,
                                                        
                                                    },
                                                    scales: {
                                                        y: {
                                                            stacked: true,
                                                            barPercentage: 0.75,
                                                            grid: {
                                                                display: false,
                                                                drawTicks: false,
                                                                drawOnChartArea: false
                                                            },
                                                            ticks: {
                                                                padding: 10,
                                                                fontColor: '#555759',
                                                                fontFamily: 'Lato',
                                                                fontSize: 11
                                                            }
                                                            
                                                        },
                                                        x: {
                                                            stacked: true,
                                                            grid: {
                                                                display: false,
                                                                drawTicks: false,
                                                                color: '#464646'
                                                            },
                                                            suggestedMin: 1,
                                                            suggestedMax: 4,
                                                            // maxRotation: 0,
                                                        ticks: {
                                                            padding: 5,
                                                            // beginAtZero: true,
                                                            fontColor: '#555759',
                                                            fontFamily: 'Lato',
                                                            fontSize: 11,
                                                            
                                                            callback: function(label, index, labels) {
                                                                return null
                                                            // if(label==10){
                                                            //     return 'Digital Stategy Differentiated'
                                                            // }else if(label==-10){
                                                            //     return 'Brick and Mortar Strategy'
                                                            // }else if(label==0){
                                                            //     return 0
                                                            // }else{
                                                            //     return null
                                                            // }
                                                
                                                            }
                                                            
                                                        },
                                                        
                                                        }
                                                    }
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                            <p>Selain memliki Differentiating Digital Strategy yang jelas, organisasi yang siap bertransformasi membangun dan menyelaraskan kemampuan organisasi berbasis agilitas atau <i>Organization Integrating Capability</i>. Dimensi <i>agile</i> dalam organisasi terdiri sistem/proses kerja ala <i>rapid-decision making</i>, struktur organisasi yang flat dan fleksibel, pengelolaan talenta SDM yang <i>agile</i>, serta budaya yang menghargai inovasi dan mendorong pengambilan risiko (serta belajar dari kesalahan).   </p>
                                        </div>
                                        <br/>
                                        <div className='pagebreak'></div>
                                        <br/>
                                        <div className='div-margin' style={{width: '100%',textAlign: 'center'}}>
                                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 7. Organization Integrating Capability</p>
                                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}
                                            <div className='div-margin'>
                                            <div style={{width:'70%'}}>
                                            <Bar
                                                height={400}
                                                data={chart9}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    indexAxis: 'y',
                                                    maintainAspectRatio: false,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: {
                                                            stacked:true,
                                                            min:1,
                                                            max:4,
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            }  ,
                                                            ticks: {
                                                                padding: 5,
                                                                beginAtZero: true,
                                                                fontColor: '#555759',
                                                                fontFamily: 'Lato',
                                                                fontSize: 11,
                                                                
                                                                callback: function(label, index, labels) {
                                                                return null
                                                                // if(label==-6){
                                                                //     return 'Low Organization Agility'
                                                                // }else if(label==6){
                                                                //     return 'High Organization Agility'
                                                                
                                                                // }else{
                                                                //     return null
                                                                // }
                                                    
                                                                }
                                                                
                                                            },
                                                        },
                                                        y: {
                                                            display:true,
                                                            grid: {
                                                                display:false
                                                            }  
                                                        },
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                            anchor: 'end',
                                                            align: 'right',
                                                            offset:10,
                                                        formatter: function(value) {
                                                            return value.toFixed(2)
                                                        },
                                                        },
                                                        tooltip: {enabled: false},
                                                        hover: {mode: null},
                                                        legend: {
                                                            display: false,
                                                        },
                                                        
                                                    },
                                                }}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                            <p><b>Execution-biased Systems:</b> Organisasi yang bertransformasi digital tidak lagi melihat teknologi hanya sebagai <i>enabler</i>, namun investasi dalam teknologi dan digitalisasi terbaru terus dilakukan sehingga teknologi menjadi teintegrasi dalam proses bisnis. Data dilihat sebagai aset strategis yang dapat diakses oleh semua bagian. Proses inovasi dan pengambilan keputusan dilakukan dengan cepat dan lincah berbasis iterasi berulang.</p>
                                            <p><b>Empowered Structure:</b> Struktur organisasi yang <i>agile</i>, lintas fungsional dan independen, minim intervensi manajemen puncak diterapkan dalam berinovasi. Pemindahan talenta antar fungsi atau proyek dapat dilakukan dengan lincah tanpa birokrasi berbelit.</p>
                                            <p><b>Entrepreneurial People:</b> Sumber daya manusia terus dikembangkan dengan budaya pembelajaran. Inovasi dengan akuntabilitas diterapkan dan diberikan penghargaan. Manajemen kinerja yang adaptif terhadap perubahan bisnis diimplementasikan.</p>
                                            <p><b>Adhocracy Culture:</b> Budaya kolaboratif yang berfokus kepada penciptaan nilai baru dan kepuasan pelanggan merupakan roh perusahaan. Inovasi dan <i>risk-taking</i> disertai disiplin dalam melakukan reviu dan mendapatkan pembelajaran dari proses keberhasilan serta kegagalan merupakan bagian dari budaya kerja.</p>
                                            <p><b>Ambidextrous Leadership:</b> Kepemimpinan yang menyeimbangkan antara eksploitasi dan eksplorasi, sehingga mampu menjaga fokus kinerja keseharian sambal sekaligus mendorong pengembangan inovasi untuk masa depan.</p>
                                            <p>Lihat kembali nilai Anda dan identifikasi dimensi dalam <i>Organization Integrating Capability</i> yang memiliki nilai relatif rendah. Lakukan prioritas untuk melakukan penyelarasan organisasi agar transformasi digital Anda dapat lebih berhasil.</p>
                                        </div>
                                        <br/>
                                        <div className='pagebreak'></div>
                                        <br/>
                                        <div className='report-shape3'>
                                            <h1>Pemetaan Kemampuan Agilitas Strategy-into-Performance Execution Excellence (SPEx2) vs {report.digital.groupName.join(' vs ')} vs Benchmark</h1>
                                        </div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                            <p>Berbagai riset di Indonesia dan dunia menunjukkan bahwa strategi yang baik, termasuk transformasi digital, sering gagal 
                                                diimplementasi. Kemampuan formulasi dan eksekusi strategi (SPEx2) merupakan kemampuan yang akan membedakan 
                                                organisasi yang hanya “cerdas” dengan yang <i>agile</i> atau “lincah.” Organisasi yang <i>agile</i> adalah organisasi yang aktif memonitor 
                                                perkembangan tren eksternal, cepat memformulasi strategi untuk mengantisipasi dan meresponi perubahan, dan selalu aktif 
                                                melakukan reviu atas hasil implementasi strategi.</p>
                                            <div style={{width: '100%',textAlign: 'center'}}>
                                                <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 8. Pemetaan Kemampuan Agilitas Strategy-Into-Performance Execution Exellence (SPEx2) </p>
                                                {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}

                                            </div>
                                            <br/>
                                                <div style={{width:700,margin: 'auto',height: 400}}>
                                                <Bar
                                                // height={200}
                                                data={chart10}
                                                plugins={[ChartDataLabels]}
                                                options={{
                                                    tooltips: {enabled: false},
                                                    hover: {mode: null},
                                                    
                                                    maintainAspectRatio: false,
                                                    animation: {
                                                        duration: 0
                                                    },
                                                    scales: {
                                                        x: { 
                                                            grid: {
                                                                display: false,
                                                            },
                                                            ticks: {
                                                                fontColor: "#464646",
                                                                fontFamily:'Lato', // this here,
                                                                autoSkip: false,
                                                                maxRotation: 0,
                                                                minRotation: 0
                                                            },
                                                        },
                                                        y: 
                                                        {
                                                            grid: {
                                                                color: '#464646'
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#464646",
                                                                fontFamily:'Lato' // this here
                                                            },
                                                        },
                                                    
                                                    },
                                                    plugins: {
                                                        datalabels: {
                                                        formatter: function(value) {
                                                            return '';
                                                        },
                                                        offset: 2,
                                                        padding: 0
                                                        },
                                                        legend: {
                                                            display: true,
                                                            position:'bottom',
                                                            labels: {
                                                                usePointStyle: true
                                                            }
                                                        },
                                                    },
                                                }}
                                                />
                                                </div>
                                            <br/>
                                            <p><b>Kemampuan SPEx2 yang agile memiliki 5 tahapan:</b></p>
                                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Formulasi strategi:</b> Kemampuan organisasi memantau perkembangan luar, memformulasi respon strategi berbasis digital dan data baik untuk perbaikan operasi, mengembangkan bisnis model baru, atau memberikan nilai tambah yang merubah pengalaman pelanggan. Strategi mengedepankan keberanian untuk melakukan disrupsi bahkan mengkanibalisasi bisnis yang ada.</p>
                                            </div>
                                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemetaan strategi:</b> Kemampuan menggambarkan strateginya secara visual dan menarik dengan penanggung
                                                jawab yang jelas dalam organisasi disertai pengukuran keberhasilan yang menyeimbangkan indikator <i>leading </i>
                                                atau proses dan <i>lagging</i> atau hasil. Komunikasi strategi dilakukan dengan media berbasis digital untuk
                                                menjangkau karyawan milenial.
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <br/>
                                        <div className='pagebreak'></div>
                                        <br/>
                                        <div className='pemetaan div-margin strategic-text'>
                                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Penyelarasan organisasi:</b> Strategi organisasi diselaraskan dengan investasi dalam pengembangan teknologi
                                                digitalisasi dan sumber daya manusia. Struktur organisasi yang ambidekster dibentuk untuk mendukung
                                                agilitas.</p>
                                            </div>
                                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Eksekusi operasional:</b> Program kerja strategis dieksekusi dengan <i>mindset</i> yang fokus kepada pelanggan atau
                                                <i>stakeholder</i> utama. Implementasi yang cepat berbasis <i>rapid decision making</i>. Inovasi terus terjadi dalam proses
                                                eksekusi. Pemindahan talenta dan pembagian pembelajaran/ide antar proyek atau fungsi mudah dilakukan
                                                dengan cepat.</p>
                                            </div>
                                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemantauan dan penyelarasan kembali :</b>  Pemantauan dan reviu kinerja dilakukan dengan disiplin dengan
                                                menggunakan pengukuran yang selaras antar individu/unit kerja dengan organisasi serta maturitas bisnis. Ide ide baru terus muncul dalam pembelajaran. Penghargaan atas keberhasilan inovasi diberikan dan kegagalan juga
                                                dihargai sebagai bagian dari proses menuju keberhasilan.</p>
                                            </div>
                                            <p><b>Lihat kembali nilai Anda dan identifikasi tahapan dalam SPEx2 yang memiliki nilai relatif rendah, baik dibanding
                                            dengan kelima tahapan dalam organisasi Anda,maupun dibanding perusahaan pembanding. Lakukan prioritas
                                            untuk melakukan perbaikan agar transformasi digital Anda dapat lebih berhasil.
                                            </b></p>
                                        </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>}
        </div>
    )
}
export default withRouter(ReportEdtra)