import React,{useEffect,useState,useRef} from 'react'
import './style.css'
import {Button,Checkbox ,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import Logo1 from '../../../assets/image/logo.png'
import Logo2 from '../../../assets/image/logo_report_2.png'
import Map from '../../../assets/image/map.svg'
import report_1 from '../../../assets/image/report/report_1.jpg'
import report_2 from '../../../assets/image/report/report_2.jpg'
import report_3 from '../../../assets/image/report/report_3.jpg'
import report_4 from '../../../assets/image/report/report_4.jpg'
import report_5 from '../../../assets/image/report/report_5.jpg'
import report_6 from '../../../assets/image/report/report_6.jpg'
import report_7 from '../../../assets/image/report/report_7.jpg'
import report_8 from '../../../assets/image/report/report_8.jpg'
import report_9 from '../../../assets/image/report/report_9.jpg'
import report_10 from '../../../assets/image/report/report_10.jpg'
import report_11 from '../../../assets/image/report/report_11.jpg'
import report_12 from '../../../assets/image/report/report_12.jpg'
import report_13 from '../../../assets/image/report/report_13.jpg'
import report_14 from '../../../assets/image/report/report_14.jpg'
import report_15 from '../../../assets/image/report/report_15.jpg'
import report_16 from '../../../assets/image/report/report_16.jpg'
import report_17 from '../../../assets/image/report/report_17.jpg'
import report_18 from '../../../assets/image/report/report_18.jpg'
import report_od from '../../../assets/image/report/report_od.png'

import Check from '../../../assets/image/Check.svg'
import Close from '../../../assets/image/Close_red.svg'
import Loader from '../../../components/Loading'

import ChevronGrey from '../../../assets/image/chevron_grey.svg'
import {getAkhlak, getAkhlakResultAll,getListIndustry, getReportAkhlakAll} from '../../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import {Helmet} from "react-helmet";
import {Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {Chart} from 'chart.js'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import { CheckBox } from '@material-ui/icons'

function ReportAll(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let init=[false,false,false,false,false]
    const [loaders,setLoaded]=useState(init)
    const [timer,setTimer]=useState(0)
    const {report}=audit
    const canvas = useRef(null);
    const canvas2 = useRef(null);
    const [chart1,setChart1]=useState([])
    const [chart1Color,setChart1Color]=useState(['#ed4900','#0089ed','#d200ed','#aded00','#00ede4','#ed8900'])
    const [group_name,setGroup]=useState('')
    const [total,setTotal]=useState(0)
    const [industry_list,setIndustryList]=useState([])
    const [industry_filter,setIndustryFilter]=useState(['Rata rata'])
    const [for_slug,setForSlug]=useState([])
    const color_list=[
        {
            color1:'rgb(220,20,60)',
            color2:'rgba(220,20,60,0.5)'
        },
        {
            color1:'rgb(205,92,92)',
            color2:'rgba(205,92,92,0.5)'
        },
        {
            color1:'rgb(250,128,114)',
            color2:'rgba(250,128,114,0.5)'
        },
        {
            color1:'rgb(255,215,0)',
            color2:'rgba(255,215,0,0.5)'
        },
        {
            color1:'rgb(255,140,0)',
            color2:'rgba(255,140,0,0.5)'
        },
        {
            color1:'rgb(255,250,205)',
            color2:'rgba(255,250,205,0.5)'
        },
        {
            color1:'rgb(255,218,185)',
            color2:'rgba(255,218,185,0.5)'
        },
        {
            color1:'rgb(240,230,140)',
            color2:'rgba(240,230,140,0.5)'
        },
        {
            color1:'rgb(189,183,107)',
            color2:'rgba(189,183,107,0.5)'
        },
        {
            color1:'rgb(50,205,50)',
            color2:'rgba(50,205,50,0.5)'
        },
        {
            color1:'rgb(124,252,0)',
            color2:'rgba(124,252,0,0.5)'
        },
        {
            color1:'rgb(173,255,47)',
            color2:'rgba(173,255,47,0.5)'
        },
        {
            color1:'rgb(0,250,154)',
            color2:'rgba(0,250,154,0.5)'
        },
        {
            color1:'rgb(143,188,143)',
            color2:'rgba(143,188,143,0.5)'
        },
        {
            color1:'rgb(128,128,0)',
            color2:'rgba(128,128,0,0.5)'
        },
        {
            color1:'rgb(0,255,255)',
            color2:'rgba(0,255,255,0.5)'
        },
        {
            color1:'rgb(0,139,139)',
            color2:'rgba(0,139,139,0.5)'
        },
        {
            color1:'rgb(176,196,222)',
            color2:'rgba(176,196,222,0.5)'
        },
        {
            color1:'rgb(255,0,255)',
            color2:'rgba(255,0,255,0.5)'
        },
    ]
    useEffect(() => {

        if(industry_list.length<1){
            getListInd()

        }
        if(industry_list.length>0){
            getChart()

        }
        
    },[industry_list]);
    
    const getListInd=async ()=>{

        let getdetailakhlak=await dispatch(getAkhlak())
        if(getdetailakhlak){

            let getindustry=await dispatch(getListIndustry(`/${getdetailakhlak.data.id}`))
            if(getindustry){
                let newind=[
                    {
                        id:0,
                        text:{
                            id:'Rata-rata',
                            en:'Rata-rata'
                        },
                        checked:true,
                        color1:'rgb(0,191,255)',
                        color2:'rgba(0,191,255,0.5)'
                    }
                ]
                
                getindustry.data.map((data,i)=>{
                    newind.push({...data,checked:false,color1:color_list[i].color1,color2:color_list[i].color2})
                })
                await setIndustryList(newind)
            }
        }
        
    }
    const getChart=async ()=>{
        // let newd=[]
        industry_list.map(async (data)=>{
            if(data.checked){
                let filter=for_slug.filter((f)=>{
                    return f===data.id
                })
                if(filter.length<1){
                    await setForSlug([...for_slug,data.id])

                }

            }
        })
        const findColor=(text)=>{
            if(industry_list.length>0&&text==='Nilai rata-rata'){
                return {color1:industry_list[0].color1,color2:industry_list[0].color2} 
            }
            let filter=industry_list.filter((data)=>{
                return data.text.id===text
            })

            if(filter.length>0){
                return {color1:filter[0].color1,color2:filter[0].color2}

            }else{
                return {color1:'',color2:''}
            }
        }
        let getdetailakhlak=await dispatch(getAkhlak())
        if(getdetailakhlak){
            let res= await dispatch(getReportAkhlakAll(`/${getdetailakhlak.data.id}/${for_slug.length>0?for_slug.join(','):0}`))
            if(res){
                let newdatas=[]
                res.data.map((data,i)=>{
                    let d=[]
                    data.dimensions.map((dim)=>{
                        if(dim.indicators!==null){
                            d.push(dim.indicators[0].value)
                        }
                    })
                    
                    newdatas.push({data:d,borderColor:findColor(data.title).color1,backgroundColor:findColor(data.title).color2})
                })
                console.log(`newdatas`, newdatas)
                const cfg = {
                    type: 'radar',
                    data: {
                        labels: ['Amanah', 'Kompeten', 'Harmoni', 'Loyal','Adaptif','Kolaboratif'],
                        datasets: newdatas,
                    },
            
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        
                        scale: {
                            beginAtZero: true,
                            max: 4,
                            min:1,
                            pointLabels :{
                                fontStyle: "bold",
                                fontSize:16
                            }
                        },
                          plugins: {
                            legend: {
                                display: false
                            },
                            datalabels: {
                              formatter: function(value) {
                                return '';
                              },
                              offset: 2,
                              padding: 0
                            }
                          },
                        elements: {
                            point:{
                                radius: 0
                            }
                        }
                    }
                };
                if(industry_list.length>0){
                    // if(window.chart){
                    //     window.chart.destroy()
                    // }
                    if(window.chart)
                    {
                        window.chart.destroy();
                    }
                    window.chart = new Chart(canvas.current.getContext('2d'), cfg);
                    

                    window.chart.update();
                    // chart.destroy();
                }
                
            }
        }
        
        
    }
    console.log('industry_filter', industry_filter)
    const onChangeCheckbox=(index,id,text)=>{
        let check=industry_filter.filter((d)=>{
            return d===text
        })
        if(industry_list.length>1){
            if(check.length>0){
                industry_list[index].checked=!industry_list[index].checked
            }else{
                if(industry_filter.length<3){
                    industry_list[index].checked=!industry_list[index].checked
                }
            }
            

        }
        
        
        if(check.length>0){
            let newind=industry_filter.filter((d)=>{
                return d!==text
            })
            let new_for_slug=for_slug.filter((f)=>{
                return f!==id
            })
            setForSlug(new_for_slug)
            setIndustryFilter([...newind])
        }else{
            if(industry_filter.length<3){
                setForSlug([...for_slug,id])
                setIndustryFilter([...industry_filter,text])
            }
            
        }
        setIndustryList([...industry_list])
    }
    let image1=[
        report_1,
        report_13,
        report_17,
        report_18,
    ]
    let image2=[
        report_5,
        report_6,
        report_7,
        report_8,
    ]
    let image3=[
        report_9,
        report_10,
        report_11,
        report_12,
    ]
    let image4=[
        report_1,
        report_13,
        report_14,
        report_15,
    ]
    let image5=[
        report_1,
        report_14,
        report_16,
        report_15,
    ]
    const renderImage=()=>{
        let {id,uuid}=props.match.params
        switch (parseInt(id)) {
            case 3:
                return image2
            case 2:
                return image3
            case 1:
                return image1
            case 4:
                return image4
            case 5:
                return image5
            case 6:
                return image5
            default:
                return image1
                break;
        }
    }
    let oo=[]
    const setonLoaded=(index)=>{
        
            let b=[false,true]
            
            
            let c=init.filter((data)=>{
                return data===false
            })
            if(oo.length<4){

                oo.push(index)
            }else{
                oo=[]
                setTimeout(()=>{
                    // window.print()
                },3000)
            }
            

    }
    const renderCheck=()=>{
        
        let map=industry_list.map((data,i)=>{
            const checkBoxStyles = (color) => ({
                root: {
                  '&$checked': {
                    color: data.color1,
                  },
                },
              checked: {}
            })
            
            const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
            if(i!==0){
                return(
                    <FormControlLabel style={{display:'flex',alignItems:'flex-start'}} key={i} control={<CustomCheckbox name="checkedC" checked={industry_list[i].checked} onChange={()=>onChangeCheckbox(i,data.id,data.text.id)}  color='primary' style={{padding:'0px 5px 0px 10px'}}/>} label={data.text.id} />
                )
            }
        })
        return map
    }
    const checkBoxStyles2 = () => ({
        root: {
          '&$checked': {
            color: industry_list[0].color1,
          },
        },
      checked: {}
    })
    
    const CustomCheckbox2 = withStyles(checkBoxStyles2)(Checkbox); 
    return (
        <>
        <Loader/>
        {industry_list.length>0&&
        <div className='report-wrapper' >
            <Helmet>
                <title>Report-Akhlak</title>
                
            </Helmet>
            <div className='report-header'>
                <div className='report-shape'></div>
                <div className='div-margin'>
                    <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                </div>
            </div>
            <div className='report-footer'>
                <div className='div-margin'>
                    <p>Report date :  {moment().format('DD MMM YYYY')}</p>
                </div>
            </div>
            <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">
                        <div className='div-flex div-margin'>
                            {renderImage().map((data,index)=>(
                                <div style={{width:140,height:140}}>
                                <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>Survey Implementasi Budaya Akhlak</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <br/>
                        <br/><br/>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        {/* page1 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Hasil Penilaian Implementasi Budaya Akhlak</h1>
                        </div>
                        <br/>
                        <div className='div-margin '>
                            <p ><b style={{fontSize:16}}>Survey ini mengukur implementasi budaya AKHLAK yang berlaku di perusahaan-perusahaan BUMN di Indonesia. Harap dicatat bahwa ini merupakan survey singkat dan hasilnya tidak akan memberikan laporan yang menyeluruh tentang implementasi AKHLAK di perusahaan masing-masing.</b></p>
                            <br/>
                            <p><b style={{fontSize:16}}>Diagram Hasil Penilaian Implementasi Budaya Akhlak:</b></p>
                            <br/>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div className="chartjs-wrapper" style={{width:'45%',height:350}}><canvas ref={canvas}  ></canvas></div>
                                <div style={{width:'45%'}}>
                                    <p style={{color:'#464646',fontSize:20,fontWeight:'bold'}}>Industri :</p>
                                    <br/>
                                    <FormControlLabel style={{display:'flex',alignItems:'flex-start',marginBottom:10}} control={<CustomCheckbox2   checked={industry_list[0].checked} onChange={()=>onChangeCheckbox(0,industry_list[0].id,'Rata rata')} style={{padding:'0px 5px 0px 10px'}} name="checkedC"  />} label="Rata rata" />

                                    <div style={{display:'grid',gridTemplateColumns: 'repeat( 2,1fr)',gridRowGap:10}}>
                                        {renderCheck()}
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                        </div>

                        {report!==null&&report.map((data,i)=>(
                                <div key={i} style={{width:'100%'}}>
                                    <div className='pagebreak'></div>

                                    <br/><br/>
                                    <div className='report-shape3'>
                                        <h1>{data.title}</h1>
                                    </div>
                                    <br/>
                                    <div className='div-margin'>
                                    <table border="0" cellspacing="0" cellpadding="0" width='100%'>
                                        <thead>
                                            <th className='akhlak-bold'>Kompetensi</th>
                                            <th className='akhlak-bold'>Nilai rata-rata</th>
                                            <th className='akhlak-bold'>Interpretasi</th>
                                        </thead>
                                        <tbody>
                                            {data.dimensions.map((dm,i)=>(
                                                <tr key={i}>
                                                    <td className='akhlak-bold' style={{width:100}}>
                                                        {dm.title}
                                                    </td>
                                                    <td className='akhlak-bold' style={{width:140}}>
                                                        {dm.indicators!==null?dm.indicators[0].value.toFixed(2):'0.00'}
                                                    </td>
                                                    <td className='akhlak-bold2' >
                                                       {dm.description}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            ))}
                        <div className='pagebreak'></div>

                        
                        
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
        </div>
         } 
        </>
    )
}
export default withRouter(ReportAll)