import {Modal,OutlinedInput,FormControl,InputLabel,TextField,Button, Backdrop,Fade,Paper,Typography,Divider,Grid} from '@material-ui/core'
import React, { Component } from 'react'
import './style.css'
import Close from '../../assets/image/closeblack.svg'
export default class index extends Component {
    render() {
        const { isOpen, title,children, modalToggle,without_x,width} = this.props;
        return (
            <div>
                <Modal
                    className='modal'
                    open={isOpen}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    // style={{position: 'fixed', zIndex: 1300, right: 0, top: 0, left: 0,}}
                >
                   <div className='modal-content' style={{width:width!==undefined?width:400}}>
                       <div className='modal-header'>
                            <h2>{title}</h2>
                            {!without_x&&<img src={Close} style={{width:20}} onClick={modalToggle}/>}
                       </div>
                        {/* <hr size='1'/> */}
                       <div className='modal-body'>
                            {children}
                       </div>
                   </div>
                </Modal>
            </div>
        )
    }
}
