import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#00a1ed',
    main:'#00a1ed',
    light: '#00a1ed'
  },
 
};
