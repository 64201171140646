import React,{useState,useEffect} from 'react'
import './style.css'
import {Button,RadioGroup ,Radio ,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import Takenote from '../../../assets/image/tdrc/Takenote.svg'
import {useDispatch,useSelector} from 'react-redux'
import {getAkhlak,getDetailSurvey3,setActiveQuestion,setJawaban,postAudit,getDataReport} from '../../../redux/action/audit'
import * as actionTypes from '../../../redux/constant/audit'
import Loader from '../../../components/Loading'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Kurang from '../../../assets/image/Kurang.jpg'
import Cukup from '../../../assets/image/Cukup.jpg'
import Baik from '../../../assets/image/Baik.jpg'
import SangatBaik from '../../../assets/image/SangatBaik.jpg'
import Logo from '../../../assets/image/logo.png'
import {isMobile} from 'react-device-detect'
import Modal from '../../../components/modal'
import InputMask from 'react-input-mask'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 5,
      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const PetunjukPengisian=({detail_survey,next,back})=>{
    return(
        <div style={{width:'100%'}}>
            <div style={{padding:'20px 20px 0px 20px'}}>
                <h3>{detail_survey.intro}</h3>
                <br/>
                <p>{detail_survey.description}</p>
                <br/>
                
            </div>
            <div className='kolom-isi hide-mobile'>
                <div className='square-1'>
                    <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                </div>
                <div className='square-middle '>
                <RadioGroup row aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:3,height:60}}

                        />
                    </RadioGroup>
                </div>
                <div className='square-2'>
                    <p>Kami fokus pada perubahan perilaku digital pelanggan atau stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience membeli atau menggunakan solusi/produk kami</p>
                </div>
            </div>
            <div className='kolom-isi-mobile'>
                <div style={{width:'70%',display:'flex',justifyContent:'space-between',flexDirection:'column',height:'100%'}}>
                    <div className='square-1' style={{marginBottom:10}}>
                        <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                    </div>
                    
                    <div className='square-2'>
                        <p>Kami fokus pada perubahan perilaku digital pelanggan atau stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience membeli atau menggunakan solusi/produk kami</p>
                    </div>
                </div>
                <div className='square-middle '>
                    <RadioGroup column aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<p>1</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label={<p>2</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label={<p>3</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary" />}
                            label={<p>4</p>}
                            labelPlacement="top"
                            style={{margin:5,height:60}}

                        />
                    </RadioGroup>
                </div>
            </div>
            <br/>
            <div className='div-flex div-justify-between' style={{padding:20}} >
                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                <Button onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Ikuti Survey</Button>
            </div>
        </div>
        
    )
}
const Introduction=({detail_survey,next})=>{
    return(
        <div style={{padding:20}}>
            <h3>{detail_survey.title} </h3>
            <br/>
            <p style={{lineHeight:2}}>{detail_survey.description}</p>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={next} color='primary' variant='contained' size='large' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
            </div>
        </div>
    )
}
const Welcome=({detail_survey,next,back})=>{
    return(
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <h2>Selamat datang, </h2>
                        <p>Anda sedang menjalani proses <b>Survey Implementasi Budaya Akhlak </b></p>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                            <Introduction 
                                    detail_survey={detail_survey}
                                    next={next}
                                    back={back}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
const Pertanyaan=({next,back,detail_survey,chooseAnswer,findActiveJawaban2,active_question,handleDisable,onSubmit,survey_length})=>{
    const [need_help,setNeedHelp]=useState(false)
    const child=()=>{
        return(
            <div className='help-mobile'>
                <p>Differentiating Capability<br/>
                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                <br/><br/>
                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
            </div>
        )
    }
    const modToggle=()=>{
        setNeedHelp(!need_help)
    }
    console.log('uyuuu',survey_length)
    return(
        <div className='welcome-wrapper'>
               {isMobile&&<Modal title="" isOpen={need_help} children={child()} modalToggle={modToggle}/>}
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        {/* <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div> */}
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%',position:'relative'}}>
                            <div style={{padding:'20px 20px 0px 20px'}}>
                            <div className='hide-mobile div-flex div-align-center div-justify-between'>
                                <p style={{color:'#252525',fontSize:18}}>Survey</p>
                                &nbsp;
                                <div style={{width:'70%',display:'flex',alignItems:'center'}}>
                                <progress className='bar-dtra' max={survey_length-2} value={active_question+1-2}></progress>

                                </div>
                                &nbsp;
                                <p style={{color:'#808080',fontSize:12}}>{detail_survey.title.id}</p>
                                &nbsp;
                                {/* <div className='help' onClick={()=>modToggle()}>?</div>&nbsp; */}

                            </div>
                            {need_help&&!isMobile&&<div className='help-backdrop' onClick={()=>setNeedHelp(false)}></div>}
                            {need_help&&!isMobile&&<div className='help-wrapper'>
                                <p>Differentiating Capability<br/>
                                Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
                                <br/><br/>
                                Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
                            </div>}
                            <div className='show-mobile'>
                                <div className='div-flex div-justify-between'>
                                    <p>Survey</p>
                                    <div className='div-flex div-align-center'>
                                        <p>{detail_survey.title.id}</p>
                                        &nbsp;&nbsp;
                                        {/* <div className='help' onClick={()=>setNeedHelp(!need_help)}>?</div> */}
                                    </div>
                                </div>
                                <br/>
                                <progress className='bar-dtra' max={survey_length-2} value={active_question+1-2}></progress>

                            </div>
                                
                            </div>
                            <br/>
                            <div className='pertanyaan-container' >
                                <p>{detail_survey.intro.id}</p>
                                <br/>
                                <div className='div-flex' style={{position:'relative'}}>
                                    <div style={{width:20,top:3,height:20,borderRadius:'100%',backgroundColor:'#00a1ed',position:'absolute'}}></div>
                                    <p style={{marginLeft:25}}>{detail_survey.items[0].text.id}</p>

                                </div>
                                <br/>
                                <RadioGroup aria-label="gender" name="gender1" onChange={(e)=>chooseAnswer(e.target.value)} value={active_question>1?parseInt(findActiveJawaban2().option):0}>
                                    {detail_survey.items[0].options.map((data,i)=>(
                                        <FormControlLabel value={data.id} key={i} control={<Radio color='primary'/>} label={<p style={{color:'#252525'}}>{data.text.id}</p>} />
                                    ))}
                                </RadioGroup>
                            </div>
                            
                            <div className='div-flex div-justify-between footer-form' style={{padding:20,bottom:0}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                &nbsp;&nbsp;
                                {active_question+1-2===survey_length-2?
                                <Button disabled={handleDisable()} onClick={onSubmit} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Lihat hasil</Button>
                                :
                                <Button disabled={handleDisable()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
const Demografi=({org_index,onChangeOther,next,back,detail_survey,chooseAnswer,findActiveJawaban2,active_question,handleDisable,onChangeDemografi,list_jawaban,onSetActive,handleDisable2,isOther})=>{
    const classes=useStyles()
    const renderOtherDisable=(answer,id)=>{
        if(answer===id){
            return false
        }else{

            return true
        }
    }
    
    console.log(`detail_survey`, detail_survey)
    return(
        <div className='welcome-wrapper'>
               
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        {/* <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div> */}
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative',width:'100%'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:350,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%'}}>
                            <div style={{padding:'20px 20px 0px 20px'}}>
                                <div className='hide-mobile div-flex div-align-center div-justify-between'>
                                    <p style={{color:'#252525',fontSize:18}}>{detail_survey.intro.id}</p>
                                    &nbsp;
                                    <div style={{width:'70%',display:'flex',alignItems:'center'}}>
                                    <progress className='bar-dtra' max={19} value={0}></progress>

                                    </div>
                                    &nbsp;
                                    <p style={{color:'#808080',fontSize:12}}>{detail_survey.title.id}</p>

                                </div>
                                <div className='show-mobile'>
                                    <div className='div-flex div-justify-between'>
                                        <p>{detail_survey.intro.id}</p>
                                        <p>{detail_survey.title.id}</p>
                                    </div>
                                    <br/>
                                    <progress className='bar-dtra' max={19} value={0}></progress>

                                </div>
                                <br/>
                                <div className='demografi-wrapper'>
                                {detail_survey.items.map((field,i)=>{
                                    if(field.itemType==='textfield'||field.itemType==='email'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                                <TextField
                                                    label={<>{field.text.id}<span style={{color:'red'}}>*</span></>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    type='text'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                    value={list_jawaban[org_index].data_org[i].answerText}
                                                    onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}
                                                />
                                            </div>
                                            
                                        )
                                    }else if(field.itemType==='phone'){
                                        return(
                                            <div className='dtra-field' key={i}>
                                            <InputMask
                                                maskChar={null}
                                                mask="9999-9999-9999-9999"
                                                value={list_jawaban[org_index].data_org[i].answerText}
                                                onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}
                                            >
                                            {() =>
                                                <TextField
                                                    label={<>{field.text.id}</>}
                                                    color='primary'
                                                    variant='outlined'
                                                    size='small'
                                                    name={field.text.id}
                                                    className={classes.textField}
                                                />
                                            }
                                            </InputMask>
                                            </div>
                                        )
                                    }else if(field.itemType==='single'){
                                       return(
                                        <div key={i} >
                                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{field.text.id} <span style={{color:'red'}}>*</span></p>
                                            <RadioGroup value={list_jawaban[org_index].data_org[i].ratingId} onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)}  style={{marginBottom:8}} aria-label="gender" name="gender1"  >
                                                <div className='lps-flex'>
                                                    {field.options.map((dat,i)=>(
                                                        <>
                                                        <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} />
                                                        
                                                        </>
                                                    ))}
                                                
                                                </div>
                                            </RadioGroup>
                                        </div>
                                       )
                                    }else if(field.itemType==='singleother'){
                                        return(
                                            <div key={i}>
                                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{field.text.id} <span style={{color:'red'}}>*</span></p>
                                                    <RadioGroup value={isOther[i]?list_jawaban[org_index].data_org[i].ratingId:parseInt(list_jawaban[org_index].data_org[i].answerText)}  aria-label="gender" name="gender1"  onChange={(e)=>onChangeOther(i,e.target.value,field.itemType,e.target.name)}>
                                                    {/* <div className='form-demografi ' > */}
                                                        <div className='lps-flex'>
                                                            {field.options.map((dat,i2)=>(
                                                                <div>
                                                                <FormControlLabel 
                                                                    key={i2}  
                                                                    className='radio-height'  
                                                                    value={dat.id} 
                                                                    name={dat.text.id} 
                                                                    control={<Radio color='primary' size='small'/>} 
                                                                    label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} 
                                                                />
                                                                {dat.text.en==='Other'&&
                                                                    <div className='form-80'>
                                                                        <TextField
                                                                            color='primary'
                                                                            variant='outlined'
                                                                            size='small'
                                                                            name='name'
                                                                            className={classes.textField}
                                                                            onChange={(e)=>onChangeOther(i,e.target.value,'otherValue','')}
                                                                            value={isOther[i]?list_jawaban[org_index].data_org[i].answerText:''}
                                                                            disabled={renderOtherDisable(list_jawaban[org_index].data_org[i].ratingId,dat.id)}
                            
                                                                        />
                                                                    </div>
                                                                }
                                                                </div>
                                                            ))}
                                                        {/* </div> */}
                                                        
                                                    </div>
                                                </RadioGroup>
                                                <br/>
                                            </div>
                                        )
                                    }else if(field.itemType==='dropdown'){
                                        return(
                                            <div className='tdra-field'>
                                                <FormControl  variant="outlined" size="small" style={{marginTop:15}} className='add-proposal__field' >
                                                    <InputLabel  htmlFor="category">{field.text.id} <span style={{color:'red'}}>*</span></InputLabel>
                                                    <Select className={classes.textField}   onChange={(e)=>onChangeDemografi(i,e.target.value,field.itemType)} value={parseInt(list_jawaban[org_index].data_org[i].ratingId)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={120} style={{borderRadius:5}}>
                                                    {field.options.map((opt,i)=>(
                                                        <MenuItem key={i}  value={opt.id}>{opt.text.id}</MenuItem>

                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                })}
                                {/* <div className='tdra-field'>
                                    <TextField
                                        label={<>Nama </>}
                                        color='primary'
                                        variant='outlined'
                                        size='small'
                                        name='name'
                                        className={classes.textField}
                                        value={list_jawaban[0].data_org[0].answerText}
                                        onChange={(e)=>onChangeDemografi(0,e.target.value,detail_survey.items[0].itemType)}
                                        onFocus={(e)=>onSetActive(0)}
                                    />
                                    <InputMask
                                        maskChar={null}
                                        mask="9999-9999-9999-9999"
                                        onFocus={(e)=>onSetActive(1)}
                                        onChange={(e)=>onChangeDemografi(4,e.target.value,detail_survey.items[4].itemType)}
                                        value={list_jawaban[0].data_org[4].answerText}
                                    >
                                    {() =>
                                        <TextField
                                            label={<>Nomor telepon </>}
                                            color='primary'
                                            variant='outlined'
                                            size='small'
                                            name='name'
                                            className={classes.textField}
                                            // value={list_jawaban[0].data_org[4].answerText}
                                            // onChange={(e)=>onChangeDemografi(4,e.target.value,detail_survey.items[4].itemType)}
                                        />
                                    }
                                    </InputMask>
                                    
                                    <TextField
                                        label={<>Email </>}
                                        color='primary'
                                        variant='outlined'
                                        size='small'
                                        name='name'
                                        className={classes.textField}
                                        value={list_jawaban[0].data_org[5].answerText}
                                        onChange={(e)=>onChangeDemografi(5,e.target.value,detail_survey.items[5].itemType)}
                                        onFocus={(e)=>onSetActive(2)}
                                    />
                                </div>

                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>Divisi Bapak/Ibu saat ini</p>
                            <RadioGroup onFocus={(e)=>onSetActive(3)} aria-label="gender" name="gender1"  value={parseInt(isOther?list_jawaban[0].data_org[1].ratingId:list_jawaban[0].data_org[1].answerText)} onChange={(e)=>onChangeDemografi(1,e.target.value,detail_survey.items[1].itemType,e.target.name)}>
                                <div className='form-demografi ' >
                                    <div className='form-50' style={{flexDirection:'column',display:'flex'}}>
                                        <FormControlLabel name={detail_survey.items[1].options[0].text.id} style={{height:30}} value={detail_survey.items[1].options[0].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[0].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[1].text.id} style={{height:30}}   value={detail_survey.items[1].options[1].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[1].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[2].text.id} style={{height:30}}   value={detail_survey.items[1].options[2].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[2].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[7].text.id} style={{height:30}}   value={detail_survey.items[1].options[7].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[7].text.id}</p>} />
                                        <div className='form-80'>
                                        <TextField
                                            color='primary'
                                            variant='outlined'
                                            size='small'
                                            name='otherValue'
                                            className={classes.textField}
                                            value={isOther?list_jawaban[0].data_org[1].answerText:''}
                                            onChange={(e)=>onChangeDemografi(1,e.target.value,'otherValue')}
                                            disabled={renderOtherDisable(list_jawaban[0].data_org[1].ratingId,detail_survey.items[1].options[7].id)}

                                        />
                                        </div>
                                    </div>
                                    <div style={{flexDirection:'column',display:'flex'}}>
                                        <FormControlLabel name={detail_survey.items[1].options[3].text.id} style={{height:30}}value={detail_survey.items[1].options[3].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[3].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[4].text.id} style={{height:30}}value={detail_survey.items[1].options[4].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[4].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[5].text.id} style={{height:30}}value={detail_survey.items[1].options[5].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[5].text.id}</p>} />
                                        <FormControlLabel name={detail_survey.items[1].options[6].text.id} style={{height:30}}value={detail_survey.items[1].options[6].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{detail_survey.items[1].options[6].text.id}</p>} />
                                    </div>
                                </div>
                            </RadioGroup>
                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>Jumlah pegawai di perusahaan Bapak/Ibu</p>
                            <RadioGroup onFocus={(e)=>onSetActive(4)} aria-label="gender" name="gender1" value={parseInt(list_jawaban[0].data_org[2].answerText)} onChange={(e)=>onChangeDemografi(2,e.target.value,detail_survey.items[2].itemType)}>
                                <div className='form-demografi'>
                                    {detail_survey.items[2].options.map((opt,i)=>(
                                        <FormControlLabel key={i} value={opt.id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{opt.text.id}</p>} />

                                    ))}
                                </div>
                            </RadioGroup>
                            <br/>
                            <div className='tdra-field'>
                                <FormControl  variant="outlined" size="small" className='add-proposal__field' >
                                    <InputLabel  htmlFor="category">Sektor industry</InputLabel>
                                    <Select onFocus={(e)=>onSetActive(5)} placeholder='Perusahaan Bapak/Ibu termasuk dalam sektor industri'  value={parseInt(list_jawaban[0].data_org[3].ratingId)} onChange={(e)=>onChangeDemografi(3,e.target.value,detail_survey.items[3].itemType)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={120} style={{borderRadius:5}}>
                                    {detail_survey.items[3].options.map((opt,i)=>(
                                        <MenuItem key={i}  value={opt.id}>{opt.text.id}</MenuItem>

                                    ))}
                                    </Select>
                                </FormControl>
                            </div> */}
                            {/* <div className=' hide-mobile'> */}
                            {/* {detail_survey.items.map((data,i)=>{
                                if(data.itemType==='textfield'||data.itemType==='phone'||data.itemType==='email'){
                                    return(
                                        <div className='tdra-field'>
                                            
                                        </div>
                                    )
                                }
                                if(data.itemType==='singleother'){
                                    return(
                                        <div>
                                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{data.text.id}</p>
                                            <RadioGroup onFocus={(e)=>onSetActive(i)} aria-label="gender" name="gender1"  value={parseInt(list_jawaban[0].data_org[i].answerText)} onChange={(e)=>onChangeDemografi(i,e.target.value,)}>
                                                <div className='form-demografi ' >
                                                    <div className='form-50' style={{flexDirection:'column',display:'flex'}}>
                                                        <FormControlLabel style={{height:30}} value={data.options[0].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[0].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}   value={data.options[1].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[1].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}   value={data.options[2].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[2].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}   value={data.options[7].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[7].text.id}</p>} />
                                                        <div className='form-80'>
                                                        <TextField
                                                            color='primary'
                                                            variant='outlined'
                                                            size='small'
                                                            name='name'
                                                            className={classes.textField}
                                                            onChange={(e)=>null}
                                                            disabled={renderOtherDisable(list_jawaban[0].data_org[i].answerText,data.options[7].id)}

                                                        />
                                                        </div>
                                                    </div>
                                                    <div style={{flexDirection:'column',display:'flex'}}>
                                                        <FormControlLabel style={{height:30}}value={data.options[3].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[3].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}value={data.options[4].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[4].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}value={data.options[5].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[5].text.id}</p>} />
                                                        <FormControlLabel style={{height:30}}value={data.options[6].id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{data.options[6].text.id}</p>} />
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    )
                                }
                                if(data.itemType==='single'){
                                    return(
                                        <div>
                                            <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{data.text.id}</p>
                                            <RadioGroup onFocus={(e)=>onSetActive(i)} aria-label="gender" name="gender1" value={parseInt(list_jawaban[0].data_org[i].answerText)} onChange={(e)=>onChangeDemografi(i,e.target.value,)}>
                                                <div className='form-demografi'>
                                                    {data.options.map((opt,i)=>(
                                                        <FormControlLabel key={i} value={opt.id} control={<Radio color='primary' size='small'/>} label={<p style={{fontSize:12,color:'#777777'}}>{opt.text.id}</p>} />

                                                    ))}
                                                </div>
                                            </RadioGroup>
                                            <br/>
                                        </div>
                                    )
                                }
                                if(data.itemType==='dropdown'){
                                    return(
                                        <div className='tdra-field'>
                                            <FormControl  variant="outlined" size="small" className='add-proposal__field' >
                                                <InputLabel  htmlFor="category">{data.text.id}</InputLabel>
                                                <Select onFocus={(e)=>onSetActive(i)} placeholder='Perusahaan Bapak/Ibu termasuk dalam sektor industri'  value={parseInt(list_jawaban[0].data_org[i].answerText)} onChange={(e)=>onChangeDemografi(i,e.target.value,)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={120} style={{borderRadius:5}}>
                                                {data.options.map((opt,i)=>(
                                                    <MenuItem key={i}  value={opt.id}>{opt.text.id}</MenuItem>

                                                ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )
                                }
                            })} */}
                                
                            </div>
                            </div>
                            <div className='div-flex div-justify-between' style={{padding:25}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                <Button disabled={handleDisable2()} onClick={next} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Selanjutnya</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}

const Form=({next,back,detail_survey,onChangeDataOrg,onSubmit,handleDisable2})=>{
    const classes=useStyles()
    
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div style={{width:'100%'}}>
                            <div style={{padding:'20px 20px 0px 20px'}}>
                                <p>{detail_survey.intro.id}</p>
                                <br/>
                                
                            </div>
                            <div className='kolom-isi2 '>
                                <div className='field-kolom'>
                                {detail_survey.items.map((data,i)=>{
                                    if(data.itemType==='textfield'||data.itemType==='email'){
                                        return(
                                            <TextField
                                                key={i}
                                                label={<>{data.text.id} <span style={{color:'red'}}>*</span></>}
                                                color='primary'
                                                variant='outlined'
                                                size='small'
                                                name='name'
                                                className={classes.textField}
                                                onChange={(e)=>onChangeDataOrg(i,e.target.value)}
                                            />
                                        )
                                    }
                                    if(data.itemType==='dropdown'){
                                        return(
                                            <FormControl key={i} variant="outlined" size="small" className='add-proposal__field' >
                                                <InputLabel  htmlFor="category">{data.text.id} <span style={{color:'red'}}>*</span></InputLabel>
                                                <Select  onChange={(e)=>onChangeDataOrg(i,e.target.value)}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={60} className='field-radius'>
                                                    {data.options.map((option,i)=>(
                                                        <MenuItem key={i} value={option.id}>{option.text.id}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )
                                    }
                                })}
                                </div>
                            </div>
                            
                            <div className='div-flex div-justify-between' style={{padding:20}} >
                                <Button onClick={back} style={{width:150}} color='primary' variant='outlined' size='medium' className='btn-remove-capital btn-rounded'>Kembali</Button>
                                <Button disabled={handleDisable2()} onClick={onSubmit} style={{width:150}} color='primary' variant='contained' size='medium' className='btn-remove-capital btn-rounded'>Lihat hasil</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}

const Result=({result})=>{
    const renderImg=(id)=>{
        switch (id) {
            case 1:
                return Kurang
            case 2:
                return Cukup
            case 3:
                return Baik
            case 4:
                return SangatBaik
            case 5:
                return SangatBaik
            default:
                break;
        }
    }
    return(
        <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <img className='hide-mobile' src={Takenote} style={{width:'80%'}}/>
                        <div className='bg-pertanyaan-mobile' >
                            <img   src={shape_3} style={{width:'40%'}}/>
                            <img   src={shape_2} style={{width:'45%',marginTop:50,marginRight:0}}/>
                        </div>
                        
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                        <div className='result-wrapp' style={{width:'100%',textAlign:'center'}}>
                            <div className='field-kolom'>
                            <img src={renderImg(result!==null&&result.id)} style={{width:200}}/>
                            <br/>
                                <h2>{result!==null&&result.text.id}</h2>
                            <br/>
                            <p><b>Terimasih sudah berpartisipasi dalam survey Digital Transformation Readiness Assessment . Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.</b></p>
                            <br/>
                            <div className='div-flex div-align-center div-justify-center'>
                                <p><b>Provided by:</b></p>
                                &nbsp;&nbsp;
                                <img src={Logo} onClick={()=>window.location.assign('https://www.gmlperformance.com/')} style={{width:90,cursor:'pointer'}}/>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}
export default function Index() {
    const dispatch=useDispatch()
    const audit = useSelector(state => state.audit)
    const [field,setField]=useState('')
    const[answer,setAnswer]=useState(null)
    const[survey_id,setSurveyId]=useState(null)
    const [tab,setTab]=useState('assestment')
    const [result,setResult]=useState(null)
    const [isOther,setIsOther]=useState(false)
    const {detail_survey,active_question,list_jawaban}=audit
    useEffect(()=>{
        akhlakGet()
    },[])
    let org_index=0
    const akhlakGet=async ()=>{
        let res=await dispatch(getAkhlak())
        if(res){
            setSurveyId(res.data.id)
            // console.log('res.data', res.data)
           
            let res2=await dispatch(getDetailSurvey3(res.data.id))
            console.log('res2', res2)
            if(res2){
                let data=[
                    {
                        itemType:'intro',
                        
                       ...res.data
        
                    },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                    // {
                    //     itemType:'varied',
                        
                    //    ...res2[0]
        
                    // },
                ]
                dispatch({
                    type:actionTypes.GET_DETAIL_SURVEY2,
                    payload:[...data,...res2]
                })
            }
        }
    }
    const findActiveJawaban2=()=>{
        let id_question=detail_survey[active_question]
        let jawaban=list_jawaban.filter((data)=>{
            return data.id===id_question.id
        })
        console.log('id_question', id_question)
        // console.log('jawaban,id_question', id_question,list_jawaban)
        return jawaban[0]
    }

    const onChangeDemografi=(index,value,type,name)=>{
        setField(value)
        if(type==='singleother'){

        }else if(type==='single'){
            list_jawaban[org_index].data_org[index].answerText=''
    
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
        }else if(type==='dropdown'){
            list_jawaban[org_index].data_org[index].ratingId=parseInt(value)

        }else{
            list_jawaban[org_index].data_org[index].answerText=value
        }
    }
    const onChangeOther=(index,value,type,name)=>{
        // console.log('value,type,name', value,type,name)
        setField(value)
        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther({...isOther,[index]:true})
                list_jawaban[org_index].data_org[index].answerText=''
    
                list_jawaban[org_index].data_org[index].ratingId=parseInt(value)
            }else{
    
                setIsOther({...isOther,[index]:false})

                list_jawaban[org_index].data_org[index].answerText=value
                list_jawaban[org_index].data_org[index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            list_jawaban[org_index].data_org[index].answerText=value

        }
        
    }
    // console.log('list_jawaban', list_jawaban)
    const onSetActive=(index)=>{
        // dispatch(setActiveQuestion(index+2))
    }
    const chooseAnswer=(id)=>{
        // console.log('id', id)
        let a=findActiveJawaban2(id).option=id
        dispatch(setJawaban([...list_jawaban]))
        setAnswer(id)

    }
    const next=()=>{
        setAnswer(null)
        dispatch(setActiveQuestion(active_question+1))
    }
    const back=()=>{
        dispatch(setActiveQuestion(active_question===0?0:active_question-1))
    }
    const handleDisable=()=>{
        if(active_question>1){
        if(list_jawaban.length-1!==active_question-1){
            // console.log('oyee',list_jawaban,active_question)
            if(list_jawaban[active_question-1].option!==null){
                return false
            }else{
                return true
            }
            
        }else{
            let filter=list_jawaban.filter((data)=>{
                return data.option===null
            })
            if(filter.length>0){
                return true
            }else{
                return false
            }
        }
    }
    // return false
    }
    console.log('list_jawaban', list_jawaban)
    const handleDisable2=()=>{
        let {data_org}=list_jawaban[org_index]
        var re = /\S+@\S+\.\S+/;
        let status=[]
        data_org.map((d,i)=>{
            if(d.type==='textfield'){
                status.push(d.answerText==='')
            }
            if(d.type==='email'){
                status.push(!re.test(d.answerText))
            }
            if(d.type==='single'||d.type==='dropdown'){
                status.push(d.ratingId===0)
            }
            if(d.type==='singleother'){
                if(isOther[i]){
                    status.push(d.ratingId===0||d.answerText==='')
                }else{
                    status.push(d.answerText==='')
                }
            }
        })
        let disable=status.includes(true)
        console.log(`disable`, disable,status,data_org)
        return disable

        // return false
        
    }
    // console.log('detail_survey', detail_survey)
    const onSubmit=async ()=>{
        let new_jawab=[]
        list_jawaban.map((data,i)=>{
            if(i!==0){
                new_jawab.push({id:0,surveyId:survey_id,itemId:data.itemId,ratingId:data.itemType==='ranking'?0:data.option,answerText:''})

            }else{
                let new_org=[]
                data.data_org.map((org)=>{
                    new_org.push({...org,ratingId:parseInt(org.ratingId)})
                })
                console.log('data', data)
                new_jawab.push(...new_org)
            }
        })
        let new_new_jawaban={
            surveyId:survey_id,
            uuid:'',
            responses:new_jawab
        }
        // console.log('new_new_jawaban', new_new_jawaban)
        let res=await dispatch(postAudit(new_new_jawaban))
        if(res){
            // console.log('res.data', res.data)
            window.location.assign(`/akhlak/report/${survey_id}/${res.data.text.id}`)
            // dispatch(getDataReport(`/${survey_id}/${res.data.text.id}`))
            // props.tabToggle('result','question')
            // setTab('result')
            // setResult(res.data)
        }

    }
    // console.log('detail_survey', detail_survey)
    return (
        <div>
            <Loader/>
            {tab==='assestment'?
            <>
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='intro'&&
                <Welcome 
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='single'&&
                <Pertanyaan
                    
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    onSubmit={onSubmit}
                    survey_length={detail_survey.length}
                />
            }
            { audit.detail_survey!==null&&detail_survey[active_question].itemType==='varied'&&
                <Demografi
                    detail_survey={detail_survey[active_question]}
                    next={next}
                    back={back}
                    chooseAnswer={chooseAnswer}
                    findActiveJawaban2={findActiveJawaban2}
                    active_question={active_question}
                    handleDisable={handleDisable}
                    handleDisable2={handleDisable2}
                    onChangeDemografi={onChangeDemografi}
                    list_jawaban={list_jawaban}
                    onSetActive={onSetActive}
                    isOther={isOther}
                    onChangeOther={onChangeOther}
                    org_index={org_index}
                />
            }
            </>
            :
                <Result result={result}/>
            }
        </div>
    )
}
