import React,{useEffect,useState,useRef} from 'react'
import './style.css'

import Logo1 from '../../../assets/image/logo.png'
import Logo2 from '../../../assets/image/logo_report_2.png'
import Map from '../../../assets/image/map.svg'
import report_1 from '../../../assets/image/report/report_1.jpg'
import report_2 from '../../../assets/image/report/report_2.jpg'
import report_3 from '../../../assets/image/report/report_3.jpg'
import report_4 from '../../../assets/image/report/report_4.jpg'
import report_5 from '../../../assets/image/report/report_5.jpg'
import report_6 from '../../../assets/image/report/report_6.jpg'
import report_7 from '../../../assets/image/report/report_7.jpg'
import report_8 from '../../../assets/image/report/report_8.jpg'
import report_9 from '../../../assets/image/report/report_9.jpg'
import report_10 from '../../../assets/image/report/report_10.jpg'
import report_11 from '../../../assets/image/report/report_11.jpg'
import report_12 from '../../../assets/image/report/report_12.jpg'
import report_13 from '../../../assets/image/report/report_13.jpg'
import report_14 from '../../../assets/image/report/report_14.jpg'
import report_15 from '../../../assets/image/report/report_15.jpg'
import report_16 from '../../../assets/image/report/report_16.jpg'
import report_17 from '../../../assets/image/report/report_17.jpg'
import report_18 from '../../../assets/image/report/report_18.jpg'
import report_od from '../../../assets/image/report/report_od.png'

import Check from '../../../assets/image/Check.svg'
import Close from '../../../assets/image/Close_red.svg'
import Loader from '../../../components/Loading'

import ChevronGrey from '../../../assets/image/chevron_grey.svg'
import {getKepemimpinanResult} from '../../../redux/action/audit'
import {useDispatch,useSelector} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import {Helmet} from "react-helmet";
import {Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {Chart} from 'chart.js'
import {isMobile} from 'react-device-detect'
import ChartDataLabels from 'chartjs-plugin-datalabels';

function Report(props) {
    const dispatch=useDispatch()
    const audit=useSelector(state=>state.audit)
    const general=useSelector(state=>state.general)
    let init=[false,false,false,false,false]
    const [loaders,setLoaded]=useState(init)
    const [dimensions,setDimensions]=useState([])
    const {report}=audit
    const canvas = useRef(null);
    useEffect(() => {
        getReport()
      },[]);
    const getReport=async ()=>{
        let {id,uuid}=props.match.params
        let res=await  dispatch(getKepemimpinanResult(`/${id}/${uuid}`))
        if(res){
            let new_labels=[]
            let new_dataset=[]
            let new_dimension=[]
            res.data.dimensions.map((data)=>{
                new_labels.push(data.title)
                new_dataset.push(data.score.toFixed(2))
                let new_data=[]
                let new_categories=[]
                data.indicators.map((dim)=>{
                    let word
                    let wordsarray=dim.indicator.split(' ')
                    if(wordsarray.length>2){
                        word=[`${wordsarray[0]} ${wordsarray[1]}`,wordsarray[2]]
                    }else if(wordsarray.length==2){
                        word=[`${wordsarray[0]} ${wordsarray[1]}`]
                    }else{
                        word=wordsarray
                    }
                    new_categories.push(word)
                    new_data.push(dim.value)
                })
                let new_options={
                    series: [{
                        data: new_data
                    }],
                    chart: {
                        type: 'bar',
                        height: 100,
                        toolbar: {
                            show: false
                          }
                    },
                    plotOptions: {
                        bar: {
                          horizontal: true,
                          barHeight:'80%'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis:{
                     
                        labels:{
                            minWidth: 0,
                            maxWidth: 350,
                            style: {
                                width:50,
                                colors: [],
                                fontSize: '12px',
                                fontFamily: 'Lato, sans-serif',
                                fontWeight: 'bold',
                                // cssClass: 'apexcharts-xaxis-label',
                            },
                            
                        }
                    },
                    xaxis: {
                        categories: new_categories,
                    }
                }
                new_dimension.push({...data,options:new_options})
            })
            setDimensions(new_dimension)
            const cfg = {
                plugins: [ChartDataLabels],

                type: 'radar',
                data: {
                    // labels: new_labels,
                    labels: ["Integrity",'Enthusiasthic',['Creativity &','Innovation'],['Building ','Partnership'],['Business' , 'Acumen'],['Customer','Focus'],['Driving','Execution'],['Visionary','Leadership'],['Aligning','Performance','for Success'],'Empowering',['Change','Leadership'],['Strategic','Orientation']],
                    datasets: [
                        {
                            data: new_dataset,
                            borderColor:'#80b5fd',
                            backgroundColor:'rgba(188, 216, 255, 0.5)'
                        },
                        // {
                        //     data: [3.5,3.5,3.5,3.5,3.5,3.5,3.5,3.5,3.5,3.5,3.5,3.5],
                        //     borderColor:'#eddb00',
                        //     backgroundColor:'rgba(237, 219, 0, 0.25)'
                        // },
                    ],
                    
                },
        
                options: {
                    responsive:false,
                    legend: {
                        display: false
                    },
                    
                    maintainAspectRatio: false,
                    scale: {
                        ticks: {
                          display:false,
                          beginAtZero: true,
                          maxTicksLimit:8
                        },
                        pointLabels :{
                            fontStyle: "bold",
                            fontSize:16
                         },
                         
                      },
                      plugins: {
                        datalabels: {
                          formatter: function(value) {
                            // console.log('value', value)
                            return value;
                          },
                          font: {
                            // weight: 'bold',
                            size: 14,
                          },
                          offset: 30,
                          padding: 10,
                          fontSize:20
                        }
                      },
                    elements: {
                        point:{
                            radius: 0
                        }
                    }
                }
            };
            const chart = new Chart(canvas.current.getContext('2d'), cfg);
            chart.update();
        }
        
    }
    let imagecover=[
        report_1,
        report_13,
        report_17,
        report_18,
    ]
    console.log('dimensions', dimensions)
    let oo=[]
    const setonLoaded=(index)=>{
        
            let b=[false,true]
            
            
            let c=init.filter((data)=>{
                return data===false
            })
            console.log('index', index,oo.length)
            if(oo.length<4){

                oo.push(index)
            }else{
                oo=[]
                setTimeout(()=>{
                    window.print()
                },5000)
            }
            

    }
    console.log('report', report)
    return (
        <>
        <Loader/>
        {report!==null&&
        <div className='report-wrapper' >
            <Helmet>
                <title>Report-Kepemimpinan</title>
                
            </Helmet>
            <div className='report-header'>
                <div className='report-shape'></div>
                <div className='div-margin'>
                    <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                </div>
            </div>
            <div className='report-footer'>
                <div className='div-margin'>
                    <p>Report date :  {moment(report.surveyDate).format('DD MMM YYYY')}</p>
                </div>
            </div>
            <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">
                        <div className='div-flex div-margin'>
                            {imagecover.map((data,index)=>(
                                <div style={{width:140,height:140}}>
                                <img src={data} onLoad={()=>setonLoaded(index+1)} style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            ))}
                        </div>
                        <br/><br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>{report.title}</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <br/>
                        <div className='report-information div-margin'>
                            <p className='text-bold'>Respondent Information</p>
                            {report.cover.map((data,i)=>(
                                <div className='div-flex'>
                                    <p style={{width:250}}>{data.indicator}</p>
                                    <p>:&nbsp;{data.text}</p>
                                </div>
                            ))} 
                        </div>
                        <br/><br/>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        {/* page1 */}
                        <br/><br/>
                        <div className='report-shape3'>
                            <h1>Hasil Survey Kepemimpinan Berbasis Kompetensi</h1>
                        </div>
                        <br/>
                        <div className='div-margin '>
                            
                            <br/>
                            <div className='radar-kepemimpinan-wrapper' >
                                <div className="chartjs-wrapper kepemimpinan-radar" ><canvas ref={canvas} className="chartjs chartsj" style={{width:'100%'}}></canvas></div>
                            </div>
                            
                        </div>
                       
                        
                        
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
        </div>
         } 
        </>
    )
}
export default withRouter(Report)