import React,{useState,useEffect} from 'react'
import shape_1 from '../../../assets/image/tdrc/shape_1.svg'
import shape_2 from '../../../assets/image/tdrc/shape_2.svg'
import shape_3 from '../../../assets/image/tdrc/shape_3.svg'
import shape_4 from '../../../assets/image/tdrc/shape_4.svg'
import moment from 'moment'

import {TextField,Button,Tooltip ,Collapse,FormControlLabel ,Checkbox, FormControl } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import Plus from '../../../assets/image/Plus.svg'
import MomentUtils from '@date-io/moment';
import {
    TimePicker,
    DatePicker ,
    MuiPickersUtilsProvider
  } from '@material-ui/pickers';
import { isMobile } from 'react-device-detect'
import { getLinkEdtra,getNewDtra } from '../../../redux/action/audit'
import { useDispatch,useSelector } from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from '../../../components/Loading'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#f4ab08',
            contrastText: '#FFFFFF',
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 8,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
const ListLink=()=>{
    const classes=useStyles()
    const dispatch = useDispatch()
    const [link, setlink] = useState([])
    const [detail_survey, setdetail_survey] = useState(null)
    const [open,setOpen]=useState({0:false})
    const audit = useSelector(state => state.audit)
    useEffect(() => {
        getLink()
    }, [])
    const getLink=async ()=>{
        let getId=await dispatch(getNewDtra())
        if(getId){
            setdetail_survey(getId.data)
            let res=await dispatch(getLinkEdtra(getId.data.id))
            if(res){
                setlink(res.data.groups)
            }
        }
        
    }
    const onClick=async ()=>{
        
    }
    const handleTooltipOpen =async (i) => {
        await setOpen({...open,[i]:true});
        setTimeout(()=>setOpen({...open,[i]:false}),3000)
    };
    const renderCopied=(i)=>{
        if(open[i]){
            return 'Copied'
        }else{
            return 'Copy'
        }
    }
    console.log(`link`, link)
    return(
        <div>
            <Loader/>

        <div className='welcome-wrapper'>
            {link.length>0&&<>
                <div className='welcome-text'>
                    <div className='welcome-mobile'>
                        <h2>Selamat datang, </h2>
                        <p>Anda sedang menjalani proses <b>{detail_survey.title}</b></p>
                    </div>
                </div>
                <div className='welcome-example'>
        
                    <div style={{position:'relative'}}>
                        <img style={{top:-35,left:-35}} className='shape-img' src={shape_1}/>
                        <img style={{top:-100,right:-30,width:150}} className='shape-img' src={shape_2}/>
                        <img style={{top:180,left:-130,width:150}} className='shape-img' src={shape_3}/>
                        <img style={{bottom:-80,left:300,width:150}} className='shape-img' src={shape_4}/>
                        <img style={{bottom:-60,right:0}} className='shape-img' src={shape_1}/>
                        <div className='card-example' >
                            <div className='create-link-wrapper' style={{padding:20}} >
                            <h2 >Bagikan link di bawah ke partisipan survey sesuai kelompok</h2>
                            {!isMobile&&link.map((data,i)=>(
                                <div key={i}>
                                <p style={{fontSize:14,color:'#777777',fontWeight:"bold",margin:'20px 0px 20px 0px'}} >Link untuk {data.text} berlaku sampai {moment(data.time).format("D MMMM YYYY")}</p>
                                <div className='div-flex div-align-center'>
                                    <div style={{width:'65%'}}>
                                    <TextField
                                        label={<>Link Untuk {data.text} </>}
                                        color='primary'
                                        variant='outlined'
                                        size='small'
                                        name='position'
                                        type='text'
                                        value={`${window.location.href}/${data.url}`}
                                        className={classes.textField}
                                        // onChange={(e)=>onChangeData(e)}
                                        multiline
                                    />
                                    </div>
                                    &nbsp;&nbsp;
                                    <CopyToClipboard text={`${window.location.href}/${data.url}`}
                                    onCopy={() => handleTooltipOpen(i)}>
                                        <Button   size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>{renderCopied(i)}</Button>
                                        
                                    </CopyToClipboard>
                                    &nbsp;
                                    <Button onClick={()=>window.open(`${window.location.href}/${data.url}`,'_blank')} size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Go to link</Button>
                                    &nbsp;
                                    <MuiThemeProvider theme={themeButton}>
                                    <Button onClick={()=>window.open(`/edtra/report/${data.url}`,'_blank')} size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Report</Button>
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            ))}
                            {isMobile&&link.map((data,i)=>(
                                <div key={i}>
                                <p style={{fontSize:14,color:'#777777',fontWeight:"bold",margin:'20px 0px 20px 0px'}} >Link untuk {data.text} berlaku sampai {moment(data.time).format("D MMMM YYYY")}</p>
                                <div className=''>
                                    <div style={{width:'100%'}}>
                                    <TextField
                                        label={<>Link untuk {data.text} </>}
                                        color='primary'
                                        variant='outlined'
                                        size='small'
                                        name='position'
                                        type='text'
                                        value={`${window.location.href}/${data.url}`}
                                        className={classes.textField}
                                        // onChange={(e)=>onChangeData(e)}
                                        multiline
                                    />
                                    </div>
                                    <div className='div-flex' style={{justifyContent:'flex-end'}}>
                                    <CopyToClipboard text={`${window.location.href}/${data.url}`}
                                    onCopy={() => handleTooltipOpen(i)}>
                                        <Button   size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>{renderCopied(i)}</Button>
                                    </CopyToClipboard>
                                    &nbsp;
                                    <Button onClick={()=>window.open(`${window.location.href}/${data.url}`,'_blank')} size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Go to link</Button>
                                    &nbsp;
                                    <MuiThemeProvider theme={themeButton}>
                                    <Button onClick={()=>window.open(`/edtra/report/${data.url}`,'_blank')} size='small' variant='text' color='primary' className='remove-capital' style={{fontWeight:"bold"}}>Report</Button>
                                    </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                            ))}
                            <br/>
                            <div className='create-link-footer' >
                                <p >Hasil dari Employee Engagement & Digital Transformation Readiness Assessment akan disampaikan oleh perwakilan dari pihak kami setelah masa berlaku pengisian.</p>
                                {/* <Button  onClick={onClick}  style={{width:120,fontWeight:'bold'}} color='primary' variant="contained" className='remove-capital btn-rounded'>Selanjutnya</Button> */}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>}
            </div>
        </div>

    )
}
export default ListLink