import * as actionTypes from "../constant/audit";
import {apiCall} from '../../service/apiCall'
import {setLoading,setLoading2} from './general'
import {get,groupBy} from 'lodash'
import { URL, USER,PASS } from '../../service/base_url'
export const setActiveQuestion=(index)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ACTIVE_QUESTION,
        payload:index
    })
}
export const setActiveSurvey=(index)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ACTIVE_SURVEY,
        payload:index
    })
}
export const setActiveLang=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ACTIVE_LANGUAGE,
        payload:data
    })
}
export const setJawaban=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ANSWER,
        payload:data
    })
}

export const getListSurvey=(slug='')=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Survey/list/1`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        dispatch({
            type:actionTypes.GET_LIST_SURVEY,
            payload:res.data
        })
        return res
    }else{
        dispatch(setLoading(false))

       
    }
}
export const checkSurvey=(uuid)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
    url:`/Survey/uuid/${uuid}`,
    method:'GET',
    data:{
        auth:{username:USER,password:PASS}
    }

    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')===200){
      dispatch(setLoading2(false))

      dispatch({
        type:actionTypes.SET_GROUP,
        payload:res.data.group
      })
      return true
    }else{
      return false
    }
    // // console.log('re', re)
}
export const getDetailSurvey=(id)=>async dispatch=>{
    dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/${id}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        let jawaban=[]
        let new_detail=[]
        res.data.map((data,i)=>{
            if(i!==0&&i!==res.data.length-1){
              if(data.itemType==='ranking'){
                data.items.map((item)=>{
                  jawaban.push({itemType:data.itemType,slug:`${data.itemType}-${item.id}`,id:data.id,itemId:item.id,option:null,active:false,title:item.title,text:item.text})
                })
              }else{
                jawaban.push({itemType:data.itemType,id:data.id,itemId:data.items[0].id,option:null,active:false})

              }
            }else if(i===res.data.length-1){
                let data_org=[]

                data.items.map((org,i)=>{
                    // console.log('org', org)
                    data_org.push({
                        "id": 0,
                        "itemId": org.id,
                        "ratingId": 0,
                        "answerText": "",
                        type:org.itemType
                    })
                })
                jawaban.push({data_org:data_org})
                
            }
        })
        res.data.map((data,i)=>{
            new_detail.push({...data,active:i===0?true:false})
        })
        // console.log('jawaban', jawaban)
        dispatch(setLoading2(false))
        // console.log('jawaban', jawaban)
        await dispatch({
            type:actionTypes.GET_DETAIL_SURVEY,
            payload:{
                detail_survey:new_detail,
                list_jawaban:jawaban
            }
        })
        return new_detail
    }else{
        dispatch(setLoading2(false))

       
    }
}
export const getDetailEdtra=(id)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/${id}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      let jawaban=[]
      res.data.map((data,i)=>{
          if(data.itemType==='varied'){
              let data_org=[]
              data.items.map((org,i)=>{
                  data_org.push({
                    id:0,
                    itemId:org.id,
                    ratingId:0,
                    answerText:"",
                    type:org.itemType
                  })
              })
              jawaban.push({data_org:data_org})
          }else{
              if(data.itemType==='ranking'){
                  data.items.map((item)=>{
                    jawaban.push({itemType:data.itemType,slug:`${data.itemType}-${item.id}`,id:data.id,itemId:item.id,option:null,active:false,title:item.title,text:item.text})
                  })
              }else{
                  if(data.itemType!==""){
                    jawaban.push({itemType:data.itemType,id:data.id,itemId:data.items[0].id,option:null,active:false})
                  }else{
                    jawaban.push({itemType:"intro",id:data.id,itemId:0,option:null,active:false})

                  }

              }
          }
      })
      dispatch({
        type:actionTypes.GET_DETAIL_SURVEY,
        payload:{
            detail_survey:res.data,
            list_jawaban:jawaban,
            total_question:res.data.length
        }
    })
      return res.data
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getReportEdtra=(slug)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/report/edtra${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      
      return res.data
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getDetailSurvey2=(id)=>async dispatch=>{
    dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/${id}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        let jawaban=[]
        let new_detail=[]
        res.data.map((data,i)=>{
            if(i!==res.data.length-1){
              if(data.itemType==='ranking'){
                data.items.map((item)=>{
                  jawaban.push({itemType:data.itemType,slug:`${data.itemType}-${item.id}`,id:data.id,itemId:item.id,option:null,active:false,title:item.title,text:item.text})
                })
              }else{
                jawaban.push({itemType:data.itemType,id:data.id,itemId:data.items[0].id,option:null,active:false})

              }
            }else if(i===res.data.length-1){
                let data_org=[]
                data.items.map((org,i)=>{
                    // console.log('org', org)
                    data_org.push({
                        "id": 0,
                        "itemId": org.id,
                        
                        "ratingId": 0,
                        "answerText": ""
                    })
                })
                jawaban.push({data_org:data_org})
                
            }
        })
        res.data.map((data,i)=>{
            new_detail.push({...data,active:i===0?true:false})
        })
        dispatch(setLoading2(false))
        await dispatch({
            type:actionTypes.GET_DETAIL_SURVEY,
            payload:{
                detail_survey:new_detail,
                list_jawaban:jawaban
            }
        })
        return new_detail
    }else{
        dispatch(setLoading2(false))

       
    }
}
export const getDetailSurvey3=(id)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/${id}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      let jawaban=[]
      let new_detail=[]
      res.data.map((data,i)=>{
          
            
            if(data.itemType==='varied'){
              let data_org=[]

              data.items.map((org,i)=>{
                  // console.log('org', org)
                  data_org.push({
                      "id": 0,
                      "itemId": org.id,
                      
                      "ratingId": 0,
                      "answerText": "",
                      type:org.itemType

                  })
              })
             jawaban.push({data_org:data_org})
            }else{
              jawaban.push({itemType:data.itemType,id:data.id,itemId:data.items[0].id,option:null,active:false})

            }
         
      })
      res.data.map((data,i)=>{
          new_detail.push({...data,active:i===0?true:false})
      })
      // console.log('jawaban', jawaban)
      dispatch(setLoading2(false))
      // console.log('jawaban', jawaban)
      await dispatch({
          type:actionTypes.GET_DETAIL_SURVEY,
          payload:{
              detail_survey:new_detail,
              list_jawaban:jawaban
          }
      })

      return new_detail
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getSpecialAudit=(slug)=>async dispatch=>{
  dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/name/${slug}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        return res
    }else{
        dispatch(setLoading2(false))
    }
}
export const getDtra=()=>async dispatch=>{
  dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/name/digital`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        return res
    }else{
        dispatch(setLoading2(false))
    }
}
export const getNewDtra=()=>async dispatch=>{
  dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/name/engagement`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        return res
    }else{
        dispatch(setLoading2(false))
    }
}
export const getLps=()=>async dispatch=>{
  dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/name/lps`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        return res
    }else{
        dispatch(setLoading2(false))
    }
}
export const getAkhlak=()=>async dispatch=>{
  dispatch(setLoading2(true))
    let dataReq={
        url:`/survey/name/akhlak`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        return res
    }else{
        dispatch(setLoading2(false))
    }
}
export const setDetailSurvey=(data)=>dispatch=>{
  dispatch({
    type:actionTypes.GET_DETAIL_SURVEY,
    payload:data
})
}
export const selectAnswer=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ANSWER,
        payload:data
    })
}
export const postAudit=(data)=>async dispatch=>{
    dispatch(setLoading2(true))
    let dataReq={
        url:`/survey`,
        method:'POST',
        data:{
            data,
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        dispatch({
            type:actionTypes.SET_MESSAGE,
            payload:res.data
        })
        return res
    }else{
        dispatch(setLoading2(false))

       
    }
}
export const getDataReport=(slug='/1/65725b45-0656-4872-8877-4acfbc0bd189')=>async dispatch=>{
    dispatch(setLoading2(true))
    let dataReq={
        url:`/Survey/report${slug}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading2(false))
        
        
        if(res.data.surveyId==1){
          let chart={
            series: [{
                data: []
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 10
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                      barHeight:70,
                      colors: {
                        ranges: [
                            {
                                from: 10,
                                to: 199,
                                color: '#ed4900'
                            },
                            {
                                from: 200,
                                to: 299,
                                color: '#00ed64'
                            },
                            {
                                from: 300,
                                to: 400,
                                color: '#00a1ed'
                            },
                        ],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                  },
                },
                dataLabels: {
                  enabled: false
                },
                yaxis:{
                   
                    labels:{
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 760,
                        style: {
                            colors: [],
                            fontSize: '12px',
                            fontFamily: 'Lato, sans-serif',
                            fontWeight: 'bold',
                            // cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    }
                },
                xaxis: {
                  categories: [],
                  min:10,
                  max:550,
                  labels:{
                    formatter: function (value) {
                      switch (value) {
                        case 64:
                            return 'Poor'
                          case 172:
                            return 'Fair'
                          case 280:
                            return 'Good'
                          case 388:
                            return 'Very Good'
                          case 496:
                            return 'Excelent'
                          default:
                            break;
                    }
                      
                    },
                    
                    style: {
                        colors: [],
                        fontSize: '14px',
                        fontFamily: 'Lato, sans-serif',
                        fontWeight: 'bold',
                        // cssClass: 'apexcharts-xaxis-label',
                    },
                    minHeight: 80,
                  }
                },
                
              },
            }
            res.data.dimensions.map((data,i)=>{
              if(data.indicators.length>0){
                chart.series[0].data.push(data.indicators[0].value*100)
              }
            })
            res.data.dimensions.map((data,i)=>{
                chart.options.xaxis.categories.push(data.title)
            })
            dispatch({
              type:actionTypes.GET_DATA_REPORT,
              payload:{...res.data,chart:chart}
            })
        }
        else if(res.data.surveyId==2){
          let chart={
            series: [{
                name: 'Inflation',
                data: []
              }],
              options: {
                chart: {
                  height: 350,
                  type: 'bar',
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      position: 'top', // top, center, bottom
                    },
                  }
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (val) {
                    return val + "%";
                  },
                  offsetY: -20,
                  style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                  }
                },
                
                xaxis: {
                  categories: [],
                  position: 'bottom',
                  axisBorder: {
                    show: false
                  },
                  axisTicks: {
                    show: false
                  },
                  crosshairs: {
                    fill: {
                      type: 'gradient',
                      gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                      }
                    }
                  },
                  tooltip: {
                    enabled: true,
                  }
                },
                yaxis: {
                  axisBorder: {
                    show: false
                  },
                  axisTicks: {
                    show: false,
                  },
                  labels: {
                    show: false,
                    formatter: function (val) {
                      return val + "%";
                    }
                  }
                
                },
              },
          }
          if(res.data.summary.indicators!==null){
            res.data.summary.indicators.map((data,i)=>{
                chart.series[0].data.push(data.value)
            })
            res.data.summary.indicators.map((data,i)=>{
                chart.options.xaxis.categories.push(data.indicator)
            })
          }
          dispatch({
            type:actionTypes.GET_DATA_REPORT,
            payload:{...res.data,chart:chart}
          })
        }
        else if(res.data.surveyId===3){
          let chart={
            series: [{
                data: []
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 10
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                      barHeight:70,
                      colors: {
                        ranges: [
                            {
                                from: 10,
                                to: 199,
                                color: '#ed4900'
                            },
                            {
                                from: 200,
                                to: 299,
                                color: '#00ed64'
                            },
                            {
                                from: 300,
                                to: 400,
                                color: '#00a1ed'
                            },
                        ],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                  },
                },
                dataLabels: {
                  enabled: false
                },
                yaxis:{
                   
                    labels:{
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 760,
                        style: {
                            colors: [],
                            fontSize: '12px',
                            fontFamily: 'Lato, sans-serif',
                            fontWeight: 'bold',
                            // cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    }
                },
                xaxis: {
                  categories: [],
                  min:10,
                  max:450,
                  labels:{
                    formatter: function (value) {
                      // return value
                      switch (value) {
                        case 54:
                            return 'Fail'
                          case 186:
                            return 'Poor'
                          case 274:
                            return 'Good'
                          case 406:
                            return 'Outstanding'
                          default:
                            break;
                    }
                    },
                    
                    style: {
                        colors: [],
                        fontSize: '14px',
                        fontFamily: 'Lato, sans-serif',
                        fontWeight: 'bold',
                        // cssClass: 'apexcharts-xaxis-label',
                    },
                    minHeight: 80,
                  }
                },
                
              },
            }
            res.data.dimensions.map((data,i)=>{
              if(data.indicators.length>0){
                chart.series[0].data.push(data.indicators[0].value*100)
              }
            })
            res.data.dimensions.map((data,i)=>{
                chart.options.xaxis.categories.push(data.title)
            })
            dispatch({
              type:actionTypes.GET_DATA_REPORT,
              payload:{...res.data,chart:chart}
            })

        }
        if(res.data.surveyId===4){
          let new_chart=[]
          res.data.data.map((charts)=>{
            let chart={
              series: [],
              options: {
                chart: {
                  type: 'bar',
                  height: 430
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top',
                    },
                   
                  }
                },
                dataLabels: {
                  enabled: false,
                  offsetX: -6,
                  style: {
                    fontSize: '12px',
                    colors: ['#fff']
                  }
                },
                stroke: {
                  show: true,
                  width: 1,
                  colors: ['#fff']
                },
                xaxis: {
                  categories: [],

                },
                fill:{
                  colors:['#ed4900','#fcbc44','#00ed64']

                },
                legend:{
                  floating:false,
                  formatter: function(seriesName, opts) {
                    return charts.chart.legend[opts.seriesIndex]
                },
                  markers:{
                    fillColors:['#ed4900','#fcbc44','#00ed64']
                  }
                },
                yaxis:{
                   
                  labels:{
                      align: 'right',
                      minWidth: 0,
                      maxWidth: 760,
                      style: {
                          colors: [],
                          fontSize: '12px',
                          fontFamily: 'Lato, sans-serif',
                          fontWeight: 'bold',
                          // cssClass: 'apexcharts-xaxis-label',
                      },
                      
                  }
              },
              }
              
            }
            charts.chart.data.map((data_chart)=>{
              // let new_value=[]
              // data_chart.value.map((value)=>{
              //   new_value.push(value*10)
              // })
              // console.log('new_value', new_value)
              chart.options.xaxis.categories.push(data_chart.indicator)
              // chart.series.push({data:new_value})
            })
            charts.chart.legend.map((legend,i)=>{
              let new_value=[]
              charts.chart.data.map((data)=>{
                new_value.push(data.value[i])
              })
              chart.series.push({data:new_value})
            })
            new_chart.push({...charts,chart})
          })
          let x=[]
          let y=[]
          res.data.quadrant.data.map((dat)=>{
            x.push(dat.x)
          })
          res.data.quadrant.data.map((dat)=>{
            y.push(dat.y)
          })
          let x_max=Math.max(...x)
          let x_min=Math.min(...x)
          let y_max=Math.max(...y)
          let y_min=Math.min(...y)
          let new_rangex=[]
          let new_rangey=[]
          let low_range=[]
          let hight_range=[]
          for (let index = Math.ceil(x_min); index <= Math.ceil(x_max); index++) {
            new_rangex.push(index)
            
          }
          for (let index = Math.ceil(y_min); index <= Math.ceil(y_max); index++) {
            new_rangey.push(index)
            
          }

          const mean = (...numbers) => numbers.reduce((acc, val) => acc + val, 0) / numbers.length;

          // console.log('new_range', new_rangex)
          let x_middle=mean(...new_rangex)
          let y_middle=mean(...new_rangey)
          for (let index = x_min; index <= x_middle; index++) {
            low_range.push(Math.ceil(index))
            
          }
          for (let index = x_middle; index <= x_max; index++) {
            hight_range.push(Math.ceil(index))
            
          }
          let low=Math.ceil(mean(...low_range))
          let hight=Math.ceil(mean(...hight_range))
          let new_series=[]
          const renderColor=(x,y)=>{
            if(x<4.5&&y>=4.5){
              return '#ed4900'
            }
            if(x>=4.5&&y>=4.5){
              return '#fcbc44'
            }
            if(x>=4.5&&y<4.5){
              return '#00ed64'
            }
            if(x<4.5&&y<4.5){
              return '#00a1ed'
            }
          }
          // let findSameSeriesX=(x)=>{
          //   let filter=new_series.filter((data)=>{
          //     return data.x===x
          //   })
          //   if(filter)
          // }
          res.data.quadrant.data.map((series)=>{
            new_series.push({...series,color:renderColor(series.x,series.y)})
          })
          // console.log('x_middle,y_middle,new_rangex,new_rangey', x_middle,y_middle,new_rangex,new_rangey)
          const options = {
            chart: {
                type: 'bubble',
                plotBorderWidth: 1,
                zoomType: 'xy',
              width:700,

              },
              legend: {
                enabled: false
              },
              title:'',
              credits:{
                enabled:false
              },
              tooltip:{
                enabled:false
              },
              xAxis: {
                gridLineWidth: 1,
                labels: {
                    formatter:(value)=>{
                     
                        if(value.value===2){
                            return 'Low'
                        }else if(value.value===7){
                            return 'High'
                        }else{
                            return ''
                        }
                    }
                  },
                plotLines: [{
                  color: '#00ede4',
                  dashStyle: '',
                  width: 2,
                  value: 4.5,
                  label: {
                    rotation: 0,
                    y: 15,
                    style: {
                      fontStyle: 'italic'
                    },
                    text: ''
                  },
                  zIndex: 3
                }],
                accessibility: {
                  rangeDescription: 'Range: 60 to 100 grams.'
                }
              },
            
              yAxis: {
                startOnTick: false,
                endOnTick: false,
                title: {
                  text: ''
                },
                
                maxPadding: 0.2,
                plotLines: [{
                  color: '#00ede4',
                  width: 2,
                  value: 4.5,
                  label: {
                    
                    align: 'right',
                    style: {
                      fontStyle: 'italic'
                    },
                    text: '',
                    x: -60
                  },
                  zIndex: 3
                }],
                accessibility: {
                  rangeDescription: 'Range: 0 to 160 grams.'
                }
              },
            
            
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                  }
                },
                bubble: {
                    minSize: 20,
                    maxSize: 20
                }
              },
              
              series: [{
                data: new_series
              }]
              
          }
          let grouping=new_series
          let group=groupBy(new_series,(data)=>{
            return data.x&&data.y
        })
          dispatch({
            type:actionTypes.GET_DATA_REPORT,
            payload:{...res.data,data:new_chart,quadrant:{...res.data.quadrant,options:options}}
          })
        }
        else if(res.data.surveyId===5||res.data.surveyId===6){
            let new_dimension=[]
            res.data.dimensions.map((data)=>{
            let chart3={
              series: [{
                  data: []
                }],
                options: {
                  chart: {
                    type: 'bar',
                    height: 10
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                        barHeight:'70%',
                        colors: {
                          ranges: [
                              {
                                  from: 10,
                                  to: 199,
                                  color: '#ed4900'
                              },
                              {
                                  from: 200,
                                  to: 299,
                                  color: '#00ed64'
                              },
                              {
                                  from: 300,
                                  to: 400,
                                  color: '#00a1ed'
                              },
                          ],
                          backgroundBarColors: [],
                          backgroundBarOpacity: 1,
                          backgroundBarRadius: 0,
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  yaxis:{
                     
                      labels:{
                          align: 'right',
                          minWidth: 0,
                          maxWidth: 760,
                          style: {
                              colors: [],
                              fontSize: '12px',
                              fontFamily: 'Lato, sans-serif',
                              fontWeight: 'bold',
                              // cssClass: 'apexcharts-xaxis-label',
                          },
                          
                      }
                  },
                  xaxis: {
                    categories: [],
                    min:10,
                    max:550,
                    labels:{
                      formatter: function (value) {
                        // return value
                        switch (value) {
                          case 64:
                                return 'Very Poor'
                              case 226:
                                return 'Poor'
                              case 334:
                                return 'Good'
                              case 496:
                                return 'Outstanding'
                              default:
                                break;
                      }    
                      },
                      
                      style: {
                          colors: [],
                          fontSize: '14px',
                          fontFamily: 'Lato, sans-serif',
                          fontWeight: 'bold',
                          // cssClass: 'apexcharts-xaxis-label',
                      },
                      minHeight: 80,
                    }
                  },
                  
                },
            }
          
            data.indicators.map((indicator)=>{
              chart3.series[0].data.push(indicator.value*100)
              chart3.options.xaxis.categories.push(indicator.indicator)
            })
            new_dimension.push({...data,chart:chart3})
            // chart3.series[0].data=[]
            // chart3.options.xaxis.categories=[]
          })
          console.log('new_dimension', new_dimension)
          dispatch({
            type:actionTypes.GET_DATA_REPORT,
            payload:{...res.data,dimensions:new_dimension}
          })
        }
        else{
          dispatch({
            type:actionTypes.GET_DATA_REPORT,
            payload:res.data
          })
        }
        
        
        return res
    }else{
        dispatch(setLoading2(false))

       
    }
}

export const postGroup=(data)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/owner`,
      method:'POST',
      data:{
          data,
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      dispatch({
          type:actionTypes.SET_MESSAGE,
          payload:res.data
      })
      return res.data
  }else{
      dispatch(setLoading2(false))

     
  }
}

export const getDtraResult=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/Survey/digital/result${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      // dispatch({
      //     type:actionTypes.GET_LIST_SURVEY,
      //     payload:res.data
      // })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getLpsResult=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/Survey/lps/result${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      // dispatch({
      //     type:actionTypes.GET_LIST_SURVEY,
      //     payload:res.data
      // })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getDtraResultIndividual=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/Survey/digital/result/individual${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      // dispatch({
      //     type:actionTypes.GET_LIST_SURVEY,
      //     payload:res.data
      // })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getLpsResultIndividual=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/Survey/lps/result/individual${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      // dispatch({
      //     type:actionTypes.GET_LIST_SURVEY,
      //     payload:res.data
      // })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getAkhlakResult=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/report${slug} `,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      dispatch({
          type:actionTypes.GET_DATA_REPORT,
          payload:res.data
      })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getKepemimpinanResult=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/report${slug} `,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      dispatch({
          type:actionTypes.GET_DATA_REPORT,
          payload:res.data
      })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getAkhlakResultAll=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/report${slug} `,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoading2(false))
      dispatch({
          type:actionTypes.GET_DATA_REPORT,
          payload:res.data
      })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getListIndustry=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/options${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
    dispatch(setLoading2(false))
      
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}
export const getReportAkhlakAll=(slug='')=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/industry${slug}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){

    dispatch(setLoading2(false))
    let rata_rata=res.data.filter((data)=>{
      return data.title==='Nilai rata-rata'
    })
    let selain_rata=res.data.filter((data)=>{
      return data.title!=='Nilai rata-rata'
    })
    let new_data=[...rata_rata,...selain_rata]
    dispatch({
        type:actionTypes.GET_DATA_REPORT,
        payload:new_data
    })
      return res
      
  }else{
      dispatch(setLoading2(false))

     
  }
}

export const postLinkEdtra=(data)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/survey/groups/stellar`,
      method:'POST',
      data:{
          data,
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==204){
      dispatch(setLoading2(false))
      return res
  }else{
      dispatch(setLoading2(false))

     
  }
}

export const getLinkEdtra=(id)=>async dispatch=>{
  dispatch(setLoading2(true))
  let dataReq={
      url:`/Survey/groups/stellar/${id}`,
      method:'GET',
      data:{
          auth:{username:USER,password:PASS}
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){

      dispatch(setLoading2(false))
      return res
  }else{
      dispatch(setLoading2(false))

     
  }
}
